import React from 'react';
import { intlShape, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { SdfButton, SdfCheckbox } from '@synerg/react-components';
import { constants } from './channel-collection-constants';
import { isEnterKeyPressed } from '../../util/misc';

import { VoiceBioMetricsOptOutPage, ChannelCollectionError, ChannelCollectionSuccess } from '.';

const { VOICE_BIOMETRICS, CONSENT } = constants;

const VoiceBioMetricsCollectionForm = (props) => {
  const {
    currentlySending,
    saveCommunicationChannel,
    channelCollectionChallenge,
    skipChannelCollection,
    channelCollectionPassed,
    setConsentFlag,
    consentFlag,
    voiceBioMetricsOptOutPage,
    voiceBioMetricsOptClicked,
    channelCollectionFailed,
    isAppView,
  } = props;

  const { displayOptOut } = channelCollectionChallenge;

  const onSave = (event) => {
    const addedCommunications = [];
    const changedCommunications = [];

    addedCommunications.push({
      type: VOICE_BIOMETRICS,
      consentStatus: CONSENT,
    });

    if (event !== null && event !== undefined) {
      if (isEnterKeyPressed(event.key)) {
        saveCommunicationChannel(addedCommunications, changedCommunications);
      }
    } else {
      saveCommunicationChannel(addedCommunications, changedCommunications);
    }
  };

  const isSaveButtonDisabled = !consentFlag;

  return (
    (channelCollectionFailed && <ChannelCollectionError { ...props } />) ||
    (channelCollectionPassed && <ChannelCollectionSuccess { ...props } />) ||
    (voiceBioMetricsOptClicked && <VoiceBioMetricsOptOutPage { ...props } />) || (
      <div
        id="channel-collection"
        className="flex flex-col"
      >
        <div className="p-1 justify-start sm:text-left font-small text-md mt-4 mb-2">
          <FormattedMessage
            id="channelcollection_voicebiometrics_primary"
            defaultMessage="A secure way to verify your identity and safeguard your information."
          />
        </div>

        <div className="p-1 justify-start sm:text-left font-small text-md mt-2 mb-2">
          <span className="font-bold black pb-4">
            <FormattedMessage
              id="channelcollection_voicebiometrics_subline"
              defaultMessage="The process is free and easy!"
            />
          </span>
          <span className="leading-md">
            <FormattedMessage
              id="channelcollection_voicebiometrics_subline0"
              // eslint-disable-next-line max-len
              defaultMessage="Begin your enrollment now and the next time you call we'll capture your unique voice pattern providing a more secure service experience on future calls."
            />
          </span>
        </div>

        <div className="p-1 justify-start sm:text-left font-small text-md mt-2">
          <span className="font-bold black pb-4">
            <FormattedMessage
              id="channelcollection_voicebiometrics_tnc"
              defaultMessage="Terms of Use"
            />
          </span>
        </div>

        <div className="p-1 justify-start sm:text-left font-small text-md mb-4 md:mb-6 leading-md">
          <FormattedMessage
            id="channelcollection_voicebiometrics_subline1"
            // eslint-disable-next-line max-len
            defaultMessage="Your voice data will be stored for 18 months after your last call to ADP, or until you or your employer notify ADP that the data should be deleted, whichever occurs first. If you decide at any time that you'd like to unenroll from voice authentication, please contact ADP."
          />
        </div>
        <div className="flex justify-center">
          <SdfCheckbox
            id="vbconsented-yes"
            checked={ consentFlag || undefined }
            onSdfChange={ () => setConsentFlag(!consentFlag) }
            onKeyPress={ evt => isEnterKeyPressed(evt.key) && setConsentFlag(!consentFlag) }
          >
            { ' ' }
            <div className="p-1 justify-start sm:text-left font-small text-md">
              <FormattedMessage
                id="channelcollection_voicebiometrics_consent"
                defaultMessage="I accept the Voice Authentication "
              />
              <span className="font-bold black pb-4">
                <FormattedMessage
                  id="channelcollection_voicebiometrics_consent_tnc"
                  defaultMessage=" Terms of Use"
                />
              </span>
            </div>
          </SdfCheckbox>
        </div>

        <div className="flex md:flex-row md:justify-end gap flex-col items-center mt-4 md:mt-10">
          <SdfButton
            id="vb_agree_id"
            class={ isAppView ? 'w-full' : 'max-w-xs w-full md:w-auto mt-2 md:mt-0' }
            emphasis="primary"
            disabled={ isSaveButtonDisabled || undefined }
            onClick={ () => {
              onSave();
            } }
            onKeyPress={ (evt) => {
              onSave(evt);
            } }
          >
            <FormattedMessage
              id="channelcollection_voicebiometrics_submit_button"
              defaultMessage="I Agree"
            />
          </SdfButton>

          { !displayOptOut ? (
            <SdfButton
              id="vb-collection-remind"
              class={ isAppView ? 'w-full' : 'max-w-xs w-full md:w-auto md:mt-0 md:mb-0' }
              emphasis="secondary"
              disabled={ currentlySending || undefined }
              onClick={ () => {
                !currentlySending && skipChannelCollection(true);
              } }
              onKeyPress={ (evt) => {
                isEnterKeyPressed(evt.key) && !currentlySending && skipChannelCollection(true);
              } }
            >
              <FormattedMessage
                id="channelcollection_remindme_button_label"
                defaultMessage="remind me later"
              />
            </SdfButton>
          ) : (
            <SdfButton
              id="vb_optout"
              class={ isAppView ? 'w-full' : 'max-w-xs w-full md:w-auto md:mt-0 md:mb-0' }
              emphasis="secondary"
              disabled={ currentlySending || undefined }
              onClick={ () => {
                voiceBioMetricsOptOutPage(true);
              } }
              onKeyPress={ (evt) => {
                isEnterKeyPressed(evt.key) && voiceBioMetricsOptOutPage(true);
              } }
            >
              { ' ' }
              <FormattedMessage
                id="channelcollection_voicebiometrics_optout"
                defaultMessage="Decline voice authentication"
              />
            </SdfButton>
          ) }
        </div>
      </div>
    )
  );
};

VoiceBioMetricsCollectionForm.propTypes = {
  currentlySending: PropTypes.bool,
  channelCollectionFailed: PropTypes.bool,
  channelCollectionPassed: PropTypes.bool,
  saveCommunicationChannel: PropTypes.func,
  consentFlag: PropTypes.bool,
  setConsentFlag: PropTypes.func,
};

VoiceBioMetricsCollectionForm.contextTypes = {
  intl: intlShape.isRequired,
};

export default VoiceBioMetricsCollectionForm;
