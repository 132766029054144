import { createTypes, asyncAction } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { NEXO_NAMESPACE } from '../../helpers/constants';

export const types = createTypes(
  NEXO_NAMESPACE,
  asyncAction('LOAD_ACCOUNTTYPE'),
  asyncAction('LOAD_PLATFORMAUTHETICATOR'),
);

const loadAccountType = createAction(types.LOAD_ACCOUNTTYPE_REQUEST);
const loadPlatformAuthenticator = createAction(types.LOAD_PLATFORMAUTHETICATOR_REQUEST);

export const actions = {
  loadAccountType,
  loadPlatformAuthenticator,
};
