import { createTypes, asyncAction } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { NEXO_NAMESPACE } from '../../helpers/constants';

export const types = createTypes(
  NEXO_NAMESPACE,
  asyncAction('VERIFY_USERID'),
  'SET_USERID',
  'SET_PASSWORD',
  'SET_REMEMBERCHECKBOX',
  'SET_ISADPUSER',
  'CHANGE_ADP_USER',
  'SET_SWITCH_TO_PASSWORD',
  'CHANGE_USER_FNAME',
  'FIDO_REGISTER',
  asyncAction('AUTHENTICATE'),
  'AUTHENTICATE_FIDO2',
  'RESET_STATE',
  'REDIRECT_URL',
  'FORM_REDIRECT_URL',
  'SET_COOKIE',
  'AUTHENTICATE_FIDO2',
  'AUTHENTICATE_MORE',
  'AUTHENTICATE_OVER',
  'DISPLAY_ERROR_PAGE',
  'DISPLAY_FIDO_DEVICE_LIST',
  'SHOW_SUCCESS_PAGE',
  'SHOW_FIDO_CANCEL_PAGE',
  'SHOW_FIDO_REGISTER_SUCCESS_PAGE',
  'FIDO_AUTH_CANCEL',
  'REDIRECT_TARGET',
  'NAVIGATE_HOME_SWITCH_PASSWORD',
  'FIDO_STEPUP_REQUEST',
  'FIDO_REMIND_ME',
  'SET_FIDO_ATTRIBUTES',
  'SET_DIFFUSER',
);

const verifyUserid = createAction(types.VERIFY_USERID_REQUEST, devicePrint => ({
  devicePrint,
}));
// prettier-ignore
const authenticate = createAction(types.AUTHENTICATE_REQUEST);
const fidoStepUpRequest = createAction(types.FIDO_STEPUP_REQUEST);
const fidoRemindMeRequest = createAction(types.FIDO_REMIND_ME);
const setFidoAttributes = createAction(types.SET_FIDO_ATTRIBUTES);
const authenticateFido2 = createAction(types.AUTHENTICATE_FIDO2);
const showSuccessPage = createAction(types.SHOW_SUCCESS_PAGE);
const showFidoCancelPage = createAction(types.SHOW_FIDO_CANCEL_PAGE);
const showFidoRegisterSuccessPage = createAction(types.SHOW_FIDO_REGISTER_SUCCESS_PAGE);
const setUserid = createAction(types.SET_USERID, userid => ({ userid: userid.trim() }));
const diffUserAction = createAction(types.SET_DIFFUSER);
const changeAdpUserId = createAction(types.CHANGE_ADP_USER, adpUserId => ({ adpUserId }));
const setSwitchPassword = createAction(types.SET_SWITCH_TO_PASSWORD, switchToPassword => ({
  switchToPassword,
}));
const displayFidoDeviceList = createAction(types.DISPLAY_FIDO_DEVICE_LIST);
const fidoRegister = createAction(types.FIDO_REGISTER);
const fidoAuthCancel = createAction(types.FIDO_AUTH_CANCEL);
const changeUserFname = createAction(types.CHANGE_USER_FNAME, userFName => ({ userFName }));
const setPassword = createAction(types.SET_PASSWORD, password => ({ password }));
const setRememberCheckBox = createAction(types.SET_REMEMBERCHECKBOX, rememberCheckBox => ({
  rememberCheckBox,
}));
const setIsADPUser = createAction(types.SET_ISADPUSER, isADPUser => ({ isADPUser }));
const resetState = createAction(types.RESET_STATE);
const authenticateOver = createAction(types.AUTHENTICATE_OVER);
const authenticateMore = createAction(types.AUTHENTICATE_MORE, response => ({ response }));

export const redirectURL = createAction(types.REDIRECT_URL, (url, newTab) => ({ url, newTab }));
export const redirectTarget = createAction(types.REDIRECT_TARGET);

const formRedirectURL = createAction(types.FORM_REDIRECT_URL, params => ({ params }));
const setCookie = createAction(types.SET_COOKIE, (cookieName, cookieValue) => ({
  cookieName,
  cookieValue,
}));

// eslint-disable-next-line max-len
const navigateHomeForSwitchPassword = createAction(types.NAVIGATE_HOME_SWITCH_PASSWORD);

export const actions = {
  verifyUserid,
  setUserid,
  changeAdpUserId,
  changeUserFname,
  setPassword,
  setRememberCheckBox,
  setIsADPUser,
  authenticate,
  resetState,
  redirectURL,
  fidoRegister,
  fidoAuthCancel,
  setCookie,
  authenticateMore,
  authenticateOver,
  displayFidoDeviceList,
  formRedirectURL,
  showSuccessPage,
  showFidoCancelPage,
  redirectTarget,
  showFidoRegisterSuccessPage,
  authenticateFido2,
  setSwitchPassword,
  navigateHomeForSwitchPassword,
  fidoStepUpRequest,
  fidoRemindMeRequest,
  setFidoAttributes,
  diffUserAction,
};
