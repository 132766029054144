import { merge, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { types, constants } from '..';

const { INITIAL_LOAD_REQUEST } = types;
const { INITIAL_LOAD_REQUEST_ACTIONS } = constants;

export const initialLoadEpic = action$ => action$.pipe(
  ofType(INITIAL_LOAD_REQUEST),
  // prettier-ignore
  mergeMap(() => merge(
    ...INITIAL_LOAD_REQUEST_ACTIONS.map(action => of(action.apply())),
  )),
);
