import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { SdfFocusPane } from '@synerg/react-components';
import { constants } from './channel-collection-constants';

const { DEFAULT_TEXT_MESSAGING_TANDC_URL } = constants;
const TextMessagingTCModal = (props) => {
  const { locale, intl } = props;

  const getTexTermsAndConditionsUrl = () => {
    let termsAndConditionsUrl = window.env ?
      window.env[`TEXT_MESSAGING_TANDC_${locale}`] :
      DEFAULT_TEXT_MESSAGING_TANDC_URL;

    termsAndConditionsUrl = termsAndConditionsUrl || DEFAULT_TEXT_MESSAGING_TANDC_URL;

    return termsAndConditionsUrl;
  };

  return (
    <SdfFocusPane
      { ...props }
      acceptLabel={ intl.formatMessage({
        id: 'general_ok',
        defaultMessage: 'OK',
      }) }
      closeable="true"
      spacing="normal"
      closeIconTitle="Close"
      hideDismissButton="true"
    >
      <iframe
        title="Text messaging Terms and Conditions"
        className="w-full h-full md:h-sm"
        frameBorder="0"
        src={ getTexTermsAndConditionsUrl() }
      />
    </SdfFocusPane>
  );
};

TextMessagingTCModal.propTypes = {
  locale: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};

export default injectIntl(TextMessagingTCModal);
