import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { createAction } from 'redux-actions';
import { QUESTION_VERIFICATION_RESPONSE } from '../step-up-constants';
import { types } from '..';
import { types as authType } from '../../login/login-actions';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { getAjaxJsonHeader, setDeviceTokenForRequiredTypes } from '../../../../util/misc/common';
import { constants } from '../../login/login-constants';
import { types as tcType } from '../../terms-and-conditions/terms-and-conditions-actions';

// eslint-disable-next-line max-len
const {
  VERIFY_CHALLENGE_QNA_REQUEST,
  VERIFY_CHALLENGE_QNA_SUCCESS,
  VERIFY_CHALLENGE_QNA_FAIL,
} = types;
const { AUTHENTICATE_SUCCESS, FIDO_AUTH_CANCEL } = authType;
const { AUTHENTICATE_ENDPOINT } = constants;
const { DISPLAY_TERMS_AND_CONDITIONS } = tcType;

const authenticationSuccess = createAction(AUTHENTICATE_SUCCESS);
const fidoAuthCancel = createAction(FIDO_AUTH_CANCEL);
const verifQNASuccess = createAction(VERIFY_CHALLENGE_QNA_SUCCESS);
const verifyQNAFail = createAction(VERIFY_CHALLENGE_QNA_FAIL);
const tcDisplay = createAction(DISPLAY_TERMS_AND_CONDITIONS);

const handleResponse = (response, userid, isFido) => {
  const { challenge, session, result } = response.response;
  const {
    accessToken, redirectUrl, type, user, fidoPromptStatus,
  } = result;

  const { givenName } = user || '';

  setDeviceTokenForRequiredTypes(response);

  if (accessToken && !fidoPromptStatus && isFido) {
    return authenticationSuccess({
      accessToken,
      redirectUrl,
      givenName,
    });
  }

  if (accessToken) {
    return authenticationSuccess({
      accessToken,
      redirectUrl,
      givenName,
    });
  }

  return verifQNASuccess({
    challenge,
    verifQNAResponseType: type,
    session,
  });
};
/* This will change later when we integrate the real api..now email verification is hardcoded */
const verifyQNARequest = (questionId, answer, type, displayQnASession) => ({
  response: {
    type,
    questionId,
    answer,
  },
  session: displayQnASession,
});
// eslint-disable-next-line max-len
const verifyQNAService = (
  ajax,
  questionId,
  answer,
  type,
  displayQnASession,
  nextActionUrl,
  userid,
  isFido,
) => ajax({
  url: nextActionUrl || `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(verifyQNARequest(questionId, answer, type, displayQnASession)),
}).pipe(
  map(response => handleResponse(response, userid, isFido)),
  catchError((err) => {
    const errorCode = getErrorMessage(err);

    if (
      (errorCode === 'ACCESS_DENIED' ||
          errorCode === 'INTERNAL SERVER ERROR' ||
          errorCode === 'APPLICATION_ERROR') &&
        isFido
    ) {
      return of(
        fidoAuthCancel({
          errorCode,
        }),
      );
    }

    return of(verifyQNAFail(getErrorMessage(err)));
  }),
);

// prettier-ignore
export const verifyQNAEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(VERIFY_CHALLENGE_QNA_REQUEST),
  switchMap(() => {
    const type = QUESTION_VERIFICATION_RESPONSE;
    const { questionId, next } = state$.value.stepup.displayQnAChallenge;
    const { displayQnASession, answer, displayQnAChallenge } = state$.value.stepup;
    const { userid, isFido } = state$.value.login;

    return sendingAsyncRequest(
      verifyQNAService(ajax, questionId, answer, type, displayQnASession,
        displayQnAChallenge && next, userid, isFido),
    );
  }),
);

export const verifQNASuccessEpic = action$ => action$.pipe(
  ofType(VERIFY_CHALLENGE_QNA_SUCCESS),
  // eslint-disable-next-line max-len
  map(({ payload: { challenge, verifQNAResponseType, session } }) => handleRedirectForVerifyONA(challenge, verifQNAResponseType, session)),
);
const handleRedirectForVerifyONA = (challenge, verifQNAResponseType, session) => {
  if (challenge && challenge.type === 'CONSENT_COLLECTION_CHALLENGE') {
    return tcDisplay({
      tcSession: session,
      tcChallenge: challenge,
    });
  }

  if (verifQNAResponseType === 'QUESTION_NOT_VERIFIED') {
    return verifyQNAFail({
      error: 'INVALID_ANSWER',
      displayQnAChallenge: challenge,
      displayQnASession: session,
      answer: '',
    });
  }
  return verifyQNAFail({
    error: 'Application Error. Please try again later',
  });
};
