import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { createAction } from 'redux-actions';
import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import { types } from '..';
import { HOME, QUESTION_COLLECTION } from '../../../../routes/router-url-constants';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import {
  getAjaxJsonHeader,
  setDeviceTokenForRequiredTypes,
  getUrlLocationSearch,
} from '../../../../util/misc';
import { constants } from '../../login/login-constants';
import { types as loginType } from '../../login/login-actions';
import { types as tcType } from '../../terms-and-conditions/terms-and-conditions-actions';

const isBlank = str => (str || '').trim().length === 0;
const { AUTHENTICATE_ENDPOINT } = constants;
const {
  SAVE_QNA_REQUEST,
  SAVE_QNA_FAIL,

  DISPLAY_QUESTION_COLLECTION,
  CANCEL_QUESTION_COLLECTION,
  SKIP_QUESTION_COLLECTION_FAIL,
  SKIP_QUESTION_COLLECTION_REQUEST,
} = types;
const { AUTHENTICATE_SUCCESS } = loginType;
const { DISPLAY_TERMS_AND_CONDITIONS } = tcType;

const saveQNAFail = createAction(SAVE_QNA_FAIL);
const skipQuestionCollectionFail = createAction(SKIP_QUESTION_COLLECTION_FAIL);
const authenticateSuccess = createAction(AUTHENTICATE_SUCCESS);
const tcDisplay = createAction(DISPLAY_TERMS_AND_CONDITIONS);
const TYPE_FIDO_SIGNED_IN = 'FIDO_AUTHENTICATION_RESULT';

const handleDataCollectionResponse = (responseObj) => {
  const { result, challenge, session } = responseObj.response;
  const { accessToken, redirectUrl, user } = result;

  const { givenName } = user || '';

  setDeviceTokenForRequiredTypes(responseObj);

  if (
    accessToken &&
    (result.type === 'SIGNED_IN' || result.type === 'FIDO_AUTHENTICATION_RESULT')
  ) {
    return authenticateSuccess({
      accessToken,
      redirectUrl,
      givenName,
    });
  }

  if (challenge && challenge.type === 'CONSENT_COLLECTION_CHALLENGE') {
    return tcDisplay({
      tcSession: session,
      tcChallenge: challenge,
    });
  }

  if (result.type === 'QUESTIONS_NOT_COLLECTED') {
    const payload = {};

    if (result.validationFailureMessages && result.validationFailureMessages.length > 0) {
      result.validationFailureMessages.map((item) => {
        const arr = item.split(':');

        payload[arr[0].trim()] = arr[1].trim();
        return null;
      });
    }

    return saveQNAFail({
      firstAnsInlineErrMessage:
        payload && !isBlank(payload.answer1) && payload.answer1 ? payload.answer1 : '',
      secondAnsInlineErrMessage:
        payload && !isBlank(payload.answer2) && payload.answer2 ? payload.answer2 : '',
      thirdAnsInlineErrMessage:
        payload && !isBlank(payload.answer3) && payload.answer3 ? payload.answer3 : '',
      questionCollectionDisplaySession: session,
    });
  }
  return null;
};

const handleSkipQuestionCollectionResponse = (responseObj) => {
  const { result, challenge, session } = responseObj.response;
  const { accessToken, redirectUrl, user } = result;

  const { givenName } = user || '';

  setDeviceTokenForRequiredTypes(responseObj);

  if (accessToken && (result.type === 'SIGNED_IN' || result.type === TYPE_FIDO_SIGNED_IN)) {
    return authenticateSuccess({
      accessToken,
      redirectUrl,
      givenName,
    });
  }

  if (challenge && challenge.type === 'CONSENT_COLLECTION_CHALLENGE') {
    return tcDisplay({
      tcSession: session,
      tcChallenge: challenge,
    });
  }
  return null;
};

// eslint-disable-next-line max-len
const saveQNARequest = (answers, questionCollectionDisplaySession) => ({
  response: {
    type: 'QUESTION_COLLECTION_RESPONSE',
    answers,
  },
  session: questionCollectionDisplaySession,
});

// eslint-disable-next-line max-len
const skipQuestionCollectionRequest = questionCollectionDisplaySession => ({
  response: {
    type: 'QUESTION_COLLECTION_RESPONSE',
    skipQuestionCollection: true,
    answers: [],
  },
  session: questionCollectionDisplaySession,
});

// eslint-disable-next-line max-len
const saveQNAService = (ajax, answers, questionCollectionDisplaySession) => ajax({
  url: `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(saveQNARequest(answers, questionCollectionDisplaySession)),
}).pipe(
  map(response => handleDataCollectionResponse(response)),
  catchError((err) => {
    if (err && err.response && err.response.code === 'QUESTION_COLLECTION_FAILED') {
      return of(saveQNAFail({ displayErrorPopup: true }));
    }
    return of(saveQNAFail({ errorMessage: getErrorMessage(err) }));
  }),
);

const skipQuestionCollectionService = (ajax, questionCollectionDisplaySession) => ajax({
  url: `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(skipQuestionCollectionRequest(questionCollectionDisplaySession)),
}).pipe(
  map(response => handleSkipQuestionCollectionResponse(response)),
  catchError(err => of(skipQuestionCollectionFail({ errorMessage: getErrorMessage(err) }))),
);

export const saveQnAEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(SAVE_QNA_REQUEST),
  // eslint-disable-next-line max-len
  switchMap(() => {
    const { questionCollectionDisplaySession } = state$.value.questionCollection;
    const { questionId: firstQuestionId } = state$.value.questionCollection.firstQuestion;
    const { questionId: secondQuestionId } = state$.value.questionCollection.secondQuestion;
    const { questionId: thirdQuestionId } = state$.value.questionCollection.thirdQuestion;
    const { firstAnswer } = state$.value.questionCollection;
    const { secondAnswer } = state$.value.questionCollection;
    const { thirdAnswer } = state$.value.questionCollection;

    const questionAndAnswers = [
      {
        questionId: firstQuestionId,
        answer: firstAnswer.trim(),
      },
      {
        questionId: secondQuestionId,
        answer: secondAnswer.trim(),
      },
      {
        questionId: thirdQuestionId,
        answer: thirdAnswer.trim(),
      },
    ];

    return sendingAsyncRequest(
      // eslint-disable-next-line max-len
      saveQNAService(ajax, questionAndAnswers, questionCollectionDisplaySession),
    );
  }),
);

export const displayQuestionCollectionEpic = action$ => action$.pipe(
  ofType(DISPLAY_QUESTION_COLLECTION),
  switchMap(() => of(push(QUESTION_COLLECTION + getUrlLocationSearch()))),
);

export const skipQuestionCollectionEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(SKIP_QUESTION_COLLECTION_REQUEST),
  switchMap(() => {
    const { questionCollectionDisplaySession } = state$.value.questionCollection;

    return sendingAsyncRequest(
      // eslint-disable-next-line max-len
      skipQuestionCollectionService(ajax, questionCollectionDisplaySession),
    );
  }),
);

export const questionCollectionCancelEpic = action$ => action$.pipe(
  ofType(CANCEL_QUESTION_COLLECTION),
  switchMap(() => of(push(HOME + getUrlLocationSearch()))),
);
