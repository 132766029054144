import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { createAction } from 'redux-actions';

import { types } from '..';
import { types as signType } from '../../signin/signin-actions';
import { getErrorMessage } from '../../../helpers/epics';
import { constants } from '../initial-load-constants';

const { LOAD_PLATFORMAUTHETICATOR_REQUEST } = signType;

const { LOAD_CSRF_REQUEST, LOAD_CSRF_FAIL } = types;
const { SIGNIN_CSRF } = constants;

const loadCSRFSuccess = createAction(LOAD_PLATFORMAUTHETICATOR_REQUEST);
const loadCSRFFail = createAction(LOAD_CSRF_FAIL);

const callCSRFService = ajax => ajax({
  url: `${SIGNIN_CSRF}`,
  method: 'get',
}).pipe(
  map(response => loadCSRFSuccess({ response })),
  catchError(err => of(loadCSRFFail(getErrorMessage(err)))),
);

export const loadCSRFEpic = (action$, _, { ajax }) => action$.pipe(
  ofType(LOAD_CSRF_REQUEST),
  // prettier-ignore
  switchMap(() => callCSRFService(ajax)),
);
