import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SdfButton } from '@synerg/react-components';
import { EMEA_REGION, PRODUCT_ID } from '../../util/misc/constants';
import {
  getParameterByName,
  getOrganizationId,
  getOrganizationIdForBranding,
} from '../../util/misc';

class ForgotUserIdLink extends React.Component {
  constructor(props) {
    super(props);
    this.onForgotUIDRedirect = this.onForgotUIDRedirect.bind(this);
  }

  onForgotUIDRedirect = () => {
    const {
      actionURL,
      returnUrl,
      callingAppId,
      backgroundImageUrl,
      productLogoUrl,
      organizationLogoUrl,
      hyperLinkID,
      userId,
      version,
      langPref,
      oneMobile,
      olpNative,
      locale,
      formRedirectURL,
      empTargetUrl,
      adminTargetUrl,
    } = this.props;

    const productId = getParameterByName(PRODUCT_ID) || '';
    const organizationId = getOrganizationIdForBranding();

    const forgotUserIdFlow = hyperLinkID === 'signin.forgotUid';

    let params = {
      callingAppId,
      returnUrl,
      rtnURL: returnUrl,
      langPref,
      oneMobile,
      olpNative,
      empTargetUrl,
      adminTargetUrl,
    };

    if (version === EMEA_REGION) {
      params = {
        ...params,
        // eslint-disable-next-line max-len
        action: actionURL,
        locale,
        productId: getParameterByName(PRODUCT_ID) || '',
        organizationId: getOrganizationId(),
      };
    } else {
      params = {
        ...params,
        action: actionURL,
        backgroundImageUrl,
        productLogoUrl,
        organizationLogoUrl,
        organizationId,
        productId,
      };
    }
    if (!forgotUserIdFlow) {
      params = {
        ...params,
        userId,
        deviceUid: userId,
      };
    }
    formRedirectURL(params);
  };

  render() {
    const {
      hyperLinkDescription,
      hyperLinkID,
      hyperLinkdefaultMessage,
      intl,
      oneMobile,
    } = this.props;

    return (
      <>
        <SdfButton
          id={ `forgot_UID_Btn_${hyperLinkID}` }
          emphasis={
            hyperLinkID === 'errorpage.password_reset_link' ||
            hyperLinkID === 'errorpage.unlock_account_link' ?
              'primary' :
              'tertiary'
          }
          onClick={ this.onForgotUIDRedirect }
          size="lg"
          class={
            oneMobile &&
            (hyperLinkID === 'errorpage.password_reset_link' ||
              hyperLinkID === 'errorpage.unlock_account_link') ?
              'w-full' :
              'w-full max-w-xs md:min-w-32 md:w-auto'
          }
          data-testid={ `forgot_UID_Btn_${hyperLinkID}` }
          button-title={ intl.formatMessage({
            id: hyperLinkID,
            defaultMessage: hyperLinkdefaultMessage,
          }) }
        >
          <FormattedMessage
            id={ hyperLinkID }
            defaultMessage={ hyperLinkdefaultMessage }
            description={ hyperLinkDescription }
          />
        </SdfButton>
      </>
    );
  }
}

ForgotUserIdLink.propTypes = {
  actionURL: PropTypes.string,
  userId: PropTypes.string,
  callingAppId: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  returnUrl: PropTypes.string,
  productLogoUrl: PropTypes.string,
  langPref: PropTypes.string,
  oneMobile: PropTypes.bool,
  olpNative: PropTypes.bool,
  organizationLogoUrl: PropTypes.string,
  version: PropTypes.number,
  formRedirectURL: PropTypes.func.isRequired,
  locale: PropTypes.string,
  hyperLinkDescription: PropTypes.string,
  hyperLinkID: PropTypes.string,
  hyperLinkdefaultMessage: PropTypes.string,
  empTargetUrl: PropTypes.string,
  adminTargetUrl: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};

export default injectIntl(ForgotUserIdLink);
