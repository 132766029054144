import React from 'react';
import PropTypes from 'prop-types';
import {
  ChannelCollectionSuccess,
  ChannelCollectionError,
  ResendConfirmationPage,
  PasscodePage,
  EmailActivationPage,
} from '.';

const PasscodePageLayout = (props) => {
  const {
    mobileVerified,
    mobileVerifiedForEmail,
    channelCollectionFailed,
    resendPasscodeClicked,
  } = props;

  return (
    (channelCollectionFailed && <ChannelCollectionError { ...props } />) ||
    (resendPasscodeClicked && <ResendConfirmationPage { ...props } />) ||
    (mobileVerifiedForEmail && <EmailActivationPage { ...props } />) ||
    (mobileVerified && <ChannelCollectionSuccess { ...props } />) || <PasscodePage { ...props } />
  );
};

PasscodePageLayout.propTypes = {
  mobileVerified: PropTypes.bool,
  channelCollectionFailed: PropTypes.bool,
  resendPasscodeClicked: PropTypes.bool,
};
export default PasscodePageLayout;
