import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SdfButton } from '@synerg/react-components';
import { isEnterKeyPressed } from '../../util/misc';

const VoiceBioMetricsOptOutPage = (props) => {
  const { optOutChannelCollection, currentlySending, isAppView } = props;

  return (
    <div id="voice-bio-opt-out-page">
      <form>
        <label
          id="resend_passcode_label_id"
          className="text-left md:font-medium md:not-italic text-md md:leading-md mt-2"
        >
          <p>
            <FormattedMessage
              id="channelcollection_voicebiometrics_optout_subheader"
              // eslint-disable-next-line max-len
              defaultMessage="By opting out of voice authentication, ADP will continue to use current methods to verify your identity. If you'd like to enroll later, you can contact ADP at any time."
            />
          </p>
        </label>
        <div className="flex md:flex-row md:justify-end gap flex-col items-center mt-4 md:mt-10">
          <SdfButton
            id="saveVbChannelCollection"
            class={ !isAppView ? 'w-xs md:w-auto mt-2 md:mt-0' : 'w-full sm:w-32' }
            size="lg"
            emphasis="primary"
            onClick={ () => {
              !currentlySending && optOutChannelCollection(true);
            } }
            onKeyPress={ (evt) => {
              !currentlySending && isEnterKeyPressed(evt.key) && optOutChannelCollection(true);
            } }
          >
            <FormattedMessage
              id="general_continue"
              defaultMessage="Continue"
            />
          </SdfButton>
        </div>
      </form>
    </div>
  );
};

VoiceBioMetricsOptOutPage.propTypes = {
  optOutChannelCollection: PropTypes.func,
  isAppView: PropTypes.bool,
  currentlySending: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};
export default VoiceBioMetricsOptOutPage;
