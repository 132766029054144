export const ADP_SMSESSION_COOKIE = 'SMSESSION';
export const COOKIE_EXPIRY_DEFAULT = 365 * 5; // 5 years
export const ADP_URL_COOKIE_EXPIRES = 60 * 60 * 24; //  24 hours
export const DOMAIN = '.adp.com';
export const PATH = '/';
export const SECURE = true;
export const PRODUCT_ID = 'productId';
export const ORGANIZATION_ID = 'organizationId';
export const TRANSACTION_ID = 'transaction_id';
export const STATE_PARAMETER = 'ostate';
export const CLIENT_ALIAS = 'CLIENT_ALIAS';
export const DEFAULT = 'default';
export const ALIAS = 'alias';
export const ADP_BRANDING_COOKIE = 'adp.aim.branding';
export const USER_ACCOUNT_IDENTITY = 'userAccountIdData';
export const USER_ACCOUNT_DATA = 'userAccountData';
export const ADP_PILOT_CONFIG_COOKIE = 'adp.aim.signin.toggle.v1';
export const DEFAULT_TARGETURL = '/local_success.html';
export const URL_REGEX = /^(([^:\/?#]+:)?(?:\/\/((?:([^\/?#:]*):([^\/?#:]*)@)?([^\/?#:]*)(?::([^\/?#:]*))?)))?([^?#]*)(\?[^#]*)?(#.*)?$/; // eslint-disable-line
export const EMEA_REGION = 2;
export const RELATIVE_PATH_PREFIX = '/';
export const CALLING_APP_ID = 'APPID';
export const FIDO2ENABLED = 'fido2_enabled';
export const OTP_HEADER = 'ADP-OTP-VERIFICATION-RESPONSE';
export const EMAIL_REGEX =
  "[A-Za-z0-9!#$%&'*+/=?^_`{|}~\\-ÀàÈèÌìÒòÙù]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~\\-ÀàÈèÌìÒòÙù]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9\\-]*[A-Za-z0-9])?\\.)+[A-Za-z][A-Za-z0-9](?:[A-Za-z0-9\\-]*[A-Za-z0-9])?";
export const PASSCODE_REGEX = '[0-9]{6}$';
export const EXTN_REGEX = '^([0-9]{3,8})$';
export const ZERO_CHECK_REGEX = '^0*$';
export const SPI_CLIENT_NAME = 'SPInfo.clientName';
export const SPI_MOBI_FED_URL = 'SPInfo.mobiFedURL';
export const FED_TARGET_URL = '/api/authorization-service/v1/authorize';
export const APAC_REGION = 'apac-1';
export const VIEW = 'view';
export const APP_VIEW = 'app';

export default {
  ADP_SMSESSION_COOKIE,
  COOKIE_EXPIRY_DEFAULT,
  DOMAIN,
  PATH,
  SECURE,
  PRODUCT_ID,
  ORGANIZATION_ID,
  DEFAULT,
  CLIENT_ALIAS,
  ALIAS,
  EMEA_REGION,
  RELATIVE_PATH_PREFIX,
  CALLING_APP_ID,
  ADP_BRANDING_COOKIE,
  EMAIL_REGEX,
  APAC_REGION,
};
