import { createTypes, asyncAction } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { NEXO_NAMESPACE } from '../../helpers/constants';

export const types = createTypes(
  NEXO_NAMESPACE,
  asyncAction('LOAD_DYNAMICMESSAGE'),
  'SET_DYNAMICMESSAGE',
);

const loadDynamicMessage = createAction(types.LOAD_DYNAMICMESSAGE_REQUEST);
const setDynamicMessage = createAction(
  types.SET_DYNAMICMESSAGE,
  (dynMsg, dynamicMessageLoaded) => ({
    dynMsg,
    dynamicMessageLoaded,
  }),
);

export const actions = {
  loadDynamicMessage,
  setDynamicMessage,
};
