import React from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { SdfButton } from '@synerg/react-components';
import 'react-phone-input-2/lib/style.css';
import { constants } from './channel-collection-constants';
import {
  isValidEmail,
  isNotBlank,
  isOnlyBusinessEmailPresent,
  isOnlyPersonalEmailPresent,
  isEmailCollection,
  isEmailVerification,
  isEnterKeyPressed,
  APAC_REGION,
} from '../../util/misc';
import { ChannelCollectionError, PasscodePageLayout, ChannelCollectionEmail } from '.';

const isBlank = value => !isNotBlank(value);
const { EMAIL, PERSONAL, BUSINESS } = constants;

const EmailCollectionForm = (props) => {
  const {
    saveCommunicationChannel,
    currentlySending,
    channelCollectionFailed,
    channelCollectionPassed,
    skipChannelCollection,
    intl,
    channelCollectionChallenge,
    emailAddress,
    emailUsageType,
    showEmailValidationError,
    persoanlEmailCommunicationId,
    personalPreferenceType,
    businessEmailCommunicationId,
    businessPreferenceType,
    palErrorMessage,
    isAppView,
  } = props;

  const { communicationCollectionType, communications, geography } = channelCollectionChallenge;
  const onSave = () => {
    const addedCommunications = [];
    const changedCommunications = [];

    if (isEmailCollection(communicationCollectionType)) {
      emailAddress &&
        emailUsageType &&
        addedCommunications.push({
          type: EMAIL,
          address: emailAddress,
          usageType: emailUsageType,
        });
    } else if (isEmailVerification(communicationCollectionType) && emailUsageType === PERSONAL) {
      if (isOnlyBusinessEmailPresent(communications)) {
        emailAddress &&
          emailUsageType &&
          addedCommunications.push({
            type: EMAIL,
            address: emailAddress,
            usageType: emailUsageType,
          });
      } else {
        emailAddress &&
          emailUsageType &&
          changedCommunications.push({
            type: EMAIL,
            address: emailAddress,
            usageType: emailUsageType,
            preferenceType: personalPreferenceType || businessPreferenceType,
            communicationId: persoanlEmailCommunicationId || businessEmailCommunicationId,
          });
      }
    } else if (isEmailVerification(communicationCollectionType) && emailUsageType === BUSINESS) {
      if (isOnlyPersonalEmailPresent(communications)) {
        emailAddress &&
          emailUsageType &&
          addedCommunications.push({
            type: EMAIL,
            address: emailAddress,
            usageType: emailUsageType,
          });
      } else {
        emailAddress &&
          emailUsageType &&
          changedCommunications.push({
            type: EMAIL,
            address: emailAddress,
            usageType: emailUsageType,
            preferenceType: businessPreferenceType || personalPreferenceType,
            communicationId: businessEmailCommunicationId || persoanlEmailCommunicationId,
          });
      }
    }

    if (!isValidEmail(emailAddress)) {
      showEmailValidationError('channelcollection_invalid_email_address_label');
    } else {
      saveCommunicationChannel(addedCommunications, changedCommunications);
    }
  };

  const isSaveButtonDisabled = isBlank(emailAddress) || palErrorMessage || currentlySending;

  const getEmailSubHeader = () => {
    let localeData;

    if (geography === APAC_REGION) {
      localeData = intl.formatMessage({
        id: 'channelcollection_uniqueemail_collection_headline',
        // eslint-disable-next-line max-len
        defaultMessage:
          'We noticed you do not have a unique email address saved. To complete your registration, you will need to provide a primary email address before March 2024. We will send a code to confirm it is you.',
      });
    } else if (isEmailCollection(communicationCollectionType)) {
      localeData = intl.formatMessage({
        id: 'channelcollection_email_collection_headline',
        // eslint-disable-next-line max-len
        defaultMessage:
          'Enter your primary email address to protect your account from fraud activity. For security purposes, we will send a verification code to confirm it is you.',
      });
    } else if (isOnlyPersonalEmailPresent(communications)) {
      localeData = intl.formatMessage({
        id: 'channelcollection_email_verification_headline',
        // eslint-disable-next-line max-len
        defaultMessage:
          'To help protect your account we need to confirm your primary email address. We will email you a code to verify it.',
      });
    } else {
      localeData = intl.formatMessage({
        id: 'channelcollection_primary_email_verification_headline',
        // eslint-disable-next-line max-len
        defaultMessage:
          'To help protect your account we need to confirm your primary email address. We will send you a code to verify your selection.',
      });
    }
    return localeData;
  };

  const getPrimaryButtonText = () => {
    if (
      isEmailCollection(communicationCollectionType) ||
      isOnlyPersonalEmailPresent(communications)
    ) {
      return intl.formatMessage({
        id: 'channelCollection_send_email_message',
        defaultMessage: 'Verify email address',
      });
    }

    return intl.formatMessage({
      id: 'channelcollection_send_code',
      defaultMessage: 'Send me a code',
    });
  };

  const onSubmit = () => {
    !isSaveButtonDisabled && onSave();
  };

  const onRemindMe = () => {
    !currentlySending && skipChannelCollection(true);
  };

  const handlePrimaryButtonKeyPress = (event) => {
    if (isEnterKeyPressed(event.key)) {
      onSubmit();
    }
  };

  const handleSecondaryButtonKeyPress = (event) => {
    if (isEnterKeyPressed(event.key)) {
      onRemindMe();
    }
  };

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      event.preventDefault();
      onSubmit();
    }
  };
  const otherProps = {
    handleKeyPress,
  };

  return (
    (channelCollectionFailed && <ChannelCollectionError { ...props } />) ||
    (channelCollectionPassed && <PasscodePageLayout { ...props } />) || (
      <div id="channel-collection">
        <div className="font-normal text-md leading-md mt-4 mb-6">{ getEmailSubHeader() }</div>
        { /** ********* Email Field Start *************** */ }

        {
          <div className="flex flex-row justify-center">
            <div
              className={
                isAppView ? 'w-full mb-10' : 'max-w-xs w-full md:max-w-full md:mx-20 mb-10'
              }
            >
              <ChannelCollectionEmail
                { ...props }
                { ...otherProps }
              />
            </div>
          </div>
        }

        { /** ********* Email Field End *************** */ }

        <div className="flex md:flex-row md:justify-end gap flex-col items-center mt-4 md:mt-10">
          <SdfButton
            id="email-save-id"
            size="lg"
            className={ isAppView ? 'w-full' : 'max-w-xs w-full md:w-auto' }
            emphasis="primary"
            disabled={ isSaveButtonDisabled || undefined }
            onClick={ onSubmit }
            onKeyPress={ handlePrimaryButtonKeyPress }
          >
            { getPrimaryButtonText() }
          </SdfButton>
          <SdfButton
            id="channel-collection-remind"
            size="lg"
            className={ isAppView ? 'w-full' : 'max-w-xs w-full md:w-auto' }
            emphasis="secondary"
            disabled={ currentlySending || undefined }
            onClick={ onRemindMe }
            onKeyPress={ handleSecondaryButtonKeyPress }
          >
            <FormattedMessage
              id="channelcollection_remindme_button_label"
              defaultMessage="Remind me later"
            />
          </SdfButton>
        </div>
      </div>
    )
  );
};

EmailCollectionForm.propTypes = {
  saveCommunicationChannel: PropTypes.func,
  currentlySending: PropTypes.bool,
  channelCollectionFailed: PropTypes.bool,
  channelCollectionPassed: PropTypes.bool,
  skipChannelCollection: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
  emailAddress: PropTypes.string,
  emailUsageType: PropTypes.string,
  showEmailValidationError: PropTypes.func,
  persoanlEmailCommunicationId: PropTypes.string,
  personalPreferenceType: PropTypes.string,
  businessEmailCommunicationId: PropTypes.string,
  businessPreferenceType: PropTypes.string,
  palErrorMessage: PropTypes.string,
  isAppView: PropTypes.bool,
};

EmailCollectionForm.contextTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(EmailCollectionForm);
