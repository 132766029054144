import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SdfAlert } from '@synerg/react-components';

const LocaleCommonAlert = (props) => {
  const {
    title, content, alertType, autoClose, id, defaultMessage,
  } = props;
  const closeable = true;

  return (
    <div id="common_alert_div">
      <SdfAlert
        type={ alertType }
        autoClose={ autoClose }
        title={ title }
        closeable={ closeable }
        size="sm"
        id="common_alert"
      >
        <FormattedMessage
          id={ id }
          defaultMessage={ defaultMessage }
          description={ content }
        />
      </SdfAlert>
    </div>
  );
};

LocaleCommonAlert.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  alertType: PropTypes.string,
  id: PropTypes.string,
  defaultMessage: PropTypes.string,
  autoClose: PropTypes.bool,
};

export default LocaleCommonAlert;
