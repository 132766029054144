import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { LoginForm } from '../../components';
import { LoginLayout } from '../../layouts';
import { actions as localeActions } from '../../redux/ducks/locale';
import { actions as loginActions } from '../../redux/ducks/login';
import { actions as changePasswordActions } from '../../redux/ducks/change-password';
import { actions as tcActions } from '../../redux/ducks/terms-and-conditions';
import { actions as brandingActions } from '../../redux/ducks/brand';
import { actions as dynamicMessageActions } from '../../redux/ducks/dynamic-message';
import { isApp } from '../../util/misc';
import AppLoginForm from '../../components/login/AppLoginForm';

export const LoginContainer = props => <LoginLayout { ...props } />;

const loginTitle = (
  <FormattedMessage
    id="welcome.title"
    defaultMessage="Welcome to ADP"
    description="Welcome to ADP"
  />
);
const mapStateToProps = ({
  locale,
  login,
  brand,
  signin,
  sendingRequest,
  changepassword,
  termsandconditions,
  dynamicMessage,
}) => ({
  ...locale,
  ...login,
  ...brand,
  ...signin,
  ...sendingRequest,
  ...changepassword,
  ...termsandconditions,
  ...dynamicMessage,
});
// prettier-ignore
const mapDispatchToProps = dispatch => bindActionCreators(
  Object.assign({}, { ...localeActions }, { ...loginActions },
    { ...changePasswordActions }, { ...tcActions },
    { ...brandingActions }, { ...dynamicMessageActions }),
  dispatch,
);
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const props = { ...ownProps, ...stateProps, ...dispatchProps };

  Object.assign(props, {
    title: loginTitle,
    isHomeScreen: true,
    // Display Login Form based on view
    mainPane: isApp() ? <AppLoginForm { ...props } /> : <LoginForm { ...props } />,
  });

  return props;
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LoginContainer);
