import { handleActions } from 'redux-actions';
import { types } from './change-password-actions';

const {
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_CANCEL,
  SET_CURRENT_PASSWORD,
  SET_NEW_PASSWORD,
  SET_CONFIRM_PASSWORD,
  SET_VALIDATION_ERROR,
  SUBMIT_STATE,
  CHANGE_PASSWORD_REMIND_ME_LATER_SUCCESS,
  CHANGE_PASSWORD_REMIND_ME_LATER_FAIL,
  DISPLAY_CHANGE_PASSWORD,
  CURRENT_PASSWORD_ERROR,
  CLEAR_CHANGEPASSWORD_STATE,
} = types;

export const initialState = {
  currentPassword: '',
  newPassword: '',
  currentPwdCheck: 'normal',
  confirmPassword: '',
  errorType: '',
  changePasswordError: '',
  type: '',
};

const navigateToHome = (state, payload) => ({
  ...state,
  validationError: '',
  changePasswordError: '',
  type: '',
  passwordStatus: '',
  passwordUpdateStatus: '',
});

const mergeWithSetValue = (state, payload, error) => ({
  ...state,
  ...payload,
  changePasswordError: error || '',
  // currentPwdCheck: 'normal',
  // newPassword: '',
  // currentPassword: '',
  // confirmPassword: '',
  validationError: '',
  clearPasswordAlerts: true,
});

const setChangePasswordFailState = (state, payload, error) => ({
  ...state,
  ...payload,
  changePasswordError: error || '',
  currentPwdCheck: 'normal',
  // password: '',
  validationError: '',
  clearPasswordAlerts: true,
});

const setDisplayState = (state, payload) => ({
  ...state,
  ...payload,
  validationError: '',
  clearPasswordAlerts: false,
  passwordUpdateStatus: '',
});

const setCurrentPasswordError = (state, payload) => ({
  ...state,
  ...payload,
});

const setSuccessState = (state, payload, error) => ({
  ...state,
  ...payload,
  changePasswordError: error || '',
  validationError: '',
  passwordStatus: '',
  clearPasswordAlerts: false,
});

const setRMLSuccessState = (state, payload, error) => ({
  ...state,
  ...payload,
  changePasswordError: error || '',
  validationError: '',
  clearPasswordAlerts: false,
  passwordStatus: '',
  passwordUpdateStatus: '',
});

const setPasswordError = (state, payload, error) => ({
  ...state,
  ...payload,
  changePasswordError: error || '',
  clearPasswordAlerts: false,
  validationError: 'VALIDATON_ERROR',
});

export const backButtonState = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
  changePasswordError: '',
  clearPasswordAlerts: false,
};

const submitState = state => ({
  ...state,
  ...backButtonState,
});

const clearChangePasswordState = state => ({
  ...state,
  ...backButtonState,
  passwordUpdateStatus: '',
});

export default handleActions(
  {
    // prettier-ignore
    [DISPLAY_CHANGE_PASSWORD]: (state, { payload }) => setDisplayState(state, payload),
    [CURRENT_PASSWORD_ERROR]: (state, { payload }) => setCurrentPasswordError(state, payload),
    [CHANGE_PASSWORD_SUCCESS]: (state, { payload }) => setSuccessState(
      state,
      {
        ...payload,
      },
      payload.reason,
    ),
    // eslint-disable-next-line max-len
    [CHANGE_PASSWORD_FAIL]: (state, { payload }) => setChangePasswordFailState(state, payload, payload.reason || payload),
    [CHANGE_PASSWORD_CANCEL]: (state, { payload }) => navigateToHome(state, payload),
    [SET_CURRENT_PASSWORD]: (state, { payload }) => mergeWithSetValue(state, payload),
    [SET_NEW_PASSWORD]: (state, { payload }) => mergeWithSetValue(state, payload),
    [SET_CONFIRM_PASSWORD]: (state, { payload }) => mergeWithSetValue(state, payload),
    // prettier-ignore
    [SET_VALIDATION_ERROR]: (state, { payload }) => setPasswordError(
      state,
      payload,
      payload.validationError,
    ),
    [CLEAR_CHANGEPASSWORD_STATE]: (state, { payload }) => clearChangePasswordState(state),
    [SUBMIT_STATE]: (state, { payload }) => submitState(state),
    // prettier-ignore
    [CHANGE_PASSWORD_REMIND_ME_LATER_SUCCESS]: (
      state, { payload },
    ) => setRMLSuccessState(state, payload),
    // eslint-disable-next-line max-len
    [CHANGE_PASSWORD_REMIND_ME_LATER_FAIL]: (state, { payload }) => mergeWithSetValue(state, payload, payload),
  },
  initialState,
);
