export const nnlConfig = {
  appsdk_version: '8.0.0',
  version: '8.0.0',
  fido_appid: 'https://apinoknoktest.adp.com:9443/facets/facets.uaf',
  bank_auth_website: 'https://apinoknoktest.adp.com:9443/bankauth',
  bank_auth_endpoint: 'https://apinoknoktest.adp.com:9443/nnlgateway/nnl/auth',
  nnlappsdk_url: 'https://online-dev.nj.adp.com/nnlappsdk-8.0.0',
  reg_endpoint: 'https://online-dev.nj.adp.com/nnlgateway/nnl/reg',
  auth_endpoint: 'https://online-dev.nj.adp.com/nnlgateway/nnl/auth',
  login_url: 'https://online-dev.nj.adp.com/signin/v1',
  fed_login_url: 'https://online-dev.nj.adp.com/',
  fed_logout_url: null,
  netverify_endpoint: 'https://online-dev.nj.adp.com',
  federation_enabled: false,
  sso_enabled: false,
  host: 'https://online-dev.nj.adp.com',
  reg_web_oob_url: 'https://online-dev.nj.adp.com/signin/v1/fido_verify_page',
  auth_web_oob_url: 'https://online-dev.nj.adp.com/signin/v1/fido_signin_consent',
};
