import { handleActions } from 'redux-actions';
import { types } from './question-collection-actions';

const {
  SET_FIRST_ANSWER,
  SET_SECOND_ANSWER,
  SET_THIRD_ANSWER,
  SET_FIRST_QUESTION,
  SET_SECOND_QUESTION,
  SET_THIRD_QUESTION,
  DISPLAY_QUESTION_COLLECTION,
  CANCEL_QUESTION_COLLECTION,
  SAVE_QNA_FAIL,
  SKIP_QUESTION_COLLECTION_FAIL,
} = types;

export const initialState = {
  errorMessage: '',
  firstAnsInlineErrMessage: '',
  secondAnsInlineErrMessage: '',
  thirdAnsInlineErrMessage: '',
  firstQuestion: '',
  secondQuestion: '',
  thirdQuestion: '',
  firstAnswer: '',
  secondAnswer: '',
  thirdAnswer: '',
};

const mergeWithSetValue = (state, payload, error) => ({
  ...state,
  ...payload,
  errorMessage: error || '',
});

const navigateToHome = (state, payload) => ({
  ...initialState,
});

export default handleActions(
  {
    [SET_FIRST_ANSWER]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
      firstAnsInlineErrMessage: '',
    }),
    [SET_SECOND_ANSWER]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
      secondAnsInlineErrMessage: '',
    }),
    [SET_THIRD_ANSWER]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
      thirdAnsInlineErrMessage: '',
    }),
    // eslint-disable-next-line max-len
    [SAVE_QNA_FAIL]: (state, { payload }) => mergeWithSetValue(state, payload, payload.errorMessage || ''),

    // eslint-disable-next-line max-len
    [SKIP_QUESTION_COLLECTION_FAIL]: (state, { payload }) => mergeWithSetValue(state, payload, payload.errorMessage || ''),

    // prettier-ignore
    [DISPLAY_QUESTION_COLLECTION]: (state, { payload }) => mergeWithSetValue(
      state, payload,
    ),

    // prettier-ignore
    [CANCEL_QUESTION_COLLECTION]: (state, { payload }) => navigateToHome(state, payload),

    [SET_FIRST_QUESTION]: (state, { payload }) => mergeWithSetValue(state, payload),
    [SET_SECOND_QUESTION]: (state, { payload }) => mergeWithSetValue(state, payload),
    [SET_THIRD_QUESTION]: (state, { payload }) => mergeWithSetValue(state, payload),
  },
  initialState,
);
