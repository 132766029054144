import React from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { SdfButton, SdfInput, SdfAlert } from '@synerg/react-components';
import { isValidPasscode, isNotBlank, isEnterKeyPressed } from '../../util/misc';
import { constants } from './channel-collection-constants';

const { EMAIL } = constants;

const PasscodePage = (props) => {
  const {
    intl,
    formattedChannelInfo,
    setPasscode,
    passcode,
    submitPasscode,
    cancelPasscodeCollection,
    resendPasscodeConfirmed,
    toggleResendConfirmationPage,
    currentlySending,
    passcodeFailErrorMessage,
    autoClose,
    isAppView,
  } = props;

  const isSubmitButtonDisabled =
    currentlySending || !isValidPasscode(passcode) || isNotBlank(passcodeFailErrorMessage);

  const onSubmit = () => {
    !isSubmitButtonDisabled && submitPasscode();
  };

  const handleKeyPress = (event) => {
    if (event.charCode === 13 && !isSubmitButtonDisabled) {
      event.preventDefault();
      submitPasscode();
    }
  };

  const passcodeMask = {
    mask: 'Number',
  };

  return (
    <div id="passcode-page">
      <div
        id="passcode_page_title_id"
        className="font-normal text-md leading-md mt-2 mb-6"
      >
        { formattedChannelInfo && formattedChannelInfo.type === EMAIL ? (
          <>
            <FormattedMessage
              id="channelCollection_verification_code_email"
              defaultMessage="We sent an email with your code to "
              description="passcode page title"
            />
            <strong>{ formattedChannelInfo && formattedChannelInfo.address }</strong>
          </>
        ) : (
          <>
            <FormattedMessage
              id="channelCollection_verification_code_message"
              defaultMessage="We sent a verification code to "
              description="passcode page title"
            />
            <strong>{ formattedChannelInfo && formattedChannelInfo.formattedNumber }</strong>
          </>
        ) }

        { '. ' }
        <br />
        <FormattedMessage
          id="channelCollection_passcode_valid"
          defaultMessage="This code is valid for 10 minutes."
          description="passcode page title"
        />
      </div>
      { passcodeFailErrorMessage && (
        <SdfAlert
          id="passcodeFailErrorMessage"
          className="mb-2"
          type="banner"
          autoClose={ autoClose }
          status="error"
          slot="alert"
          closeable="true"
        >
          { intl.formatMessage({
            id: passcodeFailErrorMessage,
            defaultMessage: 'Invalid email address format.',
          }) }
        </SdfAlert>
      ) }
      <div className="flex-row flex-1 text-center mb-8">
        <SdfInput
          id="passcode"
          autofocus
          inputType="decimal"
          advancedMask={ passcodeMask }
          maxlength={ 6 }
          label={ intl.formatMessage({
            id: 'channelCollection_code',
            defaultMessage: 'Code',
          }) }
          input-width="xs"
          value={ passcode }
          state={ passcodeFailErrorMessage ? 'error' : 'normal' }
          onSdfInput={ eve => setPasscode(eve.detail) }
          onKeyPress={ handleKeyPress }
        />
      </div>

      { !resendPasscodeConfirmed && (
        <div className="flex-row flex-1 text-center">
          <div className="inline-block">
            <div
              id="passcode_page_title_resend"
              className="font-medium md:font-normal leading-md"
            >
              <FormattedMessage
                id="channelCollection_otp_not_received"
                defaultMessage="Didn’t receive a code?"
              />
            </div>
          </div>
          <div className="inline-block ml-2">
            <SdfButton
              id="resendCode"
              size="sm"
              emphasis="tertiary"
              variant="text"
              aria-label="Resend verification code"
              disabled={ currentlySending || undefined }
              onClick={ () => {
                toggleResendConfirmationPage(true);
              } }
              onKeyPress={ (evt) => {
                isEnterKeyPressed(evt.key) && toggleResendConfirmationPage(true);
              } }
            >
              <FormattedMessage
                id="channelCollection_resend"
                defaultMessage="Resend code"
              />
            </SdfButton>
          </div>
        </div>
      ) }

      <div className="flex md:flex-row md:justify-end gap flex-col items-center mt-4 md:mt-10">
        <SdfButton
          id="onSubmitPasscode"
          class={ isAppView ? 'w-full' : 'max-w-xs w-full md:w-auto mt-2 md:mt-0' }
          size="sm"
          emphasis="primary"
          disabled={ isSubmitButtonDisabled || undefined }
          onClick={ () => {
            onSubmit();
          } }
          onKeyPress={ (evt) => {
            isEnterKeyPressed(evt.key) && onSubmit();
          } }
        >
          <FormattedMessage
            id="olp_submit"
            defaultMessage="Submit"
          />
        </SdfButton>
        <SdfButton
          id="cancel-passcode-collection"
          class={ isAppView ? 'w-full' : 'max-w-xs w-full md:w-auto mt-0 md:mt-0' }
          size="sm"
          emphasis="secondary"
          onClick={ () => {
            !currentlySending && cancelPasscodeCollection();
          } }
          onKeyPress={ (evt) => {
            !currentlySending && isEnterKeyPressed(evt.key) && cancelPasscodeCollection();
          } }
        >
          <FormattedMessage
            id="olp_cancel"
            defaultMessage="CANCEL"
            description="verification code cancel button"
          />
        </SdfButton>
      </div>
    </div>
  );
};

PasscodePage.propTypes = {
  formattedChannelInfo: PropTypes.shape({}),
  setPasscode: PropTypes.func,
  passcode: PropTypes.string,
  submitPasscode: PropTypes.func,
  currentlySending: PropTypes.bool,
  isAppView: PropTypes.bool,
  cancelPasscodeCollection: PropTypes.func,
  resendPasscodeConfirmed: PropTypes.bool,
  autoClose: PropTypes.bool,
  toggleResendConfirmationPage: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
  passcodeFailErrorMessage: PropTypes.string,
};

PasscodePage.contextTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(PasscodePage);
