import { of, concat, from } from 'rxjs';
import {
  map, switchMap, catchError, tap, mergeMap,
} from 'rxjs/operators';
import { push } from 'connected-react-router';
import { ofType } from 'redux-observable';
import { createAction } from 'redux-actions';
import { types } from '..';
import {
  getErrorMessage,
  sendingAsyncRequest,
  getFidoAuthErrorMessage,
  getErrorMessageInvalidUser,
} from '../../../helpers/epics';
import { constants } from '../login-constants';
import {
  SUCCESSFULL_FIDO_AUTHENTICATION,
  FIDO_AUTH_FAIL,
  REMIND_ME_ACTION,
} from '../../../../util/pendo/constants';
import {
  setCookie,
  getTargetUrl,
  getUrlLocationSearch,
  getDevicePrint,
  getController,
  displayFidoRegMac,
  getCookieCacheInfo,
} from '../../../../util/misc';
import {
  getAjaxJsonHeader,
  setDeviceTokenForRequiredTypes,
  isLocalStorageSupported,
  checkNnlToggle,
  trackEvents,
  getPasskeyUserHandle,
  getadpAccountId,
  isNotBlank,
} from '../../../../util/misc/common';
import { types as tcType } from '../../terms-and-conditions/terms-and-conditions-actions';
import { types as stepUpType } from '../../step-up/step-up-actions';
import { types as questionCollectionType } from '../../question-collection/question-collection-actions';
import { types as channelCollectionType } from '../../channel-collection';
import { types as changePasswordType } from '../../change-password/change-password-actions';
import {
  ERROR_PAGE,
  FIDO_DEVICE_LIST,
  HOME,
  FIDO_SETUP_ERROR_PAGE,
} from '../../../../routes/router-url-constants';
import { USER_ACCOUNT_DATA } from '../../../../util/misc/constants';
import { constants as channelCollectionConstants } from '../../../../components/channel-collection/channel-collection-constants';
import { PendoUtil } from '../../../../util/pendo';

const {
  AUTHENTICATE_REQUEST,
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_FAIL,
  AUTHENTICATE_FIDO2,
  SET_COOKIE,
  REDIRECT_URL,
  AUTHENTICATE_MORE,
  AUTHENTICATE_OVER,
  DISPLAY_ERROR_PAGE,
  DISPLAY_FIDO_DEVICE_LIST,
  NAVIGATE_HOME_SWITCH_PASSWORD,
  FIDO_AUTH_CANCEL,
  VERIFY_USERID_REQUEST,
  FIDO_STEPUP_REQUEST,
  FIDO_REMIND_ME,
  SET_FIDO_ATTRIBUTES,
  FIDO_REGISTER,
} = types;
const { DISPLAY_TERMS_AND_CONDITIONS } = tcType;
const { DISPLAY_CHANNELS } = stepUpType;
const { DISPLAY_QUESTION_COLLECTION } = questionCollectionType;
const {
  DISPLAY_CHANNEL_COLLECTION,
  DISPLAY_MOBILE_COLLECTION,
  DISPLAY_EMAIL_COLLECTION,
  DISPLAY_LANDLINE_COLLECTION,
  DISPLAY_VOICE_BIOMETRICS_COLLECTION,
} = channelCollectionType;
const { DISPLAY_CHANGE_PASSWORD } = changePasswordType;

const {
  AUTHENTICATE_ENDPOINT,
  PASSWORD_EXPIRED,
  PASSWORD_ABOUT_TO_EXPIRE,
  RESET,
  COOKIE_REMEMBER_ME,
  COOKIE_GREET_FNAME,
  TYPE_SIGNED_IN,
  FIDO2ELIGIBLE,
  FIDO_CHALLENGE_RESPONSE,
  PASSKEY_REMIND_ME_RESPONSE,
  ERROR_COUNT,
} = constants;

const {
  MOBILE_COLLECTION,
  EMAIL_COLLECTION,
  LANDLINE_COLLECTION,
  VOICE_BIOMETRICS_COLLECTION,
} = channelCollectionConstants;

const authenticateSuccess = createAction(AUTHENTICATE_SUCCESS);
const authenticateFail = createAction(AUTHENTICATE_FAIL);
const setCookieEvent = createAction(SET_COOKIE);
const redirectURL = createAction(REDIRECT_URL);
const authenticateMore = createAction(AUTHENTICATE_MORE);
const authenticateOver = createAction(AUTHENTICATE_OVER);
const tcDisplay = createAction(DISPLAY_TERMS_AND_CONDITIONS);
const displayChannels = createAction(DISPLAY_CHANNELS);
const displayQuestionCollection = createAction(DISPLAY_QUESTION_COLLECTION);
const displayFidoDeviceList = createAction(DISPLAY_FIDO_DEVICE_LIST);
const displayChannelCollection = createAction(DISPLAY_CHANNEL_COLLECTION);
const displayMobileCollection = createAction(DISPLAY_MOBILE_COLLECTION);
const displayLandlineCollection = createAction(DISPLAY_LANDLINE_COLLECTION);
const displayVoiceBioMetricsCollection = createAction(DISPLAY_VOICE_BIOMETRICS_COLLECTION);
const displayEmailCollection = createAction(DISPLAY_EMAIL_COLLECTION);
const displayChangePassword = createAction(DISPLAY_CHANGE_PASSWORD);
const displayErrorPage = createAction(DISPLAY_ERROR_PAGE);
const fidoAuthCancel = createAction(FIDO_AUTH_CANCEL);
const fidoRegister = createAction(FIDO_REGISTER);
const showFidoCancelPage = createAction(types.SHOW_FIDO_CANCEL_PAGE);

const verifyUserId = createAction(VERIFY_USERID_REQUEST);

const handleResponse = (responseObj) => {
  const { rememberMe } = responseObj;

  if (rememberMe.isChecked) {
    return setCookieEvent({ cookieName: COOKIE_REMEMBER_ME, cookieValue: rememberMe.userId });
  }

  return authenticateOver();
};

const handleFidoRedirect = (responseObj) => {
  const { session, challenge } = responseObj.response;

  return authenticateMore({
    session,
    challenge,
  });
};

const handleFidoRemindMe = (responseObj) => {
  const { redirectUrl, accessToken, type } = responseObj.response.result;

  trackEvents(REMIND_ME_ACTION);
  if (type === 'PASSKEY_REMIND_ME_RESULT' && accessToken) return redirectURL({ url: getTargetUrl(redirectUrl) });

  console.log('error senario for fido remind me', redirectUrl);
  return redirectURL({ url: getTargetUrl(redirectUrl) });
};

const handleRedirect = (responseObj) => {
  const {
    accessToken, redirectUrl, user, adpAccountId, fidoPromptStatus, type,
  } =
    responseObj.response.result || '';
  const { passwordStatus, passwordExpirationAt, skippable } = responseObj.response.challenge || '';
  const { accountAboutToLock } = responseObj.response.challenge || '';
  const lockError = accountAboutToLock === true ? 'ACCOUNT_ABOUT_TO_LOCK' : '';
  const {
    session, challenge, userTC, cookieCacheToken,
  } = responseObj.response;

  const { givenName } = user || '';

  setDeviceTokenForRequiredTypes(responseObj);

  if (accessToken) {
    return authenticateSuccess({
      accessToken,
      redirectUrl,
      givenName,
      userTC,
      passwordStatus,
      passwordExpirationAt,
      skippable,
      lockError,
      session,
      challenge: accessToken,
      adpAccountId,
      fidoPromptStatus,
      type,
      cookieCacheToken,
    });
  }
  return authenticateMore({
    userTC,
    passwordStatus,
    passwordExpirationAt,
    skippable,
    lockError,
    session,
    challenge,
    cookieCacheToken,
  });
};

// eslint-disable-next-line max-len
const containsMobilePrompt = collectionType => collectionType && collectionType.some(type => type.promptName === MOBILE_COLLECTION);

// eslint-disable-next-line max-len
const containsEmailPrompt = collectionType => collectionType && collectionType.some(type => type.promptName === EMAIL_COLLECTION);

// eslint-disable-next-line max-len
const containsLandlinePrompt = collectionType => collectionType && collectionType.some(type => type.promptName === LANDLINE_COLLECTION);

// eslint-disable-next-line max-len
const containsVoiceBioMetricsPrompt = collectionType => collectionType && collectionType.some(type => type.promptName === VOICE_BIOMETRICS_COLLECTION);

// eslint-disable-next-line max-len
const isCombinedPrompt = collectionType => containsMobilePrompt(collectionType) && containsEmailPrompt(collectionType);

const handleInternalUrlRedirect = (response) => {
  const { passwordStatus, challenge, session } = response;

  if (challenge && challenge.type === 'CONSENT_COLLECTION_CHALLENGE') {
    return tcDisplay({
      tcSession: session,
      tcChallenge: challenge,
    });
  }
  if (challenge && challenge.type === 'QUESTION_COLLECTION_CHALLENGE') {
    return displayQuestionCollection({
      questionCollectionDisplaySession: session,
      questionCollectionDisplayChallenge: challenge,
    });
  }
  if (challenge && challenge.type === 'COMMUNICATION_COLLECTION_CHALLENGE') {
    if (isCombinedPrompt(challenge.communicationCollectionType)) {
      return displayChannelCollection({
        channelCollectionSession: session,
        channelCollectionChallenge: challenge,
      });
    }

    if (containsMobilePrompt(challenge.communicationCollectionType)) {
      return displayMobileCollection({
        channelCollectionSession: session,
        channelCollectionChallenge: challenge,
      });
    }

    if (containsEmailPrompt(challenge.communicationCollectionType)) {
      return displayEmailCollection({
        channelCollectionSession: session,
        channelCollectionChallenge: challenge,
      });
    }

    if (containsLandlinePrompt(challenge.communicationCollectionType)) {
      return displayLandlineCollection({
        channelCollectionSession: session,
        channelCollectionChallenge: challenge,
      });
    }

    if (containsVoiceBioMetricsPrompt(challenge.communicationCollectionType)) {
      return displayVoiceBioMetricsCollection({
        channelCollectionSession: session,
        channelCollectionChallenge: challenge,
      });
    }
  }
  if (challenge && challenge.authenticators) {
    return displayChannels({
      channelsDisplaySession: session,
      channelsDisplayChallenge: challenge,
    });
  }
  if (
    passwordStatus === PASSWORD_EXPIRED ||
    passwordStatus === PASSWORD_ABOUT_TO_EXPIRE ||
    passwordStatus === RESET
  ) {
    const { passwordExpirationAt, skippable, next } = challenge || '';

    return displayChangePassword({
      passwordStatus,
      passwordExpirationAt,
      skippable,
      changePasswordDisplaySession: session,
      changePasswordActionUrl: next,
    });
  }
  return authenticateOver();
};

const submitFidoRequest = fidoSession => ({
  response: {
    type: FIDO_CHALLENGE_RESPONSE,
  },
  session: fidoSession,
});

const submitFidoRemindMeRequest = fidoSession => ({
  response: {
    type: PASSKEY_REMIND_ME_RESPONSE,
    passkeyRemindMeLater: true,
  },
  session: fidoSession,
});

const submitRequest = (
  apiSessionSubmit,
  password,
  locale,
  riskSessionId,
  userid,
  cookieCacheInfo,
) => ({
  response: {
    type: 'PASSWORD_VERIFICATION_RESPONSE',
    password,
    locale,
  },
  session: apiSessionSubmit,
  riskSessionId,
  cookieCacheInfo,
});
// eslint-disable-next-line max-len
const authenticateService = (
  ajax,
  apiSessionSubmit,
  password,
  rememberMe,
  locale,
  nextActionUrl,
  riskSessionId,
  userid,
  cookieCacheInfo,
  count,
) => ajax({
  url: nextActionUrl || `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(
    submitRequest(apiSessionSubmit, password, locale, riskSessionId, userid, cookieCacheInfo),
  ),
}).pipe(
  mergeMap((response) => {
    response.rememberMe = rememberMe;
    return concat(of(handleResponse(response)), of(handleRedirect(response)));
  }),
  catchError((err) => {
    if (
      err.response &&
        (err.response.code === 'EMPLOYEE_ACCOUNT_SUSPENDED' ||
          err.response.code === 'ACCOUNT_SUSPENDED' ||
          err.response.code === 'EMPLOYEE_ACCOUNT_INACTIVE' ||
          err.response.code === 'QUESTIONNAIRE_ACCOUNT_LOCKED')
    ) {
      const { code: errorCode } = err.response;

      return of(
        displayErrorPage({
          errorCode,
        }),
      );
    }

    if (
      err.response &&
        (err.response.code === 'ACCOUNT_LOCKED' ||
          err.response.code === 'ADMIN_ACCOUNT_LOCKED' ||
          err.response.code === 'INVALID_CREDENTIALS')
    ) {
      const errCount = window.env !== undefined ? window.env.ERROR_COUNT : 4;

      if (count > errCount) {
        let resp = err.response;

        resp =
            err.response.code === 'INVALID_CREDENTIALS' ? { code: 'ACCOUNT_LOCKED' } : err.response;
        const { code: errorCode } = resp;

        return of(
          displayErrorPage({
            errorCode,
          }),
        );
      }
      return of(authenticateFail(getErrorMessageInvalidUser(err, count, errCount)));
    }

    return of(authenticateFail(getErrorMessage(err)));
  }),
);

// prettier-ignore
export const authenticateEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(AUTHENTICATE_REQUEST),
  switchMap(() => {
    const {
      userid, password, rememberCheckBox, verifyUserActionUrl, count, useridVerified,
    } = state$.value.login;
    const { apiSessionSubmit } = state$.value.login;
    const { locale } = state$.value.locale;
    const { riskSessionId, isAppView } = state$.value.signin;
    const rememberMe = {
      isChecked: rememberCheckBox || false,
      userId: userid || '',
    };
    const cookieCacheInfo =
    isAppView ? getCookieCacheInfo(rememberMe.isChecked, userid) : undefined;

    return sendingAsyncRequest(
      authenticateService(
        ajax, apiSessionSubmit, password, rememberMe, locale,
        verifyUserActionUrl, riskSessionId, userid, cookieCacheInfo, count, useridVerified,
      ),
    );
  }),
);

// prettier-ignore
export const authenticateFido2Epic = (action$, state$) => action$.pipe(
  ofType(AUTHENTICATE_FIDO2),
  switchMap(() => {
    const {
      userid,
      apiSessionSubmit,
    } = state$.value.login;
    const { signStartActionUrl, riskSessionId } = state$.value.signin;

    const accountId = getadpAccountId(userid.toLowerCase());

    const controller = getController();

    return from(
      controller.signInWithFIDO2(userid, apiSessionSubmit, getDevicePrint(), 'USER_IDENTIFY', signStartActionUrl, riskSessionId, accountId),
    );
  }),
  mergeMap((response) => {
    const { rememberCheckBox, userid } = state$.value.login || '';

    const rememberMe = {
      isChecked: rememberCheckBox || false,
      userId: userid,
    };

    if (
      response &&
        (response.resultType === 'ACCOUNT_LOCKED' ||
        response.resultType === 'EMPLOYEE_ACCOUNT_SUSPENDED' ||
        response.resultType === 'ACCOUNT_SUSPENDED' ||
        response.resultType === 'EMPLOYEE_ACCOUNT_INACTIVE' ||
        response.resultType === 'QUESTIONNAIRE_ACCOUNT_LOCKED')
    ) {
      const { resultType: errorCode } = response;

      return of(
        displayErrorPage({
          errorCode,
        }),
      );
    }

    if (
      response &&
        (response.resultType === 'DENIED_ACCESS_RISK' ||
        response.resultType === 'DENIED_ACCESS_RISK_ASSESSMENT')
    ) {
      const { resultType: errorCode } = response;

      trackEvents(FIDO_AUTH_FAIL);

      return of(
        fidoAuthCancel({
          errorCode,
        }),
      );
    }

    if (response) {
      response.rememberMe = rememberMe;
      return concat(of(handleResponse(response)), of(handleFidoResponse(response)));
    }

    trackEvents(FIDO_AUTH_FAIL);
    return of(fidoAuthCancel());
  }),
  catchError(() => {
    trackEvents(FIDO_AUTH_FAIL);

    return of(fidoAuthCancel());
  }),
);

const handleFidoResponse = (response) => {
  const {
    accessToken, resultType, redirectUrl, givenName, challenge, session,
  } = response || '';

  if (challenge && !accessToken) {
    trackEvents(SUCCESSFULL_FIDO_AUTHENTICATION);
    return authenticateMore({
      session,
      challenge,
    });
  }

  if (resultType === 'SUCCESS' && accessToken) {
    trackEvents(SUCCESSFULL_FIDO_AUTHENTICATION);
    return authenticateSuccess({
      accessToken,
      redirectUrl,
      givenName,
      session,
      challenge,
    });
  }
  trackEvents(FIDO_AUTH_FAIL);

  return fidoAuthCancel();
};

export const authenticateSuccessEpic = (action$, state$) => action$.pipe(
  ofType(AUTHENTICATE_SUCCESS),
  mergeMap(
    ({
      payload: {
        redirectUrl, givenName, adpAccountId, fidoPromptStatus, type, session,
      },
    }) => {
      const {
        rememberCheckBox, switchToPassword, userid, cookieCacheToken,
      } = state$.value.login;
      const { UVPAA } = state$.value.signin || false;
      const { locale } = state$.value.locale;
      const { version } = state$.value.login.passwordRecovery || '';
      const userValue = userid.toLowerCase();

      if (
        UVPAA &&
          fidoPromptStatus &&
          fidoPromptStatus === FIDO2ELIGIBLE &&
          type &&
          type === TYPE_SIGNED_IN &&
          checkNnlToggle() &&
          displayFidoRegMac() &&
          !switchToPassword &&
          isLocalStorageSupported(true)
      ) {
        let userAccountData = {};

        if (adpAccountId && isLocalStorageSupported(true)) {
          userAccountData = localStorage.getItem(USER_ACCOUNT_DATA) || '{}';
          userAccountData = JSON.parse(userAccountData);
          userAccountData[userValue] = adpAccountId;
          userAccountData = JSON.stringify(userAccountData);
          localStorage.setItem(USER_ACCOUNT_DATA, userAccountData);
        }
        (async () => {
          try {
            PendoUtil.pendoVisitorIdUpdate(adpAccountId, locale, version);
          } catch {
              console.log('user is not tracked'); // eslint-disable-line
          }
        })();
        if (rememberCheckBox && givenName) {
          return concat(
            of(setCookieEvent({ cookieName: COOKIE_GREET_FNAME, cookieValue: givenName })),
            of(
              displayFidoDeviceList({
                adpAccountId,
                session,
              }),
            ),
          );
        }
        return of(
          displayFidoDeviceList({
            adpAccountId,
            session,
          }),
        );
      }

      if (rememberCheckBox && givenName) {
        return concat(
          of(setCookieEvent({ cookieName: COOKIE_GREET_FNAME, cookieValue: givenName })),
          of(redirectURL({ url: getTargetUrl(redirectUrl, cookieCacheToken) })),
        );
      }
      return of(redirectURL({ url: getTargetUrl(redirectUrl, cookieCacheToken) }));
    },
  ),
);

export const fidoStepUpRequestEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(FIDO_STEPUP_REQUEST),
  switchMap(() => {
    const { verifyUserActionUrl, session } = state$.value.login;

    return sendingAsyncRequest(fidoStepUpRequestService(ajax, session, verifyUserActionUrl));
  }),
);

const fidoStepUpRequestService = (ajax, fidoSession, nextActionUrl) => ajax({
  url: nextActionUrl || `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(submitFidoRequest(fidoSession)),
}).pipe(
  map(response => handleFidoRedirect(response)),
  catchError(err => of(showFidoCancelPage())),
);

export const fidoRemindMeEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(FIDO_REMIND_ME),
  switchMap(() => {
    const { verifyUserActionUrl, session, redirectUrl } = state$.value.login;

    return sendingAsyncRequest(
      fidoRemindMeService(ajax, session, verifyUserActionUrl, redirectUrl),
    );
  }),
);

const fidoRemindMeService = (ajax, fidoSession, nextActionUrl, redirectUrl) => ajax({
  url: nextActionUrl || `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(submitFidoRemindMeRequest(fidoSession)),
}).pipe(
  map(response => handleFidoRemindMe(response)),
  catchError(() => of(redirectURL({ url: getTargetUrl(redirectUrl) }))),
);

export const authenticateMoreEpic = action$ => action$.pipe(
  ofType(AUTHENTICATE_MORE),
  map(({ payload: response }) => handleInternalUrlRedirect(response)),
);

export const setFidoAttributesEpic = action$ => action$.pipe(
  ofType(SET_FIDO_ATTRIBUTES),
  map(({ response }) => fidoRegister(response)),
);

export const setCookieEpic = action$ => action$.pipe(
  ofType(SET_COOKIE),
  tap(({ payload: { cookieName, cookieValue } }) => setCookie(cookieName, cookieValue)),
  switchMap(({ payload: { cookieName, cookieValue } }) => of({ type: 'completed_cookie', payload: { cookieName, cookieValue } })),
);

export const displayErrorPageEpic = action$ => action$.pipe(
  ofType(DISPLAY_ERROR_PAGE),
  switchMap(() => of(push(ERROR_PAGE + getUrlLocationSearch()))),
);

export const displayFidoDeviceListEpic = action$ => action$.pipe(
  ofType(DISPLAY_FIDO_DEVICE_LIST),
  switchMap(() => of(push(FIDO_DEVICE_LIST + getUrlLocationSearch()))),
);

export const fidoAuthCancelEpic = action$ => action$.pipe(
  ofType(FIDO_AUTH_CANCEL),
  switchMap(() => of(push(FIDO_SETUP_ERROR_PAGE + getUrlLocationSearch()))),
);

export const navigateHomeForSwitchPasswordEpic = (action$, state$) => action$.pipe(
  ofType(NAVIGATE_HOME_SWITCH_PASSWORD),
  switchMap(() => {
    const devicePrint = getDevicePrint();
    const { adpUserId, userFName } = state$.value.login;

    if (isNotBlank(adpUserId) && isNotBlank(userFName)) {
      return of(push(HOME + getUrlLocationSearch()));
    }
    return concat(of(verifyUserId({ devicePrint })), of(push(HOME + getUrlLocationSearch())));
  }),
);
