import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SdfButton, SdfRadioButton } from '@synerg/react-components';
import { isEnterKeyPressed } from '../../util/misc';

const ChannelListForm = (props) => {
  const {
    cancelChannelList,
    sendOTP,
    channelsDisplaySession,
    channelsDisplayChallenge,
    currentlySending,
    isAppView,
  } = props;
  const { authenticators } = channelsDisplayChallenge;

  const mobiles = [];
  const emails = [];
  const typeResponse = 'AUTHENTICATOR_PICKER_RESPONSE';

  authenticators.map((item) => {
    if (item.type === 'EMAIL') {
      emails.push(item);
    } else if (item.type === 'MOBILE') {
      mobiles.push(item);
    }
    return item;
  });

  const handleKeyPressOnNexIn = (event) => {
    if (event.charCode === 13) {
      event.preventDefault();
      onSendOTP(authId, authType, authFormat);
    }
  };

  const channels = [
    {
      type: 'Mobile',
      data: mobiles,
      listButton: {
        iconName: 'mobile',
        id: 'mobileListButton',
        iconClassName: 'mobile_icon',
        selectable: true,
      },
      button: {
        id: 'mobile-button',
        style: 'link',
      },
      formattedMessage: {
        id: 'stepup.mobile.text',
        defaultMessage: 'Send me a text.',
        description: 'stepup mobile text',
      },
      communicator: 'formattedNumber',
    },
    {
      type: 'Email',
      data: emails,
      listButton: {
        iconName: 'envelope',
        id: 'emailListButton',
        iconClassName: 'envelope_icon',
        selectable: true,
      },
      button: {
        id: 'email-button',
        style: 'link',
      },
      formattedMessage: {
        id: 'stepup.email.text',
        defaultMessage: 'Send me an email.',
        description: 'stepup email text',
      },
      communicator: 'address',
    },
  ];
  const noChannelFound =
    (emails === undefined || emails.length === 0) &&
    (mobiles === undefined || mobiles.length === 0);

  const selectChannel = mobiles.length > 0 ? mobiles[0] : emails[0];

  const onSelectChannel = () => {
    if (mobiles.length > 0) {
      return mobiles[0].formattedNumber;
    }
    if (emails.length > 0) {
      return emails[0].address;
    }
    return '';
  };

  const [authFormat, setAuthFormat] = useState(onSelectChannel());
  const [authId, setAuthId] = useState(noChannelFound ? '' : selectChannel.authenticatorId);
  const [authType, setAuthType] = useState(noChannelFound ? '' : selectChannel.type);

  // eslint-disable-next-line max-len
  const onSendOTP = (authenticatorId, authenticatorType, authenticatorFormat) => !currentlySending &&
    sendOTP(
      typeResponse,
      authenticatorId,
      authenticatorType,
      authenticatorFormat,
      channelsDisplaySession,
    );

  const isChecked = item => authId === item.authenticatorId;

  return (
    <div
      id="frmLogin"
      className="flex flex-col"
    >
      <div>
        { noChannelFound ? (
          <div className="text-right">
            { ' ' }
            <FormattedMessage
              id="stepup.nochannel.found.text"
              defaultMessage="No Channel Found"
              description="stepup nochannel found text"
            />
            { ' ' }
          </div>
        ) : (
          <div>
            <div className="md:font-medium md:not-italic text-md md:leading-md mt-2">
              { ' ' }
              <FormattedMessage
                id="stepup.welcome.text"
                defaultMessage="Select an option to verify your identity"
                description="stepup title"
              />
            </div>
            <div
              className={
                isAppView ? 'flex-row flex-1 pt-8' : 'mx-auto my-0 items-center pt-8 table'
              }
            >
              { channels.map(channel => channel.data.map(item => (
                <div>
                  <SdfRadioButton
                    key={ item.authenticatorId }
                    iconName={ channel.listButton.iconName }
                    id={ channel.listButton.id }
                    data-testid={ channel.listButton.id }
                    className="pb-4 md:font-bold"
                    iconClassName={ channel.listButton.iconClassName }
                    selectable={ channel.listButton.selectable }
                    onSdfChange={ () => {
                      setAuthId(item.authenticatorId);
                      setAuthType(item.type);
                      setAuthFormat(item[channel.communicator]);
                    } }
                    checked={ isChecked(item) }
                    onKeyPress={ handleKeyPressOnNexIn }
                  >
                    <span>
                      <div className="font-bold">
                        <FormattedMessage
                          id={ channel.formattedMessage.id }
                          defaultMessage={ channel.formattedMessage.defaultMessage }
                          description={ channel.formattedMessage.description }
                        />
                      </div>
                      { item[channel.communicator] }
                    </span>
                  </SdfRadioButton>
                  <br />
                </div>
              ))) }
            </div>
            <div
              id="btnCancel"
              className={
                isAppView ?
                  'flex-row flex-1 mt-10 text-center' :
                  'flex md:flex-row-reverse mt-8 flex-col items-center'
              }
            >
              <SdfButton
                buttonStyle="link"
                data-testid="nextBtn"
                id="next-Channel"
                emphasis="primary"
                size="lg"
                className={ isAppView ? 'w-full p-2' : 'w-full max-w-xs md:min-w-32 md:w-auto' }
                onClick={ () => {
                  onSendOTP(authId, authType, authFormat);
                } }
                onKeyPress={ (event) => {
                  isEnterKeyPressed(event.key) && onSendOTP(authId, authType, authFormat);
                } }
              >
                <span>
                  <FormattedMessage
                    id="stepup.next"
                    defaultMessage="Next"
                    description="stepup next button"
                  />
                </span>
              </SdfButton>
              <SdfButton
                buttonStyle="link"
                data-testid="cancelBtn"
                id="cancel-Channel"
                className={
                  isAppView ?
                    'w-full p-2' :
                    'md:p-4 mt-4 md:mt-0 w-full max-w-xs md:min-w-32 md:w-auto'
                }
                size="lg"
                onClick={ () => {
                  cancelChannelList();
                } }
                onKeyPress={ (event) => {
                  isEnterKeyPressed(event.key) && cancelChannelList();
                } }
              >
                <span>
                  <FormattedMessage
                    id="olp_cancel"
                    defaultMessage="CANCEL"
                    description="stepup cancel button"
                  />
                </span>
              </SdfButton>
            </div>
          </div>
        ) }
      </div>
    </div>
  );
};

ChannelListForm.propTypes = {
  challenge: PropTypes.shape({
    type: PropTypes.string,
    authenticators: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        authenticatorId: PropTypes.string,
        address: PropTypes.string,
      }),
    ),
  }),
  channelsDisplayChallenge: PropTypes.shape({
    type: PropTypes.string,
    authenticators: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        authenticatorId: PropTypes.string,
        address: PropTypes.string,
      }),
    ),
  }),
  cancelChannelList: PropTypes.func.isRequired,
  sendOTP: PropTypes.func.isRequired,
  channelsDisplaySession: PropTypes.string,
  currentlySending: PropTypes.bool,
  isAppView: PropTypes.bool,
};

export default ChannelListForm;
