import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { NEXO_NAMESPACE } from '../../helpers/constants';

export const types = createTypes(NEXO_NAMESPACE, 'SENDING_REQUEST');

const sendingRequest = createAction(types.SENDING_REQUEST, sending => sending);

export const actions = {
  sendingRequest,
};
