/* eslint-disable global-require */

export const ADP_LANG_LOCALE_COOKIE = 'ADPLangLocaleCookie';
export const ADP_LANG_LOCALE_COOKIE_EXPIRES = 60 * 60 * 24; //  24 hours
export const DEFAULT_LOCALE = 'en_US';

export const localeWithCountryMapping = {
  en_US: 'us',
  ar_SA: 'sa',
  cs_CZ: 'cz',
  de_DE: 'de',
  en_CA: 'ca',
  es_ES: 'es',
  es_US: 'us',
  fr_CA: 'ca',
  fr_FR: 'fr',
  hu_HU: 'hu',
  it_IT: 'it',
  it_CH: 'ch',
  ja_JP: 'jp',
  ko_KR: 'kr',
  nl_NL: 'nl',
  pl_PL: 'pl',
  pt_BR: 'br',
  pt_PT: 'pt',
  ru_RU: 'ru',
  sk_SK: 'sk',
  th_TH: 'th',
  zh_CN: 'cn',
  zh_TW: 'tw',
  da_DK: 'dk',
  fi_FI: 'fi',
  no_NO: 'no',
  ro_MO: 'mo',
  sv_SE: 'se',
  tr_TR: 'tr',
  vi_VN: 'vn',
  zh_HK: 'hk',
  ms_MY: 'my',
  in_ID: 'id',
  id_ID: 'id',
  en_AU: 'au',
  en_GB: 'gb',
  es_AR: 'ar',
  ro_RO: 'ro',
};
export const LOCALE_MAP = {
  en_US: { id: 1, language: 'English (US)' },
  ar_SA: { id: 2, language: 'Arabic (SA)' },
  cs_CZ: { id: 3, language: 'Čeština (CR)' },
  de_DE: { id: 4, language: 'Deutsch (DE)' },
  en_CA: { id: 5, language: 'English (CA)' },
  es_ES: { id: 6, language: 'Español (ES)' },
  es_US: { id: 7, language: 'Español (US)' },
  fr_CA: { id: 8, language: 'Français (CA)' },
  fr_FR: { id: 9, language: 'Français (FR)' },
  hu_HU: { id: 10, language: 'Magyar (HU)' },
  it_IT: { id: 11, language: 'Italiano (IT)' },
  ja_JP: { id: 12, language: '日本語 (JP)' },
  ko_KR: { id: 13, language: '한국어 (KR)' },
  nl_NL: { id: 14, language: 'Nederlands (NL)' },
  pl_PL: { id: 15, language: 'Polski (PL)' },
  pt_BR: { id: 16, language: 'Português (BR)' },
  pt_PT: { id: 17, language: 'Português (PT)' },
  ru_RU: { id: 18, language: 'Русский (RU)' },
  sk_SK: { id: 19, language: 'Slovenčina  (SK)' },
  th_TH: { id: 20, language: 'ไทย (TH)' },
  zh_CN: { id: 21, language: '简体中文 (CN)' },
  zh_TW: { id: 22, language: '繁體中文 (TW)' },
  da_DK: { id: 23, language: 'Dansk (DK)' },
  fi_FI: { id: 24, language: 'suomi (FI)' },
  no_NO: { id: 25, language: 'Noors (NO)' },
  ro_MO: { id: 26, language: 'Română (MO)' },
  sv_SE: { id: 27, language: 'Svenska (SE)' },
  tr_TR: { id: 28, language: 'Türkçe (TR)' },
  vi_VN: { id: 29, language: 'Tiếng Việt (VN)' },
  zh_HK: { id: 30, language: '繁體中文 (HK)' },
  ms_MY: { id: 31, language: 'Malay (MY)' },
  in_ID: { id: 32, language: 'Indonesian (IN)' },
  en_AU: { id: 33, language: 'English (AU)' },
  en_GB: { id: 34, language: 'English (GB)' },
  ro_RO: { id: 35, language: 'Română (RO)' },
  it_CH: { id: 36, language: 'Italiano (CH)' },
  es_AR: { id: 37, language: 'Español (AR)' },
  id_ID: { id: 38, language: 'Indonesian (ID)' },
  he_IL: { id: 39, language: 'תירבע (IL)' },
};

export const DEFAULT_OPTIONS_MAP = [
  { value: 'en_US', label: 'English (US)' },
  { value: 'fr_CA', label: 'Français (CA)' },
];

export const LOCALE_MAP_LIST = [
  { value: 'en_US', label: 'English (US)' },
  { value: 'ar_SA', label: 'Arabic (SA)' },
  { value: 'cs_CZ', label: 'Čeština (CR)' },
  { value: 'de_DE', label: 'Deutsch (DE)' },
  { value: 'en_CA', label: 'English (CA)' },
  { value: 'es_ES', label: 'Español (ES)' },
  { value: 'es_US', label: 'Español (US)' },
  { value: 'fr_CA', label: 'Français (CA)' },
  { value: 'fr_FR', label: 'Français (FR)' },
  { value: 'hu_HU', label: 'Magyar (HU)' },
  { value: 'it_IT', label: 'Italiano (IT)' },
  { value: 'ja_JP', label: '日本語 (JP)' },
  { value: 'ko_KR', label: '한국어 (KR)' },
  { value: 'nl_NL', label: 'Nederlands (NL)' },
  { value: 'pl_PL', label: 'Polski (PL)' },
  { value: 'pt_BR', label: 'Português (BR)' },
  { value: 'pt_PT', label: 'Português (PT)' },
  { value: 'ru_RU', label: 'Русский (RU)' },
  { value: 'sk_SK', label: 'Slovenčina  (SK)' },
  { value: 'th_TH', label: 'ไทย (TH)' },
  { value: 'zh_CN', label: '简体中文 (CN)' },
  { value: 'zh_TW', label: '繁體中文 (TW)' },
  { value: 'da_DK', label: 'Dansk (DK)' },
  { value: 'fi_FI', label: 'suomi (FI)' },
  { value: 'no_NO', label: 'Noors (NO)' },
  { value: 'ro_MO', label: 'Română (MO)' },
  { value: 'sv_SE', label: 'Svenska (SE)' },
  { value: 'tr_TR', label: 'Türkçe (TR)' },
  { value: 'vi_VN', label: 'Tiếng Việt (VN)' },
  { value: 'zh_HK', label: '繁體中文 (HK)' },
  { value: 'ms_MY', label: 'Malay (MY)' },
  { value: 'in_ID', label: 'Indonesian (IN)' },
  { value: 'en_AU', label: 'English (AU)' },
  { value: 'en_GB', label: 'English (GB)' },
  { value: 'ro_RO', label: 'Română (RO)' },
  { value: 'it_CH', label: 'Italiano (CH)' },
  { value: 'es_AR', label: 'Español (AR)' },
  { value: 'id_ID', label: 'Indonesian (ID)' },
  { value: 'he_IL', label: 'תירבע (IL)' },
];

export default {
  ADP_LANG_LOCALE_COOKIE,
  ADP_LANG_LOCALE_COOKIE_EXPIRES,
  DEFAULT_LOCALE,
  LOCALE_MAP,
  DEFAULT_OPTIONS_MAP,
  LOCALE_MAP_LIST,
};
