import { isMobile } from 'react-device-detect';
import { handleActions, combineActions } from 'redux-actions';
import { types } from './login-actions';
import { constants } from './login-constants';
import { getCookie } from '../../../util/misc';

const {
  VERIFY_USERID_SUCCESS,
  VERIFY_USERID_FAIL,
  AUTHENTICATE_FIDO2,
  SET_USERID,
  SET_PASSWORD,
  SET_REMEMBERCHECKBOX,
  CHANGE_ADP_USER,
  SET_SWITCH_TO_PASSWORD,
  CHANGE_USER_FNAME,
  SET_ISADPUSER,
  RESET_STATE,
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_MORE,
  AUTHENTICATE_FAIL,
  DISPLAY_ERROR_PAGE,
  DISPLAY_FIDO_DEVICE_LIST,
  SHOW_SUCCESS_PAGE,
  FIDO_REGISTER,
  NAVIGATE_HOME_SWITCH_PASSWORD,
  FIDO_AUTH_CANCEL,
  SET_FIDO_ATTRIBUTES,
  SHOW_FIDO_CANCEL_PAGE,
  FIDO_STEPUP_REQUEST,
  SET_DIFFUSER,
} = types;

const { CHALLENGE_TYPE_NOT_DEFINED, COOKIE_GREET_FNAME } = constants;

export const initialState = {
  userid: '',
  adpUserId: undefined,
  switchToPassword: false,
  fidoAuthEligible: true,
  isFido: false,
  sendingRequest: false,
  password: '',
  useridVerified: false,
  errorMessage: '',
  passwordStatus: '',
  rememberCheckBox: false,
  isADPUser: false,
  isMobileDevice: isMobile,
  toggleNextBtn: !getCookie(COOKIE_GREET_FNAME),
  count: 0,
  prevUserId: '',
};

export const backButtonState = {
  password: '',
  errorMessage: '',
  passwordStatus: '',
  fidoAuthEligible: true,
  clearAlerts: false,
  sendingRequest: false,
  isADPUser: false,
  isMobileDevice: isMobile,
  switchToPassword: false,
  toggleNextBtn: !getCookie(COOKIE_GREET_FNAME),
};

const mergeWithSetValue = (state, payload, error) => ({
  ...state,
  ...payload,
  errorMessage: error || '',
});

const resetState = state => ({
  ...state,
  ...backButtonState,
});

export default handleActions(
  {
    // prettier-ignore
    [VERIFY_USERID_SUCCESS]: (state, { payload }) => mergeWithSetValue(
      state, {
        ...payload,
        useridVerified: payload.verifyUseridResponse.response.challenge.type !== 'PASSKEY_AUTHENTICATOR_CHALLENGE',
        challengeType: payload.verifyUseridResponse.response.challenge.type,
        apiSessionSubmit: payload.verifyUseridResponse.response.session,
        passwordRecovery: payload.verifyUseridResponse.response.challenge.passwordRecovery,
        verifyUserActionUrl: payload.verifyUseridResponse.response.challenge.next,
        fidoPromptStatus: payload.verifyUseridResponse.response.challenge.fidoPromptStatus,
        userHandle: payload.verifyUseridResponse.response.userHandle,
        fidoAuthEligible: payload.verifyUseridResponse.response.fidoAuthEligible || false,
        count: state.userid !== state.prevUserId ? 0 : state.count,
      },
    ),
    [SET_SWITCH_TO_PASSWORD]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
      useridVerified: true,
      fidoAuthEligible: true,
      toggleNextBtn: false,
    }),
    [SET_DIFFUSER]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
      toggleNextBtn: true,
    }),
    // prettier-ignore
    [VERIFY_USERID_FAIL]: (state, { payload }) => mergeWithSetValue(
      state, { useridVerified: false, count: 0 }, payload,
    ),
    // prettier-ignore
    [AUTHENTICATE_FIDO2]: (state, { payload }) => mergeWithSetValue(
      state, {
        ...payload,
        isFido: true,
        sendingRequest: true,
      },
    ),
    [SHOW_FIDO_CANCEL_PAGE]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
      sendingRequest: false,
    }),
    // prettier-ignore
    [AUTHENTICATE_SUCCESS]: (state, { payload }) => mergeWithSetValue(
      state, {
        ...payload,
        apiChallengeRespondsession: payload.session,
      },
    ),
    [FIDO_AUTH_CANCEL]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
      sendingRequest: false,
    }),
    // prettier-ignore
    [AUTHENTICATE_MORE]: (state, { payload }) => mergeWithSetValue(
      state, {
        ...payload,
        apiChallengeRespondsession: payload.session,
        authsession: payload.session,
        cookieCacheToken: payload.cookieCacheToken,
        count: payload.challenge.accountAboutToLock ? state.count + 1 : state.count,
      },
      payload.lockError,
    ),
    // prettier-ignore
    [AUTHENTICATE_FAIL]: (state, { payload }) => mergeWithSetValue(
      state, { count: state.count + 1, prevUserId: payload === 'INVALID_CREDENTIALS' ? state.userid : state.prevUserId }, payload, payload,
    ),
    // prettier-ignore
    [SET_USERID]: (state, { payload }) => mergeWithSetValue(
      state, {
        ...payload,
        useridVerified: false,
        clearAlerts: true,
        challengeType: CHALLENGE_TYPE_NOT_DEFINED,
        password: '',
        fidoAuthEligible: true,
      },
    ),
    [SET_PASSWORD]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
      clearAlerts: true,
      useridVerified: true,
    }),
    [CHANGE_ADP_USER]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
      fidoAuthEligible: true,
    }),
    // prettier-ignore
    [SET_REMEMBERCHECKBOX]: (state, { payload }) => mergeWithSetValue(
      state, {
        ...payload,
      },
    ),
    [combineActions(
      CHANGE_USER_FNAME,
      SET_ISADPUSER,
      DISPLAY_ERROR_PAGE,
      DISPLAY_FIDO_DEVICE_LIST,
    )]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
    }),
    [RESET_STATE]: (state, { payload }) => resetState(state),

    [SHOW_SUCCESS_PAGE]: (state, { payload }) => ({ ...state, ...payload }),

    [FIDO_REGISTER]: (state, { payload }) => ({ ...state, ...payload, sendingRequest: true }),

    [FIDO_STEPUP_REQUEST]: (state, { payload }) => ({ ...state, ...payload, sendingRequest: true }),

    [SET_FIDO_ATTRIBUTES]: (state, { payload }) => mergeWithSetValue(state, { ...payload }),

    [NAVIGATE_HOME_SWITCH_PASSWORD]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
      switchToPassword: true,
    }),
  },
  initialState,
);
