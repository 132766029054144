import React from 'react';
import PropTypes from 'prop-types';
import { getLocaleObjFromPropKey } from '../../util/locale/locale-helper';
import { isNotBlank, extractBrandingImageProperty } from '../../util/misc/common';

const Header = ({ productLogo, organizationLogo, locale }) => {
  let productLogoAlignment = '';

  let orgLogoAlignment = '';

  let productLogoImage = '';

  let organizationLogoImage = '';

  let productLogoLinkUrl = '';

  let orgLogoLinkUrl = '';

  const productImage = (logoId, logoImage, logoUrl) => isNotBlank(logoUrl) ? (
    <ImageLinkLogo
      id={ logoId }
      logo={ logoImage }
      logoUrl={ logoUrl }
      name={ logoId }
    />
  ) : (
    <ImageLogo
      id={ logoId }
      logo={ logoImage }
      name={ logoId }
    />
  );

  if (productLogo) {
    productLogoImage = extractBrandingImageProperty(productLogo, locale, false);
    productLogoAlignment = productLogo.alignment ? productLogo.alignment : 'right';
    productLogoLinkUrl = productLogo.linkUrl ?
      getLocaleObjFromPropKey(productLogo.linkUrl, locale) :
      '';
  }
  if (organizationLogo) {
    organizationLogoImage = extractBrandingImageProperty(organizationLogo, locale, false);
    orgLogoAlignment = organizationLogo.alignment ? organizationLogo.alignment : 'left';
    orgLogoLinkUrl = organizationLogo.linkUrl ?
      getLocaleObjFromPropKey(organizationLogo.linkUrl, locale) :
      '';
  }
  return (
    <div className="hidden md:block md:mt-6 md:mx-8">
      <span style={ { float: productLogoAlignment } }>
        { productImage('productLogo', productLogoImage, productLogoLinkUrl) }
      </span>
      <span style={ { float: orgLogoAlignment } }>
        { productImage('organizationLogo', organizationLogoImage, orgLogoLinkUrl) }
      </span>
    </div>
  );
};

Header.propTypes = {
  productLogo: PropTypes.shape({
    i18n: PropTypes.object,
    linkUrl: PropTypes.object,
  }),
  organizationLogo: PropTypes.shape({
    i18n: PropTypes.object,
    linkUrl: PropTypes.object,
  }),
  locale: PropTypes.string,
};

export const ImageLogo = (logo) => {
  if (logo.logo) {
    return (
      <img
        src={ logo.logo }
        height="32"
        alt={ logo.name }
        className="max-w-full"
      />
    );
  }
  return <span />;
};

export const ImageLinkLogo = logo => (
  <a href={ logo.logoUrl }>
    <img
      src={ logo.logo }
      height="32"
      alt={ logo.name }
      className="max-w-full"
    />
  </a>
);

export default Header;
