import { createTypes, asyncAction } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { NEXO_NAMESPACE } from '../../helpers/constants';

export const types = createTypes(
  NEXO_NAMESPACE,
  'CHANGE_PASSWORD_CANCEL',
  asyncAction('CHANGE_PASSWORD'),
  'SET_CURRENT_PASSWORD',
  'SET_NEW_PASSWORD',
  'SET_CONFIRM_PASSWORD',
  'SET_VALIDATION_ERROR',
  'SUBMIT_STATE',
  asyncAction('CHANGE_PASSWORD_REMIND_ME_LATER'),
  'SET_COOKIE',
  'FLOW_COMPLETE',
  'CURRENT_PASSWORD_ERROR',
  'DISPLAY_CHANGE_PASSWORD',
  'CLEAR_CHANGEPASSWORD_STATE',
);

const changePassword = createAction(types.CHANGE_PASSWORD_REQUEST);

const changePasswordCancel = createAction(types.CHANGE_PASSWORD_CANCEL);

const changePasswordRemindMeLater = createAction(types.CHANGE_PASSWORD_REMIND_ME_LATER_REQUEST);

const setCurrentPassword = createAction(types.SET_CURRENT_PASSWORD, currentPassword => ({
  currentPassword,
}));

const setNewPassword = createAction(types.SET_NEW_PASSWORD, newPassword => ({ newPassword }));

const setConfirmPassword = createAction(types.SET_CONFIRM_PASSWORD, confirmPassword => ({
  confirmPassword,
}));

const setValidationError = createAction(types.SET_VALIDATION_ERROR, validationError => ({
  validationError,
}));

const clearChangePasswordState = createAction(types.CLEAR_CHANGEPASSWORD_STATE);
const submitState = createAction(types.SUBMIT_STATE);
const flowComplete = createAction(types.FLOW_COMPLETE);

const setCookie = createAction(types.SET_COOKIE, (cookieName, cookieValue) => ({
  cookieName,
  cookieValue,
}));

export const actions = {
  changePassword,
  changePasswordCancel,
  setCurrentPassword,
  setNewPassword,
  setConfirmPassword,
  setValidationError,
  submitState,
  changePasswordRemindMeLater,
  flowComplete,
  setCookie,
  clearChangePasswordState,
};
