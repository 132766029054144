import { createTypes, asyncAction } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { NEXO_NAMESPACE } from '../../helpers/constants';

export const types = createTypes(
  NEXO_NAMESPACE,
  asyncAction('SEND_OTP'),
  'CANCEL_CHANNEL_LIST',
  'NAVIGATE_HOME',
  'SET_OTP',
  'SHOW_QNA',
  'SET_ANSWER',
  'DISPLAY_CHANNELS',
  'RESET_STATE_STEP_UP',
  'REDIRECT_HOME_SCREEN',
  asyncAction('VERIFY_CHALLENGE_QNA'),
  asyncAction('VERIFY_OTP'),
  asyncAction('REQUEST_NEW_CODE'),
);

// prettier-ignore
const sendOTP = createAction(
  // eslint-disable-next-line max-len
  types.SEND_OTP_REQUEST, (type, authenticatorId, authenticatorType, authenticatorFormat, channelsDisplaySession) => (
    {
      type,
      authenticatorId,
      authenticatorType,
      authenticatorFormat,
      channelsDisplaySession,
    }
  ),
);
// prettier-ignore
const verifyOTP = createAction(types.VERIFY_OTP_REQUEST);

const cancelChannelList = createAction(types.CANCEL_CHANNEL_LIST);

const resetStateStepUp = createAction(types.RESET_STATE_STEP_UP);

const requestNewCode = createAction(types.REQUEST_NEW_CODE_REQUEST);
const navigateToHome = createAction(types.NAVIGATE_HOME);

const setOTP = createAction(types.SET_OTP, otp => ({ otp }));
const setAnswer = createAction(types.SET_ANSWER, answer => ({ answer }));
const verifyChallengeQNA = createAction(types.VERIFY_CHALLENGE_QNA_REQUEST);
const redirectToHome = createAction(types.REDIRECT_HOME_SCREEN, (url, newTab) => ({ url, newTab }));

export const actions = {
  sendOTP,
  cancelChannelList,
  verifyOTP,
  setOTP,
  setAnswer,
  verifyChallengeQNA,
  requestNewCode,
  navigateToHome,
  resetStateStepUp,
  redirectToHome,
};
