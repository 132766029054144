import { without } from 'ramda';

export default (actionTypes, callback) => store => (next) => {
  let called = false;

  let pendingActionTypes = actionTypes || [];

  return (action) => {
    // eslint-disable-next-line callback-return
    const callbackReturn = next(action);

    if (!called) {
      pendingActionTypes = without(action.type, pendingActionTypes);
      if (pendingActionTypes.length === 0) {
        called = true;
        // eslint-disable-next-line callback-return
        callback();
      }
    }

    return callbackReturn;
  };
};
