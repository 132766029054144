/* eslint-disable max-len */
/* eslint-disable no-prototype-builtins */
/* eslint-disable spaced-comment */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
/* eslint-disable no-bitwise */
/* eslint-disable id-length */
/* eslint-disable no-param-reassign */
/* eslint-disable no-eq-null */
/* eslint-disable no-tabs */
/* eslint-disable callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable func-names */
/**
 * Renders a registration list for specified session.
 * If controller.filterSilentRegs is defined and set to true,
 * the code will filter out silent authenticator registrations.
 *
 * @param {object} fido FidoController.
 * @param {object} controller Controller.
 * @param {object} sessionData SessionData.
 */

export function getTimeDiffLabel(stamp) {
  const timeDiff = Math.floor((Date.now() - stamp) / 1000);

  let intervalType;

  let interval = Math.floor(timeDiff / (60 * 60 * 24 * 365));

  if (interval >= 1) {
    intervalType = 'year';
  } else {
    interval = Math.floor(timeDiff / (60 * 60 * 24));
    if (interval >= 1) {
      intervalType = 'day';
    } else {
      interval = Math.floor(timeDiff / (60 * 60));
      if (interval >= 1) {
        intervalType = 'hour';
      } else {
        interval = Math.floor(timeDiff / 60);
        if (interval >= 1) {
          intervalType = 'minute';
        } else {
          interval = timeDiff;
          intervalType = 'second';
        }
      }
    }
  }

  if (interval === 0) {
    return 'Just now';
  }

  return `${interval} ${intervalType}${interval > 1 ? 's' : ''} ago`;
}

/**
 * Performs filtering of specified list.
 * @param {array} registrationsList Source URL.
 * @param {array} aaidList List of required AAIDs.
 * @returns {array} A filtered list of registrations.
 */
export function filterRegistrationsList(registrationsList, aaidList) {
  return registrationsList.reduce((acc, item) => {
    const decodedHandle = atob(item.handle);

    try {
      const obj = JSON.parse(decodedHandle);

      if (aaidList.indexOf(obj[1]) === -1) {
        acc.push(item);
      }
    } catch (err) {
      console.log(`Error parsing string into array: ${decodedHandle}. Error: ${err}`); // eslint-disable-line
    }
    return acc;
  }, []);
}

/**
 * Loads script with specified url.
 * @param {string} url Source URL.
 * @returns {Promise} A promise that will be resolved in case of success
 * and rejected in case of failure.
 */
export function loadScript(url) {
  return new Promise((resolve, reject) => {
    const existing = document.querySelector(`script[src="${url}"]`);

    if (existing === null) {
      const script = document.createElement('script');

      script.src = url;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    } else {
      resolve();
    }
  });
}

/**
 * Loads iframe content from given URL if iframe is empty.
 * @param {string} url Source URL.
 * @returns {Promise} A promise that will be resolved either immediately if iframe already has content,
 * or when iframe content is loaded.
 */
export function loadIframe(url) {
  return new Promise((resolve) => {
    if (!document.getElementById('nnl_iframe').src) {
      document.getElementById('nnl_iframe').setAttribute('src', url);
      resolve();
    } else {
      resolve();
    }
  });
}

/**
 * Utility function determines whether an object is empty.
 * @param obj
 */
export function isObjectEmpty(obj) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

/**
 * Utility function determines whether string is null, undefined or empty string
 */
export function isStringNullOrEmpty(str) {
  return str == null || str == '';
}

/**
 * Returns mobile platform name based on user agent string.
 * @returns either 'windows', 'android', ios' or 'unknown'.
 */
export function getMobilePlatform() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"

  if ((/windows phone/i).test(userAgent)) {
    return 'window';
  }
  if ((/android/i).test(userAgent)) {
    return 'android';
  }
  // iOS detection from: https://stackoverflow.com/questions/9038625
  if (
    ((/iPad|iPhone|iPod/).test(userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window.MSStream
  ) {
    return 'ios';
  }
  return 'unknown';
}

/**
 * @returns {string} android version based on userAgent string (e.g. 6.0.1)
 */
export function getAndroidVersion() {
  const userAgent = navigator.userAgent;

  return userAgent.match(/android\s([0-9\.]*)/i)[1]; // eslint-disable
}

/**
 * @returns {string} iOS version based on userAgent string (e.g. 9.2)
 */
export function getIosVersion() {
  const userAgent = navigator.userAgent;
  // in userAgent the iOS version is devided with '_' (e.g. 9_2)
  // so we need to split and then join with '.'

  return userAgent
    .match(/\b[0-9]+_[0-9]+(?:_[0-9]+)?\b/)[0]
    .split('_')
    .join('.');
}

/**
 * Generates and returns UUID
 * @returns UUID
 */
export function generateUUID() {
  let dt = new Date().getTime();

  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;

    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });

  return uuid;
}
