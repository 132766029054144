export const constants = {
  GET_BRAND_ENDPOINT: '/api/brand-service/v1/brands',
  PRODUCT_ID: 'productId',
  ORGANIZATION_ID: 'organizationId',
  DEFAULT: 'default',
  LS_EXPIRY_PERIOD_IN_MINUTES_DEFAULT: 1 * 24 * 60,
  LOCAL_BRANDING_ENV_FILE_SUFFIX_DEFAULT: 'dev',
  BRAND_LS_PREFIX: 'brand_store_',
  BRAND_LS_EXPIRATION_SUFFIX: '_expiration',
  CONTENT_TYPE_JSON: 'application/json',
  CONSUMER_HEADER_VALUE: 'OLP',
  CURRENT_YEAR: '<CURRENT_YEAR>',
};
