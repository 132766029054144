const isBlank = str => typeof str == 'string' ?
  (str || '').trim().length === 0 :
  'Application Error. Please try again later';

export const getDefaultErrorMessage = 'Application Error. Please try again later';

// prettier-ignore
export const getErrorMessage = err => err && err.response &&
    !isBlank(err.response.code) ? err.response.code : getDefaultErrorMessage;

export const getFidoAuthErrorMessage = err => (!isBlank(err) ? err : getDefaultErrorMessage);

export const getErrorMessageInvalidUser = (err, count, errCount) => {
  if (count < errCount) {
    return 'INVALID_CREDENTIALS';
  }
  return 'ACCOUNT_ABOUT_TO_LOCK';
};
