import { handleActions } from 'redux-actions';
import { types } from './signin-actions';
import { isApp } from '../../../util/misc';

const { LOAD_ACCOUNTTYPE_SUCCESS, LOAD_ACCOUNTTYPE_FAIL } = types;

export const initialState = {};

export default handleActions(
  {
    [LOAD_ACCOUNTTYPE_SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.response.response,
      apisession: payload.response.response.session,
      signStartActionUrl: payload.response.response.identityProviders[0].next,
      UVPAA: payload.fidoSupported,
      riskSessionId: payload.riskSessionId,
      empTargetUrl: payload.targetRequired ?
        payload.response.response.empTargetUrl :
        payload.targetUrl,
      adminTargetUrl: payload.targetRequired ?
        payload.response.response.adminTargetUrl :
        payload.targetUrl,
      isAppView: isApp(),
    }),
    [LOAD_ACCOUNTTYPE_FAIL]: (state, { payload }) => ({ ...state, ...payload }),
  },
  initialState,
);
