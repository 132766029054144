import { handleActions } from 'redux-actions';
import { types } from './step-up-actions';

const {
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  CANCEL_CHANNEL_LIST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAIL,
  SET_OTP,
  SHOW_QNA,
  DISPLAY_CHANNELS,
  REQUEST_NEW_CODE_SUCCESS,
  REQUEST_NEW_CODE_FAIL,
  SET_ANSWER,
  VERIFY_CHALLENGE_QNA_FAIL,
  RESET_STATE_STEP_UP,
  REDIRECT_HOME_SCREEN,
} = types;

export const initialState = {
  otp: '',
  errorMessage: '',
};

export const resetStateStepUp = {
  otpDeadEndScenario: false,
};

const resetState = state => ({
  ...state,
  ...resetStateStepUp,
});

const mergeWithSetValue = (state, payload, error) => ({
  ...state,
  ...payload,
  errorMessage: error || '',
});

const navigateToHome = (state, payload) => ({
  ...state,
});

const redirectToHome = (state, payload) => ({
  ...state,
  validationError: '',
  changePasswordError: '',
  type: '',
  passwordStatus: '',
  passwordUpdateStatus: '',
});

export default handleActions(
  {
    // prettier-ignore
    [SEND_OTP_SUCCESS]: (state, { payload }) => mergeWithSetValue(
      state, {
        ...payload,
      },
    ),
    // prettier-ignore
    [SEND_OTP_FAIL]: (state, { payload }) => mergeWithSetValue(
      state, { otp: false }, payload,
    ),
    // prettier-ignore
    [VERIFY_OTP_SUCCESS]: (state, { payload }) => mergeWithSetValue(
      state, {
        ...payload,
      },
    ),
    // prettier-ignore
    [VERIFY_OTP_FAIL]: (state, { payload }) => mergeWithSetValue(
      state, payload, payload.error || payload,
    ),

    // prettier-ignore
    [SET_OTP]: (state, { payload }) => mergeWithSetValue(
      state, {
        ...payload,
      },
    ),
    // prettier-ignore
    [CANCEL_CHANNEL_LIST]: (state, { payload }) => navigateToHome(state, payload),
    [REDIRECT_HOME_SCREEN]: (state, { payload }) => redirectToHome(state, payload),
    [SHOW_QNA]: (state, { payload }) => mergeWithSetValue(state, payload),
    [DISPLAY_CHANNELS]: (state, { payload }) => mergeWithSetValue(state, payload),
    [REQUEST_NEW_CODE_SUCCESS]: (state, { payload }) => mergeWithSetValue(state, payload),
    [REQUEST_NEW_CODE_FAIL]: (state, { payload }) => mergeWithSetValue(state, payload, payload),
    [SET_ANSWER]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
    }),
    // eslint-disable-next-line max-len
    [VERIFY_CHALLENGE_QNA_FAIL]: (state, { payload }) => mergeWithSetValue(state, payload, payload.error || payload),
    [RESET_STATE_STEP_UP]: (state, { payload }) => resetState(state),
  },
  initialState,
);
