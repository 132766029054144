export const getLocale = (locale) => {
  switch (locale) {
    case 'en_GB':
    case 'en_CA':
      return 'en_US';
    case 'es_US':
    case 'es_ES':
      return 'es_AR';
    case 'in_ID':
      return 'id_ID';
    case 'it_CH':
      return 'it_IT';
    case 'ro_MO':
      return 'ro_RO';
    default:
      return locale;
  }
};

export const getAppLocale = (locale) => {
  switch (locale) {
    case 'en':
      return 'en_US';
    case 'ar':
      return 'ar_SA';
    case 'cs':
      return 'cs_CZ';
    case 'da':
      return 'da_DK';
    case 'de':
      return 'de_DE';
    case 'es':
      return 'es_AR';
    case 'fi':
      return 'fi_FI';
    case 'fr':
      return 'fr_FR';
    case 'he':
      return 'he_IL';
    case 'hu':
      return 'hu_HU';
    case 'id':
      return 'id_ID';
    case 'in':
      return 'id_ID';
    case 'it':
      return 'it_IT';
    case 'ja':
      return 'ja_JP';
    case 'ko':
      return 'ko_KR';
    case 'ms':
      return 'ms_MY';
    case 'nl':
      return 'nl_NL';
    case 'no':
      return 'no_NO';
    case 'pl':
      return 'pl_PL';
    case 'pt':
      return 'pt_PT';
    case 'ro':
      return 'ro_RO';
    case 'ru':
      return 'ru_RU';
    case 'sk':
      return 'sk_SK';
    case 'sv':
      return 'sv_SE';
    case 'th':
      return 'th_TH';
    case 'tr':
      return 'tr_TR';
    case 'vi':
      return 'vi_VN';
    case 'zh':
      return 'zh_CN';
    default:
      return 'en_US';
  }
};
