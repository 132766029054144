import { asyncAction, createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { NEXO_NAMESPACE } from '../../helpers/constants';

export const types = createTypes(NEXO_NAMESPACE, asyncAction('LOAD_SYNERGY_COMPONENTS'));

const loadSynergyComponents = createAction(types.LOAD_SYNERGY_COMPONENTS_REQUEST);

export const actions = {
  loadSynergyComponents,
};
