import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SdfButton, SdfFocusPane, SdfSpotIllustration } from '@synerg/react-components';
import { getLocaleObjFromPropKey } from '../../util/locale';
import { setCookie } from '../../util/misc';
import { constants } from '../login/login-comp-constants';
import { constants as panelConstants } from './panel-constants';
import { Footer } from '../footer';

const ClientMobilePanel = (props) => {
  const [displayModal, setDisplayModal] = useState(true);
  const {
    bodyText,
    buttonText,
    cancelButtonText,
    headlineText,
    locale,
    buttonUrl,
    redirectURL,
  } = props;
  const onClientMobileRedirect = () => {
    redirectURL(getLocaleObjFromPropKey(buttonUrl, locale), true);
    closeModal();
  };

  const closeModal = () => {
    setCookie(panelConstants.OLP_VIEW, constants.MOBILE, false, null);
    setDisplayModal(false);
  };

  const bodyTextLocale = bodyText && getLocaleObjFromPropKey(bodyText, locale);
  const buttonTextLocale = buttonText && getLocaleObjFromPropKey(buttonText, locale);
  const headlineTextLocale = headlineText && getLocaleObjFromPropKey(headlineText, locale);
  const cancelButtonTextLocale =
    cancelButtonText && getLocaleObjFromPropKey(cancelButtonText, locale);

  return (
    <SdfFocusPane
      size="lg"
      visible={ displayModal }
      closeable
      hide-accept-button
      hide-dismiss-button
      hide-footer
      onSdfDismiss={ () => closeModal() }
      id="clientMobilePanelPrompt"
    >
      <div slot="title">{ headlineTextLocale }</div>
      <div className="h-full flex flex-col justify-between">
        <div className="grid text-center p-6 mt-6 mb-16 sm:mb-0">
          <SdfSpotIllustration
            illustration-name="smart-phone"
            size="lg"
          />
          <div className="text-lg text-center mt-12 sm:mt-4">{ bodyTextLocale }</div>
          <div
            slot="custom-buttons"
            className="mt-24"
          >
            <SdfButton
              id="mobileApp"
              emphasis="primary"
              size="lg"
              onClick={ () => {
                onClientMobileRedirect();
              } }
              className="w-full max-w-xs w-auto"
            >
              { buttonTextLocale }
            </SdfButton>
            <SdfButton
              id="continueBrowser"
              emphasis="secondary"
              size="lg"
              onClick={ () => {
                closeModal();
              } }
              className="w-full max-w-xs w-auto mt-4"
            >
              { cancelButtonTextLocale }
            </SdfButton>
          </div>
        </div>
        <div className="sm:hidden">
          <Footer { ...props } />
        </div>
      </div>
    </SdfFocusPane>
  );
};

ClientMobilePanel.propTypes = {
  bodyText: PropTypes.shape(PropTypes.string.isRequired),
  buttonText: PropTypes.shape(PropTypes.string.isRequired),
  buttonUrl: PropTypes.shape(PropTypes.string.isRequired),
  headlineText: PropTypes.shape(PropTypes.string.isRequired),
  cancelButtonText: PropTypes.shape(PropTypes.string.isRequired),
  redirectURL: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

export default ClientMobilePanel;
