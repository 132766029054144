import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { types } from '..';
import { setCookie, setHtmlLang } from '../../../../util/misc';
import {
  ADP_LANG_LOCALE_COOKIE,
  ADP_LANG_LOCALE_COOKIE_EXPIRES,
} from '../../../../util/locale/constants';

const { CHANGE_LOCALE } = types;

export const setLangCookieEpic = action$ => action$.pipe(
  ofType(CHANGE_LOCALE),
  tap(
    ({ payload: { locale, shouldSetCookie } }) => shouldSetCookie &&
        setCookie(ADP_LANG_LOCALE_COOKIE, locale, false, ADP_LANG_LOCALE_COOKIE_EXPIRES),
  ),
  switchMap(({ payload: { locale } }) => {
    setHtmlLang(locale);

    return of({
      type: 'locale change',
      payload: { locale },
    });
  }),
);
