export const constants = {
  MOBILE: 'MOBILE',
  EMAIL: 'EMAIL',
  LANDLINE: 'LANDLINE',
  VOICE_BIOMETRICS: 'VOICE_BIOMETRICS',
  MOBILE_COLLECTION: 'MOBILE_COLLECTION',
  EMAIL_COLLECTION: 'EMAIL_COLLECTION',
  LANDLINE_COLLECTION: 'LANDLINE_COLLECTION',
  VOICE_BIOMETRICS_COLLECTION: 'VOICE_BIOMETRICS_COLLECTION',
  COLLECT: 'COLLECT',
  VERIFY: 'VERIFY',
  PERIODIC_PROMPT: 'PERIODIC_PROMPT',
  NOT_CONSENT: 'NOT_CONSENT',
  CONSENT: 'CONSENT',
  PERSONAL: 'PERSONAL',
  BUSINESS: 'BUSINESS',
  ANSWER: 'answer',
  ANSWER1: 'answer1',
  ANSWER2: 'answer2',
  ANSWER3: 'answer3',
  TYPETEXT: 'text',
  TYPEPASSWORD: 'password',
  MAX_EMAIL_COUNT: 2,
  MAX_MOBILE_COUNT: 2,
  DEFAULT_COUNTRY_CODE: 'us',
  DEFAULT_COUNTRY_DAIL_CODE: '1',
  DEFAULT_COUNTRY_CODE_NUMBER: '+1',
  ICON_SIZE: '1.3em',
  ICON_SIZE_WIDTH: '2em',
  EMAIL_PREFERRED: 'PREFERRED',
  EMAIL_NOT_PREFERRED: 'NOT_PREFERRED',
  DEFAULT_TEXT_MESSAGING_TANDC_URL:
    'https://netsecure.adp.com/public/legal/TextMessagingTermsCondition.html',
};
