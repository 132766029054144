/* eslint-disable no-undef */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable id-length */
/* eslint-disable radix */
/* eslint-disable newline-after-var */
/* eslint-disable prefer-destructuring */
/* eslint-disable default-case */
/* eslint-disable callback-return */
/* eslint-disable one-var */
/* eslint-disable no-eq-null */
/* eslint-disable vars-on-top */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-var */

/**
 * @classdesc Provides controller object for FIDO operations.
 * @class
 */

import {
  loadScript,
  loadIframe,
  getMobilePlatform,
  isStringNullOrEmpty,
  getAndroidVersion,
  getIosVersion,
} from './common/common';
import { nnlConfig } from './common/config';
import { UserData, getSession } from './common/session';

const resultType = result => Promise.reject({
  resultType: result.outcome,
  resultDescription: result.errorDesc,
});

export const FidoController = function () {
  var _self = this;

  // The flag indicates that this is a Cordova App (in cordova document URL starts with FILE)
  var isCordovaApp =
    document.URL.indexOf('http://') === -1 && document.URL.indexOf('https://') === -1;

  // Fido protocol type
  var mProtocol;

  // we need to use this bound function instead of console.log, because
  // in some browsers console.log will fail and raise an exception
  // if called in context other than console

  // log = function () {}  // uncomment to disable console logging

  var platformAuthenticatorAvailable = false;

  this.FidoMode = {
    DEFAULT: 'default',
    UAF: 'uaf',
    ROAMING: 'roaming',
  };

  function setPolicyType(policyType, extras) {
    var optionsObj = { policyType };

    extras = extras || {};
    extras[AppSdk.EXTRAS_KEY_OPTIONS] = optionsObj;
    return extras;
  }

  /**
   * AppSdk object.
   */
  var appSdk;

  var mUserData = new UserData();

  /**
   * Initializes a FidoController object.
   * This includes the checking of FIDO2 support,
   * the loading of required scripts, the initializing AppSdk object,
   * the initializing OOB UI (if FIDO2 is not supported or disabled), etc.
   * @returns {Promise} A promise that will be resolved in case of success
   * and rejected in case of failure.
   */
  this.initialize = function (protocol, mode) {
    // Load independent scripts in parallel.
    const APPSDK_URL = window.env ? window.env.FIDO_NNLAPPSDK_URL : nnlConfig.nnlappsdk_url;
    const REG_ENDPOINT = window.env ? window.env.FIDO_REG_ENDPOINT : nnlConfig.reg_endpoint;
    const AUTH_ENDPOINT = window.env ? window.env.FIDO_AUTH_ENDPOINT : nnlConfig.auth_endpoint;
    return Promise.all([
      loadIframe(`${APPSDK_URL}/iframe.jsp`),
      loadScript(`${APPSDK_URL}/js/appsdk.js`),
    ]).then(() => {
      appSdk = new AppSdk();
      mProtocol = protocol;
      appSdk.mode = mode;
      appSdk.regEndpoint = REG_ENDPOINT;
      appSdk.authEndpoint = AUTH_ENDPOINT;
      appSdk.srcPage = window.location.origin + window.location.pathname;
      return appSdk.init(mProtocol);
    });
  };

  this.processOob = function (url) {
    return appSdk
      .processOob(url)
      .then((result) => {
        mUserData.addRegistration(
          result.userName,
          result.fidoProtocol,
          result.additionalInfo,
          false,
          null,
        );

        return {
          result: {
            profileData: {
              userName: result.userName,
            },
          },
          resultType: result.outcome,
        };
      })
      .catch(result => resultType(result));
  };

  /**
   * Wrapper method for overriding OOB UI strings.
   *
   * @param {string} key - the given item to update.
   * @param {string} value - the value to update.
   */
  this.setOobUiDisplayString = function (key, value) {
    appSdk.setUIDisplayString(key, value);
  };

  /**
   * Initialize OOB with the given protocolType.
   *
   * @param {string} protocolType the protocolType to be set.
   */
  this.initializeOOB = function (protocolType) {
    return PluginAppSDKOOBPromised.initializeOOB(
      nnlConfig.reg_endpoint,
      nnlConfig.auth_endpoint,
      protocolType,
    );
  };

  /**
   * Sets the OOBReciever instance protocolType.
   *
   * @param {string} protocolType the protocolType to be set.
   */
  this.setOOBProtocolType = function (protocolType) {
    if (isCordovaApp) {
      PluginAppSDKOOBPromised.setProtocol(protocolType);
    }
  };

  this.isQuickAccessEnabledLocal = function () {
    var policy =
      '{"accepted":[[{"aaid":["4e4e#4090"]}],[{"aaid":["4e4e#4091"]}],[{"aaid":["4e4e#4092"]}],[{"aaid":["4e4e#4025"]}],[{"aaid":["4e4e#4026"]}]]}';

    return PluginAppSDK.checkAuthPossibleLocal(nnlConfig.fido_appid, policy);
  };

  this.isQuickAccessEnabled = function (sessionData) {
    return _self.adaptiveController.checkAuthPossible(
      sessionData,
      createExtraByMode(AuthMode.FIDO, false, true),
    );
  };

  this.getSetupState = function (sessionData) {
    return Promise.resolve('SETUP_POSSIBLE');
  };

  this.onSetup = function (sessionData, extras, isRoaming, isFido2Disabled) {
    var fidoMode = null;
    if (isRoaming) {
      fidoMode = _self.FidoMode.ROAMING;
    } else if (isFido2Disabled) {
      fidoMode = _self.FidoMode.UAF;
    }

    // Set the suggestId parameter in extras so the SDK will set the
    // "never ask" flag for user in case of successful registration.
    extras = extras || {};
    extras.suggestId = mUserData.getSessionUserName();
    extras.userName = mUserData.getSessionUserName();
    extras.userDisplayName = mUserData.getSessionUserName();

    return setup(sessionData, extras, fidoMode);
  };

  /**
   * Call this method when registering (setup) FIDO.
   * @param {object} sessionData - Session data.
   * @param {object} extras - additional data to be passed to Server.
   * @param {object} fidoMode enumeration with FIDO modes {DEFAULT, UAF, ROAMING}
   * @returns {Promise} A promise that will be resolved with response in case of success
   * and rejected with error code in case of failure.
   */
  function setup(sessionData, extras, fidoMode) {
    // Proceed with a registration method available:
    // 'fido2' - Web Authentication API (if supported by browser),
    // 'oob' - Out Of Band (QR code and push notification),
    // 'link' - Android App link or iOS Universal link (only on mobile platforms)
    var mode = getRegistrationMode();

    switch (mode) {
      case AppSdk.MODE_NATIVE:
        if (fidoMode == _self.FidoMode.UAF || (isCordovaApp && mProtocol === AppSdk.PROTOCOL_UAF)) {
          return _self.registerUaf(sessionData, extras);
        }
        return _self.registerFIDO2(sessionData, extras, fidoMode);
      case AppSdk.MODE_OOB:
        return _self.registerOOB(sessionData, extras);
      case AppSdk.MODE_LINK:
        return gMobileLink.registerLink(sessionData, extras);
    }
  }

  /**
   * Performs FIDO registration mode selection logic.
   * @param {object} sessionData - Session data.
   * @returns {string} Returns the registration mode depending on the browser and platform.
   */
  function getRegistrationMode() {
    if (_self.isFIDO2Supported() || isCordovaApp) {
      return AppSdk.MODE_NATIVE;
    }
    if (getDefaultMode() === AppSdk.MODE_LINK) {
      return AppSdk.MODE_LINK;
    }
    return AppSdk.MODE_OOB;
  }

  /**
   * Register FIDO2 authenticator
   * @param {object} sessionData - Session data.
   * @param {object} [extras] additional data to be passed to Server.
   * @param {object} fidoMode enumeration with FIDO modes {DEFAULT, UAF, ROAMING}
   * @returns {Promise} A promise that will be resolved with response in case of success
   * and rejected with error code in case of failure.
   */
  this.registerFIDO2 = function (sessionData, extras, fidoMode) {
    appSdk.mode = AppSdk.MODE_NATIVE;
    appSdk.setFIDOProtocol(AppSdk.PROTOCOL_FIDO2);
    if (fidoMode == _self.FidoMode.ROAMING) {
      // If the given fidoMode is roaming add appropriate policy type to extras.
      extras = setPolicyType('reg_roaming_only', extras);
    } else if (platformAuthenticatorAvailable) {
      // If platform authenticators available add policy type to extras.
      extras = setPolicyType('reg_platform_only', extras);
    }
    return appSdk
      .register(sessionData, extras)
      .then((result) => {
        mUserData.addRegistration(
          null,
          result.fidoProtocol,
          result.additionalInfo,
          result.push,
          sessionData.fidoAuthenticatorToken,
          sessionData.nnlConfig,
          result.passkeyEnrollmentFinishResponse.authenticatorId,
        );
        return {
          resultType: result.outcome,
          accessToken: result.accessToken,
          sessionData,
        };
      })
      .catch(result => resultType(result));
  };

  /**
   * Register UAF authenticator
   * @param {object} sessionData - Session data.
   * @param {object} [extras] additional data to be passed to Server
   * @returns {Promise} A promise that will be resolved with response in case of success
   * and rejected with error code in case of failure.
   */
  this.registerUaf = function (sessionData, extras) {
    appSdk.mode = AppSdk.MODE_NATIVE;
    appSdk.setFIDOProtocol(AppSdk.PROTOCOL_UAF);
    return appSdk
      .register(sessionData, extras)
      .then((result) => {
        mUserData.addRegistration(
          null,
          result.fidoProtocol,
          result.additionalInfo,
          result.push,
          sessionData.fidoAuthenticatorToken,
          sessionData.nnlConfig,
        );
        return {
          resultType: result.outcome,
        };
      })
      .catch(result => resultType(result));
  };

  /**
   * This function checks availability of platform authenticators
   */
  this.isPlatformAuthenticatorAvailable = function () {
    return new Promise((resolve) => {
      if (isCordovaApp) {
        resolve(true);
      } else if (
        !window.PublicKeyCredential ||
        typeof PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable !== 'function'
      ) {
        // API not available just return false.
        resolve(false);
      } else {
        PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
          .then(resolve)
          .catch(() => {
            // In case of error return false.
            resolve(false);
          });
      }
    });
  };

  /**
   * Register UAF authenticator
   * @param {object} sessionData - Session data.
   * @param {object} [extras] additional data to be passed to Server.
   * @returns {Promise} A promise that will be resolved with response in case of success
   * and rejected with error code in case of failure.
   */
  this.registerOOB = function (sessionData, extras) {
    appSdk.mode = AppSdk.MODE_OOB;
    appSdk.setFIDOProtocol(AppSdk.PROTOCOL_UAF);
    if (extras && extras.isWebOob) {
      AppSdkConfig.oob.regBaseUrl = getRegWebOobUrl();
      // If platform authenticators available add policy type to extras.
      extras = setPolicyType('reg_platform_only', extras);
    }
    const newSessionData = {
      sessionKey:
        'eyJraWQiOiJoczI1Nl9rZXkiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLm5va25va2V2YWwuY29tOjk0NDMiLCJzdWIiOiJzdGVwdXAiLCJhdWQiOiJkZWZhdWx0IiwiZXhwIjoxNjU1NDg0NzExLCJpYXQiOjE2MjM5NDg3MTF9.QiScWN4Xos924JfM7ziQJ2luvBH1SSzFQjsO7sw5rqo',
    };
    return appSdk
      .register(newSessionData, extras, null)
      .then((result) => {
        if (extras && extras.isWebOob) {
          result.additionalInfo.isWebOob = true;
        }
        mUserData.addRegistration(
          null,
          result.fidoProtocol,
          result.additionalInfo,
          result.push,
          sessionData.fidoAuthenticatorToken,
          sessionData.nnlConfig,
        );
        AppSdkConfig.oob.regBaseUrl = '';
        return {
          resultType: result.outcome,
        };
      })
      .catch((result) => {
        AppSdkConfig.oob.regBaseUrl = '';
        return Promise.reject({
          resultType: result.outcome,
          resultDescription: result.errorDesc,
        });
      });
  };

  /**
   * Register FIDO2 authenticator
   * @param {object} sessionData - Session data.
   * @param {object} [extras] additional data to be passed to Server.
   * @returns {Promise} A promise that will be resolved with response in case of success
   * and rejected with error code in case of failure.
   */
  this.onApplinkSetup = function (sessionData, extras) {
    return gMobileLink.registerLink(sessionData, extras);
  };

  /**
   * Call this method on FIDO authentication.
   * @param {object} sessionData - Session data.
   * @param {object} [extras] additional data to be passed to Server.
   * @param {boolean} native - If true - does FIDO2 authentication with username.
   * @returns {Promise} A promise that will be resolved with response in case of success
   * and rejected with error code in case of failure.
   */
  this.onPasswordlessSignIn = function (sessionData, extras, native) {
    // Proceed with the available authentication method:
    // AppSdk.MODE_NATIVE - Web Authentication API (if supported by browser),
    // AppSdk.MODE_OOB - Out Of Band (QR code and push notification),
    // AppSdk.MODE_LINK - Android App link or iOS Universal link (only on mobile platforms)
    if (native) {
      return _self.authenticateNative(sessionData, extras);
    }
    var mode = getAuthenticationMode(sessionData);

    switch (mode) {
      case AppSdk.MODE_NATIVE:
        return _self.authenticateNative(sessionData, extras);
      case AppSdk.MODE_OOB:
        return _self.authenticateOOB(sessionData, extras);
      case AppSdk.MODE_LINK:
        return gMobileLink.authenticateLink(sessionData, extras);
      default:
        return Promise.reject({
          resultType: 'FIDO_REGISTRATION_NOT_FOUND ',
          resultDescription: 'FIDO registration not found in browser local storage',
        });
    }
  };

  /**
   * Execute FIDO authentication mode selection logic.
   * @param {object} sessionData - Session data.
   * @returns {string} Returns the authentication mode depending on the availability of registrations and platform.
   */
  function getAuthenticationMode(sessionData) {
    if (sessionData && sessionData.userName) {
      var data = mUserData.getData(sessionData.userName);

      if (isFIDO2Available(data) || isCordovaApp) {
        return AppSdk.MODE_NATIVE;
      }
      if (isOOBAvailable(data)) {
        if (getDefaultMode() === AppSdk.MODE_LINK) {
          return AppSdk.MODE_LINK;
        }
        if (data.oob.pushHandle) sessionData.pushHandle = data.oob.pushHandle;
        return AppSdk.MODE_OOB;
      }
    }
    return AppSdk.MODE_DEFAULT;
  }

  /**
   * Call this method for authentication with either QR code or Applink.
   * @param {object} sessionData - Session data.
   * @param {object} [extras] additional data to be passed to Server.
   * @returns {Promise} A promise that will be resolved with response in case of success
   * and rejected with error code in case of failure.
   */
  this.onOOBSignIn = function (sessionData, extras) {
    if (getDefaultMode() === AppSdk.MODE_OOB || isCordovaApp || (extras && extras.isWebOob)) return _self.authenticateOOB(sessionData, extras);
    return gMobileLink.authenticateLink(sessionData, extras);
  };

  /**
   * Authenticate with FIDO2
   * @param {object} sessionData - Session data.
   * @param {object} [extras] additional data to be passed to Server.
   * @returns {Promise} A promise that will be resolved with response in case of success
   * and rejected with error code in case of failure.
   */
  this.authenticateNative = function (sessionData, extras) {
    appSdk.mode = AppSdk.MODE_NATIVE;
    appSdk.setFIDOProtocol(mProtocol);
    return appSdk
      .authenticate(sessionData, extras)
      .then((result) => {
        // Update existing or update missing registration information after successful authentication.
        mUserData.addRegistration(
          result.userName,
          result.fidoProtocol,
          result.additionalInfo,
          result.push,
          sessionData.fidoAuthenticatorToken,
          sessionData.nnlConfig,
        );

        // Convert to appropriate structure
        return {
          result: {
            passkeyAuthenticationFinishResponse: {
              authenticatorId: result.passkeyAuthenticationFinishResponse.authenticatorId,
              accountId: result.passkeyAuthenticationFinishResponse.accountId,
            },
            sessionData: result.sessionData,
            profileData: {
              userName: result.userName,
            },
          },
          resultType: result.outcome,
          accessToken: result.accessToken,
          redirectUrl: result.redirectUrl,
          givenName: result.givenName,
          sessionData,
          challenge: result.challenge,
          session: result.session,
        };
      })
      .catch(result => resultType(result));
  };

  /**
   * Authenticate with OOB UAF
   * @param {object} sessionData - Session data.
   * @param {object} [extras] additional data to be passed to Server.
   * @returns {Promise} A promise that will be resolved with response in case of success
   * and rejected with error code in case of failure.
   */
  this.authenticateOOB = function (sessionData, extras) {
    if (sessionData && !(extras && extras.isWebOob)) {
      var data = mUserData.getData(sessionData.userName);

      if (data && data.oob && data.oob.appLess) {
        extras = {};
        extras.isWebOob = true;
        delete sessionData.pushHandle;
      } else if (data && data.oob && data.oob.pushHandle) {
        sessionData.pushHandle = data.oob.pushHandle;
      }
    }
    if (extras && extras.isWebOob) {
      AppSdkConfig.oob.authBaseUrl = getAuthWebOobUrl();
    }
    appSdk.mode = AppSdk.MODE_OOB;
    appSdk.setFIDOProtocol(AppSdk.PROTOCOL_UAF);
    return appSdk
      .authenticate(sessionData, extras, null)
      .then((result) => {
        if (extras && extras.isWebOob) {
          result.additionalInfo.isWebOob = true;
        }
        // Update existing or update missing registration information after successful authentication.
        mUserData.addRegistration(
          result.userName,
          result.fidoProtocol,
          result.additionalInfo,
          result.push,
          null,
        );
        AppSdkConfig.oob.authBaseUrl = '';
        // Convert to appropriate structure
        return {
          result: {
            sessionData: result.sessionData,
            profileData: {
              userName: result.userName,
            },
          },
          resultType: result.outcome,
        };
      })
      .catch((result) => {
        AppSdkConfig.oob.authBaseUrl = '';
        return Promise.reject({
          resultType: result.outcome,
          resultDescription: result.errorDesc,
        });
      });
  };

  /**
   * Call this method on FIDO transaction confirmation.
   * @param {object} sessionData - Session data.
   * @param {object} transactionText Transaction text.
   * @returns {Promise} A promise that will be resolved with response in case of success
   * and rejected with error code in case of failure.
   */
  this.onTransaction = function (sessionData, transactionText) {
    // Proceed with the available authentication method:
    // 'fido2' - Web Authentication API (if supported by browser),
    // 'oob' - Out Of Band (QR code and push notification),
    // 'link' - Android App link or iOS Universal link (only on mobile platforms)
    var method = getTransactionMode(sessionData);

    switch (method) {
      case AppSdk.MODE_NATIVE:
        return _self.transactionNative(sessionData, transactionText);
      case AppSdk.MODE_OOB:
        return _self.transactionOOB(sessionData, transactionText);
      case AppSdk.MODE_LINK:
        return gMobileLink.transactionLink(sessionData, transactionText);
      default:
        return Promise.reject({
          resultType: 'FIDO_REGISTRATION_NOT_FOUND ',
          resultDescription: 'FIDO registration not found in browser local storage',
        });
    }
  };

  /**
   * Execute FIDO transaction mode selection logic.
   * @param {object} sessionData - Session data.
   * @returns {string} Returns the authentication mode depending on the availability of registrations and platform.
   */
  function getTransactionMode(sessionData) {
    var data = mUserData.getData();

    if (isFIDO2Available(data) || isCordovaApp) {
      return AppSdk.MODE_NATIVE;
    }
    if (isOOBAvailable(data)) {
      if (getDefaultMode() === AppSdk.MODE_LINK) {
        return AppSdk.MODE_LINK;
      }
      return AppSdk.MODE_OOB;
    }
    return AppSdk.MODE_DEFAULT;
  }

  /**
   * Returns FIDO authenticators registered previously for a user for a given protocol
   * by adding them into the given registrations array. The registrations array can be empty
   * or may have registrations from previous listReg call for different protocol.
   * @param {object} sessionData Session data.
   * @param {object} [extras] additional data to be passed to Server.
   * @param protocol protocol type "uaf" or "fido2"
   * @param registrations Array of registered list
   * @returns
   */
  function listReg(sessionData, extras, protocol, registrations) {
    try {
      // setFIDOProtocol can throw exception (e.g. if protocol is AppSdk.PROTOCOL_FIDO2
      // and browser doesn't support fido2)
      appSdk.setFIDOProtocol(protocol);
    } catch (exception) {
      // If browser doesn't support fido2, do not make a call to get FIDO2 Registrations
      if (exception instanceof AppSdkException && Outcome.NOT_INSTALLED === exception.outcome) {
        return registrations;
      }

      // Rethrow all unexpected exceptions
      throw exception;
    }

    return appSdk
      .getRegistrations(sessionData, extras)
      .then((result) => {
        // Convert to appropriate structure
        $(result.registrations).each(function () {
          var device = this.device;

          $(this.authenticators).each(function () {
            registrations.push({
              info: device.info,
              device: device.id,
              authenticatorName: this.authenticatorName,
              push: device.push,
              description: this.description,
              handle: this.handle,
              protocol,
              lastUsedTimeStamp: this.lastUsedTimeStamp,
            });
          });
        });

        return registrations;
      })
      .catch((result) => {
        // If user has no registrations on the server, it is not considered an error.
        // Thus instead of rejecting the Promise, it returns the registrations list as is.
        if (result.outcome === 'SERVER_USER_NOT_FOUND') {
          return registrations;
        }

        return Promise.reject(result);
      });
  }

  /**
   * Call this method to get FIDO authenticators registered previously for a user.
   * @param {object} sessionData Session data.
   * @param {object} [extras] additional data to be passed to Server.
   * @returns {Promise} A promise that will be resolved with response in case of success
   * and rejected with error code in case of failure.
   */
  this.getRegistrations = function (sessionData, extras) {
    var regs;

    return listReg(sessionData, extras, AppSdk.PROTOCOL_UAF, [])
      .then(registrations => listReg(sessionData, extras, AppSdk.PROTOCOL_FIDO2, registrations))
      .then((registrations) => {
        regs = registrations;
        // Update registration data.
        return mUserData.updateRegistrations(null, registrations);
      })
      .then(() => ({
        result: {
          registrations: regs,
        },
        resultType: 'SUCCESS',
      }));
  };

  /**
   * Call this method to delete registered authenticator for a given user from the Server.
   * @param {object} sessionData Session data.
   * @param {string} regId Uniquely identifies a registration for the user.
   * @param {string} protocol FIDO protocol.
   * @returns {Promise} A promise that will be resolved with response in case of success
   * and rejected with error code in case of failure.
   */
  this.onRemove = function (sessionData, regId, protocol) {
    appSdk.setFIDOProtocol(protocol);
    return appSdk.deregister(sessionData, regId, null);
  };

  /**
   * Call this method to rename registered authenticator for a given user.
   * @param {object} sessionData Session data.
   * @param {string} regId Uniquely identifies a registration for the user.
   * @param {string} name New name for authenticator.
   * @returns {Promise} A promise that will be resolved with response in case of success
   * and rejected with error code in case of failure.
   */
  this.onRename = function (sessionData, regId, name) {
    return appSdk.updateRegistration(sessionData, regId, { authenticatorName: name }, null);
  };

  /**
   * Call this method to delete all registered authenticators for a given user from the Server.
   * @param {object} sessionData Session data.
   * @returns {Promise} A promise that will be resolved with response in case of success
   * and rejected with error code in case of failure.
   */
  this.onRemoveAll = function (sessionData) {
    appSdk.setFIDOProtocol(AppSdk.PROTOCOL_UAF);
    return appSdk
      .deregister(sessionData, null)
      .then(() => {
        appSdk.setFIDOProtocol(AppSdk.PROTOCOL_FIDO2);
        return appSdk.deregister(sessionData, null);
      })
      .catch((outcome) => {
        // return !logoutOnInvalidSession(outcome);
      });
  };

  this.isScannerEnabled = function () {
    return Promise.reject();
  };

  this.onScanner = function (frame) {
    if (!isCordovaApp) {
      return Promise.reject();
    }
    return PluginAppSDKOOBPromised.createScannerFragment(frame);
  };

  this.getDeviceId = function () {
    return appSdk
      .getDeviceId()
      .then(result => ({
        result: {
          deviceID: result.deviceID,
        },
        resultType: result.outcome,
      }))
      .catch(result => Promise.reject({
        resultType: result.outcome,
      }));
  };

  var waitPromise, waitHandle;

  /**
   * Get a QR code for device activation.
   * @param {object} sessionData Session data containing user name.
   * @returns {Promise} A promise that will be resolved with response in case of success
   * and rejected in case of failure.
   */
  this.getQRCodeForDeviceActivation = function (sessionData) {
    appSdk.setFIDOProtocol(AppSdk.PROTOCOL_UAF);
    appSdk.mode = AppSdk.MODE_OOB;
    var extras = setPolicyType('reg_platform_only', null);
    return new Promise((resolve, reject) => {
      waitPromise = appSdk
        .register(sessionData, extras, null, (result) => {
          waitHandle = result.initHandle;
          if (result.state.qr) {
            resolve({
              result: {
                qrImage: `data:image/png;base64,${result.state.qr}`,
              },
            });
          }
        })
        .catch((result) => {
          reject(result);
        });
    });
  };

  /**
   * Wait for started device activation completion.
   * @param {object} sessionData Session data containing user name.
   * @returns {Promise} A promise that will resolve immediately after operation is completed.
   */
  this.waitForDeviceActivation = function (sessionData) {
    return waitPromise.then((result) => {
      mUserData.addRegistration(
        null,
        result.fidoProtocol,
        result.additionalInfo,
        result.push,
        null,
      );

      return result;
    });
  };

  /**
   * Cancel already started device activation operation.
   * @param {object} sessionData Session data containing user name.
   * @returns {Promise} A promise that will resolve immediately after operation is canceled.
   */
  this.cancelDeviceActivation = function (sessionData) {
    return appSdk.cancel(waitHandle);
  };

  this.setAppSdkMode = function (mode) {
    appSdk.mode = mode;
  };

  this.getAppSdkMode = function (mode) {
    return appSdk.mode;
  };

  function getDefaultMode() {
    var p = getMobilePlatform();

    if ((p === 'ios' || p === 'android') && !isCordovaApp) return AppSdk.MODE_LINK;
    return AppSdk.MODE_OOB;
  }

  function isAppLinkSupported() {
    /**
     * gets two device versions
     * keeps only first two numbers (e.g. 10.0.1 => 10.0) of versions and compares them
     * @param {string} version1
     * @param {string} version2
     */
    function compareDeviceVersions(version1, version2) {
      var digits = [version1.split('.'), version2.split('.')];
      for (var i = 0; i < 2; ++i) {
        digits[i][0] = parseInt(digits[i][0]);
        digits[i][1] = digits[i][1] != null ? parseInt(digits[i][1]) : 0;
      }
      return (
        digits[0][0] > digits[1][0] ||
        (digits[0][0] == digits[1][0] && digits[0][1] >= digits[1][1])
      );
    }
    var p = getMobilePlatform();

    if (p === 'android') {
      return compareDeviceVersions(getAndroidVersion(), '6.0');
    }
    if (p === 'ios') {
      return compareDeviceVersions(getIosVersion(), '9.2');
    }
    return false;
  }

  this.setSecondFactorFlag = function (flag) {
    // Convert flag to Boolean and store into user data
    mUserData.setFlag(null, 'second_factor_enabled', Boolean(flag));
  };

  this.isSecondFactorEnabled = function (userName) {
    var userData = mUserData.getData(userName);

    return userData && userData.second_factor_enabled;
  };

  this.suggestRegister = function (sessionData) {
    var extras = {
      suggestId: mUserData.getSessionUserName(),
    };

    setPolicyType('reg_platform_only', extras);

    return appSdk
      .suggestRegister(sessionData, extras)
      .then(result => ({
        resultType: result.outcome,
      }))
      .catch(result => Promise.reject({
        resultType: result.outcome,
        resultDescription: result.errorDesc,
        suggestionStatus: result.suggestionStatus,
      }));
  };

  this.resetSuggestRegister = function () {
    appSdk.resetSuggestRegister(mUserData.getSessionUserName());
  };

  this.set3dsData = function (data) {
    mUserData.setFlag(null, 'emv_3ds_data', data);
  };

  this.get3dsData = function () {
    var userData = mUserData.getData();

    return userData && userData.emv_3ds_data;
  };

  this.isFidoRegsAvailable = function (sessionData) {
    if (sessionData && sessionData.userName) {
      var data = mUserData.getData(sessionData.userName);

      return isFIDO2Available(data) || isOOBAvailable(data);
    }
    return false;
  };

  this.isFIDO2Supported = function () {
    // if PublicKeyCredential is defined then FIDO2 is supported.
    return !!window.PublicKeyCredential;
  };

  this.fetchUserData = function (sessionData) {
    return appSdk
      .fetchUserData(sessionData)
      .then(result => ({
        result,
        resultType: 'SUCCESS',
      }))
      .catch(result => ({
        resultType: result.outcome,
      }));
  };

  this.purgeUserData = function (sessionData) {
    appSdk.resetSuggestRegister(mUserData.getSessionUserName());

    return appSdk
      .purgeUserData(sessionData)
      .then(result => ({
        result,
        resultType: 'SUCCESS',
      }))
      .catch(result => ({
        resultType: result.outcome,
      }));
  };

  this.useAppLink = function (fidoController) {
    return (
      APP_NAME === 'bankauth' &&
      isMobileBrowser() &&
      !_self.isFIDO2Supported() &&
      isAppLinkSupported()
    );
  };
};
function getAdaptiveAuthMode(userName) {
  if (!_self.isFIDO2Supported() || getSession().fido2Disabled) {
    return AuthMode.FIDO;
  }
  var data = mUserData.getData(userName);

  if (isFIDO2Available(data) || !isOOBAvailable(data)) {
    return AuthMode.FIDO;
  }
  return AuthMode.OOB;
}

var AuthMode = {
  FIDO: 'fido',
  OOB: 'oob',
  RECOVERY: 'recovery',
};

function getRegWebOobUrl() {
  if (nnlConfig.reg_web_oob_url) {
    return `${nnlConfig.reg_web_oob_url}/?weboob=true`;
  }
  return `${nnlConfig.host}/signin/v1/fido_verify_page/?weboob=true`;
}
function getAuthWebOobUrl() {
  if (nnlConfig.auth_web_oob_url) {
    return `${nnlConfig.auth_web_oob_url}/?weboob=true`;
  }
  return `${nnlConfig.host}/signin/v1/fido_signin_consent/?weboob=true`;
}

function createExtraByMode(mode, secondFactor, isQuickAccess, isFido2Disabled) {
  return {
    contextData: {
      mode,
      second_factor: secondFactor || false,
      quick: isQuickAccess || false,
      disable_fido2: isFido2Disabled || false,
    },
  };
}

function isFIDO2Available(userData) {
  return (
    userData != null &&
    userData.fido2 != null &&
    userData.fido2.localCredentials != null &&
    userData.fido2.localCredentials.length > 0
  );
}

function isOOBAvailable(userData) {
  return userData != null && userData.oob != null && userData.oob.available;
}

function isAppLessAvailable(userData) {
  return userData != null && userData.oob != null && userData.oob.appLess;
}

function isMobileBrowser() {
  var p = getMobilePlatform();

  return !window.cordova && (p === 'ios' || p === 'android');
}

function setTransactionText(transText) {
  var optionsObj = { transactionText: transText };
  var extras = {};

  extras[AppSdk.EXTRAS_KEY_OPTIONS] = optionsObj;
  return extras;
}

function isFIDO2Canceled(result) {
  return (
    result.exception != null &&
    !isStringNullOrEmpty(result.exception.message) &&
    (result.exception.message.includes('either timed out or was not allowed') ||
      result.exception.message.includes('request has been cancelled'))
  );
}
