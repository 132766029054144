import { handleActions } from 'redux-actions';
import { types } from './sending-request-actions';

const { SENDING_REQUEST } = types;

export const initialState = {
  currentlySending: false,
};

export default handleActions(
  {
    [SENDING_REQUEST]: (state, { payload }) => ({ ...state, currentlySending: payload }),
  },
  initialState,
);
