export const constants = {
  VERIFY_USERID_ENDPOINT: '/api/sign-in-service/v1/sign-in.account.identify',
  AUTHENTICATE_ENDPOINT: '/api/sign-in-service/v1/sign-in.challenge.respond',
  CHALLENGE_TYPE_NOT_DEFINED: 'NONE',
  PASSWORD_EXPIRED: 'EXPIRED',
  PASSWORD_ABOUT_TO_EXPIRE: 'ABOUT_TO_EXPIRE',
  RESET: 'RESET',
  ADP_SMSESSION_COOKIE: 'SMSESSION',
  COOKIE_REMEMBER_ME: 'adp_user_id',
  DYNATRACE_COOKIE: 'MTRUID',
  COOKIE_GREET_FNAME: 'adp_user_fname',
  ALP_COOKIE_REMEMBER_ME: 'adp_alp_userid',
  EMAIL_VERIFICATION_RESPONSE: 'EMAIL_VERIFICATION_RESPONSE',
  MOBILE_VERIFICATION_RESPONSE: 'MOBILE_VERIFICATION_RESPONSE',
  TYPE_SIGNED_IN: 'SIGNED_IN',
  FIDO2ELIGIBLE: 'FIDO2_ELIGIBLE',
  FIDO_CHALLENGE_RESPONSE: 'FIDO_CHALLENGE_RESPONSE',
  PASSKEY_REMIND_ME_RESPONSE: 'PASSKEY_REMIND_ME_RESPONSE',
  CHALLENGE_TYPE_PASSKEY: 'PASSKEY_AUTHENTICATOR_CHALLENGE',
  ERROR_COUNT: window !== undefined && window.env !== undefined ? window.env.ERROR_COUNT : 4,
};
