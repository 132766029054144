/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable spaced-comment */
/* eslint-disable no-eq-null */
/* eslint-disable default-case */
/* eslint-disable no-tabs */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable id-length */
/* eslint-disable no-param-reassign */
/* eslint-disable  no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
import { loadScript, getMobilePlatform } from './common/common';
import {
  SessionManager, getSession, saveSession, saveUserSession,
} from './common/session';
import { FidoController } from './fido_controller';
import { nnlConfig } from './common/config';
import { getAjaxJsonHeader, displayFidoRegMac } from '../../util/misc';

export const Controller = function () {
  const _self = this;

  // The flag indicates that this is a Cordova App (in cordova document URL
  // starts with FILE)
  const isCordovaApp =
    document.URL.indexOf('http://') === -1 && document.URL.indexOf('https://') === -1;

  // we need to use this bound function instead of console.log, because
  // in some browsers console.log will fail and raise an exception
  // if called in context other than console
  let mFidoController = null;

  // eslint-disable-next-line prefer-const
  let mAdaptiveController = null;

  let mSession = null;

  let mSessionManager;

  const adaptiveAuthType = 'adaptive-auth';

  const adaptiveTransType = 'adaptive-trans';

  const gMobileLink = null;

  this.initialize = function (protocol, mode) {
    // Load session before any initialization.
    loadSession();
    mSessionManager = new SessionManager(_self);
    mFidoController = new FidoController();
    mFidoController.isPlatformAuthenticatorAvailable().then((available) => {
      //Load NNL only on platform eligible browsers.
      if (available && displayFidoRegMac()) {
        return mFidoController.initialize(protocol, mode);
      }
    });
  };

  // Loads the session from localStorage or creates empty session.
  function loadSession() {
    mSession = getSession();
    if (!mSession) {
      mSession = {
        user: '',
        tab: '',
        sessionData: {},
      };
    }
  }

  _self.upgradeSessionData = function (sessionData) {
    if (sessionData) {
      mSession.sessionData = sessionData;
    }
    saveSession(mSession);
  };

  function onLogoutClicked() {
    // In federation case redirect to federation server logout endpoint
    if (nnlConfig.federation_enabled) {
      _self.onFederationSignOut();
    } else {
      // Session manager will call closeSession and renderLoginView.
      mSessionManager.stop();
      _self.renderLoginView();
    }
  }

  _self.onSessionTimeout = function () {
    delete mSession.sessionData;
    delete mSession.tab;
    saveSession(mSession);
  };

  // eslint-disable-next-line no-shadow
  this.register = function (userName, sessionData, serviceUrl, adpAccountId) {
    const sessionKey =
      'eyJraWQiOiJoczI1Nl9rZXkiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLm5va25va2V2YWwuY29tOjk0NDMiLCJzdWIiOiJzdGVwdXAiLCJhdWQiOiJkZWZhdWx0IiwiZXhwIjoxNjU3OTI5NTcwLCJpYXQiOjE2MjYzOTM1NzB9.m1wGrAaGpPfQlxIOfLx1YXCVOCSSfUYyQgoJePq4q8U';
    const headers = getAjaxJsonHeader();
    const newSessionData = {
      sessionKey,
      signInSession: sessionData,
      headers,
      serviceUrl,
      nnlConfig,
      adpAccountId,
    };

    saveUserSession(userName, sessionKey);
    return mFidoController
      .onSetup(newSessionData, null, false)
      .then(onRegisterSuccess)
      .catch(onRegistrError);
  };

  this.registerWebOob = function () {
    const extras = {
      isWebOob: true,
    };

    return mFidoController
      .registerOOB(mSession.sessionData, extras)
      .then(onRegisterSuccess)
      .catch(onRegistrError);
  };

  _self.registerApplink = function () {
    mFidoController
      .onApplinkSetup(mSession.sessionData)
      .then(onRegisterSuccess)
      .catch(onRegistrError)
      .then(console.log('hiding loader'));
  };

  function onRegisterSuccess(result) {
    return result;
  }

  function onRegistrError(result) {
    if (result.resultType === 'INVALID_SESSION') {
      //_self.renderTab('#logout-tab');
    } else {
      if (result.resultType === 'NO_MATCH') {
        // Some versions of Chrome browser sends wrong error when we cancel the WebAuthn authentication.
        // We find out the cancellation based on the result's description.
        if (
          result.resultDescription &&
          (!result.resultDescription.includes('timed out') ||
            !result.resultDescription.includes('not allowed'))
        ) {
          console.log(`No more registrations available: ${result.resultDescription}`);
        }
      } else if (result.resultType !== 'CANCELED') {
        console.log(`Setup failed (Error: ${result.resultType}). ${result.resultDescription}`);
      }
      if (isMobileBrowser()) {
        gMobileLink.startRegister(mSession.sessionData);
      }
    }
  }

  function onAuthentication(result) {
    // If Tutorial Web App acts as a Single Sign-On (SSO) page, there is no need to save the session.
    // We will just redirect to the AM server with JWT (if any). Then after successful JWT validation,
    // the AM server will redirect browser back to SP.
    if (nnlConfig.sso_enabled) {
      amAuthorize(result.result.sessionData);
      return;
    }
    mSession.user = result.result.profileData.userName;
    // Update displayed user name after login

    if (result.result.sessionData) {
      mSession.sessionData = result.result.sessionData;

      // If session expiration time is provided, start session timer for auto logout.
      if (result.result.sessionData.exp) {
        mSessionManager.start(result.result.sessionData.exp);
      }
    }

    saveSession(mSession);
    return result;
  }

  // Redirect to the AM server with the token (sessionData.sessionKey) to login at AM server.
  // The NNL plugin at AM server validates the token and AM server creates a session.
  function amAuthorize(sessionData) {
    const date = new Date();

    // The cookie should be short living (e.g. 1 minute)
    date.setTime(date.getTime() + 60 * 1000);

    const returnUri = window.sessionStorage.getItem('returnUri');

    window.sessionStorage.removeItem('returnUri');

    // If the session is in the payload, set the cookie for the token so NNL plugin at AM server can consume it.
    // Note that even if session is not in the payload, we STILL need redirect to AM server
    // (it is assumed that the session and token cookie can be set from the backend).
    if (sessionData && sessionData.sessionKey) {
      window.document.cookie = `Authorization=Bearer ${
        sessionData.sessionKey
      }; expires=${date.toGMTString()}; path=/; secure=true;`;
    }

    // Redirect browser back to AM server
    window.location.href = returnUri;
  }

  function onAuthenticationError(result) {
    if (result.resultType === 'INVALID_SESSION') {
      _self.renderTab('#logout-tab');
    } else {
      if (
        result.resultType === 'SERVER_REG_NOT_FOUND' ||
        result.resultType === 'SERVER_REGISTRATION_NOT_FOUND' ||
        result.resultType === 'NO_MATCH'
      ) {
        console.log(`Passwordless Sign In Not Setup: ${result.resultDescription}`);
      } else if (result.resultType !== 'CANCELED') {
        console.log(
          `Passwordless Sign In failed (Error: ${result.resultType}). ${result.resultDescription}`,
        );
      }
      if (isMobileBrowser()) {
        gMobileLink.startAuthenticate();
      }
    }
    return result;
  }

  function signInWithOOB() {
    mFidoController
      .onOOBSignIn()
      .then(onAuthentication)
      .catch(onAuthenticationError)
      .then(console.log('hiding loader'));
  }

  this.signInWithWebOOB = function () {
    const extras = {
      isWebOob: true,
    };

    const userName = $('#username').val();

    return mFidoController
      .onOOBSignIn({ userName }, extras)
      .then(onAuthentication)
      .catch(onAuthenticationError)
      .then(console.log('hiding loader'));
  };

  this.signInWithFIDO2 = function (
    userName,
    signInSession,
    devicePrint,
    requestType,
    serviceUrl,
    riskSessionId,
    accountId,
  ) {
    const headers = { ...getAjaxJsonHeader() };

    return mFidoController
      .onPasswordlessSignIn(
        {
          userName,
          signInSession,
          headers,
          adpDeviceFingerprint: devicePrint,
          requestType,
          serviceUrl,
          riskSessionId,
          accountId,
        },
        null,
      )
      .then(onAuthentication)
      .catch(onAuthenticationError)
      .then(console.log('hiding loader'));
  };

  function processWebOOB() {
    if (window.location.href.indexOf('weboob=true') !== -1) {
      mFidoController.isPlatformAuthenticatorAvailable().then((available) => {
        if (available) {
          // iOS requires a user click to allow TouchID/FaceID to be used with WebAuthn.
          if (getMobilePlatform() === 'ios') {
            $('#weboob-confirmation').modal('show');
            $('#weboob-confirmation .btn-submit').one('click', () => {
              $('#weboob-confirmation').modal('hide');
              completeWebOOB();
            });
          } else {
            completeWebOOB();
          }
        } else {
          // Remove "weboob=true" flag after a timeout so user can read the message.
          setTimeout(() => {
            window.location.href = window.location.origin + window.location.pathname;
          }, 3000);
        }
      });
    }
  }

  this.completeWebOOB = function () {
    mFidoController
      .processOob(window.location.href)
      .then((result) => {
        if (!mSession.user) {
          // Save user name into the session so it will be pre-filled on login page.
          mSession.user = result.result.profileData.userName;
          saveSession(mSession);
        }
      })
      .catch((result) => {
        console.log('hiding loader')();
        console.log(`Operation failed: ${result.resultType}`);
        // In case of error redirect after timeout to allow user see the message.
        return new Promise((resolve) => {
          setTimeout(resolve, 3000);
        });
      })
      .then(() => {
        window.location.href = window.location.origin + window.location.pathname;
      });
  };
  _self.checkPossible = function (type) {
    if (type === adaptiveAuthType) {
      mAdaptiveController.checkAuthPossible().then(console.log('hiding loader'));
    } else if (type === adaptiveTransType) {
      mAdaptiveController
        .checkTransPossible()
        .then(() => {
          console.log('adaptive transaction is possible');
        })
        .catch(() => {
          console.log('adaptive transaction is impossible');
        })
        .then(console.log('hiding loader'));
    }
  };

  _self.onFederationSignIn = function () {
    window.location.href = nnlConfig.fed_login_url;
  };

  _self.onFederationSignOut = function () {
    window.location.href = nnlConfig.fed_logout_url;
  };

  function updateFedUI() {
    // Update Session and remove cookies in case of Federation Login Enabled.
    const userName = _self.getCookie('nnl_oidc_user');

    if (userName !== null) {
      _self.removeCookie('nnl_oidc_user');
      mSession.user = userName;
      $('#user-id').text(mSession.user);
      mSession.tab = '#settings-tab';
    }

    const sessionKey = _self.getCookie('nnl_oidc_token');

    if (sessionKey !== null) {
      _self.removeCookie('nnl_oidc_token');
      mSession.sessionData.sessionKey = sessionKey;
    }

    saveSession(mSession);
  }

  _self.getCookie = function (cname) {
    const name = `${cname}=`;

    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return decodeURIComponent(c.substring(name.length, c.length));
      }
    }
    return null;
  };

  _self.removeCookie = function (cname) {
    document.cookie = `${cname}=; expires=${new Date().toGMTString()};`;
  };

  //
  function updateTransactionType() {
    const transType = $('#transaction-type').val();

    if (transType === 'adaptive-transact') {
      $('#checkLinkForTrans').css('display', 'block');
    } else {
      $('#checkLinkForTrans').css('display', 'none');
    }
  }

  function getTransactionText(amount) {
    return `Authorize $${amount} payment?`;
  }

  _self.onSelectSignin = function (selectedIndex) {
    // index = 0 is passwordless; 1 is 2nd factor
    mFidoController.setSecondFactorFlag(selectedIndex);
  };

  _self.limitDigits = function (el, e) {
    if (el.value.length === 5 && e.keyCode !== 8) {
      e.preventDefault();
      return false;
    }
  };

  _self.parseValue = function (el) {
    el.value = parseFloat(el.value).toFixed(2);
  };

  _self.restrictKeys = function (event) {
    const keyCode = event.originalEvent.data.charCodeAt(0);

    if (keyCode < 46 || keyCode > 57 || keyCode === 47) {
      event.preventDefault();
      return false;
    }
  };

  _self.isCheckBoxChecked = function () {
    const checkBox = document.getElementById('enable-quick-balance');

    return checkBox.checked;
  };

  function isMobile() {
    const p = getMobilePlatform();

    return p === 'ios' || p === 'android';
  }
  /**
   * Performs adaptive authentication.
   */
  function signInWithAdaptiveAuth() {
    const userName = $('#username').val();

    const sessionData = {};

    if (userName) {
      sessionData.userName = userName;
    }

    mAdaptiveController
      .authenticate(sessionData)
      .then((response) => {
        // Call onAuthentication with appropriate data structure
        // to save session and load corresponding page
        onAuthentication({
          result: {
            sessionData: response.sessionData,
            profileData: {
              userName: response.userName,
            },
          },
        });
      })
      .catch((result) => {
        showError(result);
        if (userName) {
          showPasswordSignIn();
        }
      });
    return false;
  }

  /**
   * Fetches user data for the specified user.
   */
  _self.fetchUserData = function () {
    mFidoController
      .fetchUserData(mSession.sessionData)
      .then((result) => {
        console.log(`Fetch user Data: ${result.resultType}`);
        if (result.result) {
          downloadBlob('MyUserData.txt', JSON.stringify(result.result));
        }
      })
      .catch((result) => {
        console.log(`Fetch user data failed (Error: ${result.resultType}). `);
      })
      .then(console.log('hiding loader'));
  };

  /**
   * Deletes user sensitive data.
   */
  _self.purgeUserData = function () {
    mFidoController
      .purgeUserData(mSession.sessionData)
      .then((result) => {
        log(result);

        _self.renderSettingsView();
      })
      .then(console.log('hiding loader'));
  };

  function initCordova() {
    return new Promise((resolve) => {
      if (!isCordovaApp) {
        // In the Web App just resolve the Promise
        resolve();
        return;
      }

      // Load cordova and resolve a Promise when it is ready
      loadScript('cordova.js').then(() => {
        document.addEventListener('deviceready', () => {
          resolve();
        });
      });
    });
  }

  function isMobileBrowser() {
    return !window.cordova && isMobile();
  }

  function downloadBlob(filename, text) {
    const element = document.createElement('a');

    element.setAttribute('href', URL.createObjectURL(new Blob([text], { type: 'text/plain' })));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  const onhashchange = function () {
    processWebOOB();
  };

  window.addEventListener('hashchange', onhashchange, false);

  function showError(result) {
    if (result.outcome !== Outcome.CANCELED) {
      let message = `Operation failed with outcome: ${result.outcome}`;

      if (result.method && (result.method.errorCode || result.method.state)) {
        message += ` [${result.method.errorCode ? result.method.errorCode : result.method.state}]`;
      }
    }
  }
};
