import { actions as brandActions, types as brandActionTypes } from '../brand';
import { types as signInActionTypes } from '../signin';
import { types as synergyActionTypes, actions as synergyActions } from '../synergy';
import { actions as csrfActions } from './initial-load-actions';

const { loadBrand } = brandActions;
const { loadCSRF } = csrfActions;
const { loadSynergyComponents } = synergyActions;
const { LOAD_SYNERGY_COMPONENTS_SUCCESS } = synergyActionTypes;
const { LOAD_BRAND_SUCCESS } = brandActionTypes;
const { LOAD_ACCOUNTTYPE_SUCCESS } = signInActionTypes;
const initialLoadRequestActions = [loadSynergyComponents, loadBrand, loadCSRF];
const initialLoadSuccessActionTypes = [
  LOAD_SYNERGY_COMPONENTS_SUCCESS,
  LOAD_BRAND_SUCCESS,
  LOAD_ACCOUNTTYPE_SUCCESS,
];

export const constants = {
  INITIAL_LOAD_REQUEST_ACTIONS: initialLoadRequestActions,
  INITIAL_LOAD_SUCCESS_ACTION_TYPES: initialLoadSuccessActionTypes,
  SIGNIN_CSRF: '/csrf',
};
