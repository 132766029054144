import React from 'react';
import PropTypes from 'prop-types';
import { SdfButton, SdfFocusPane } from '@synerg/react-components';
import { getLocaleObjFromPropKey } from '../../util/locale';

class FooterPopUpModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: [],
      closeModal: true,
    };
    const { displayOrder } = props;

    this.showPopupModal = this.showPopupModal.bind(this, displayOrder);
    this.hidePopupModal = this.hidePopupModal.bind(this);
  }

  showPopupModal(index) {
    let { showModal } = this.state;

    showModal = showModal.slice();

    showModal[index] = true;
    this.setState({ showModal });
  }

  hidePopupModal() {
    this.setState({ showModal: [] });
  }

  render() {
    const {
      displayOrder, displayText, url, locale, displayAriaText,
    } = this.props;
    const { showModal, closeModal } = this.state;

    return (
      <>
        <SdfButton
          emphasis="tertiary"
          variant="text"
          className="text-md md:ml-6 mt-4 md:mt-0"
          id={ displayOrder }
          onClick={ this.showPopupModal }
          button-title={ getLocaleObjFromPropKey(displayText, locale) }
        >
          { getLocaleObjFromPropKey(displayText, locale) }
        </SdfButton>
        <SdfFocusPane
          size="lg"
          visible={ showModal[displayOrder] }
          hideFooter
          closeable={ closeModal }
          onSdfDismiss={ this.hidePopupModal }
        >
          <div
            slot="title"
            className="text-center"
          >
            { getLocaleObjFromPropKey(displayText, locale) }
          </div>
          <iframe
            className="w-full h-md"
            title={ getLocaleObjFromPropKey(displayText, locale) }
            src={ getLocaleObjFromPropKey(url, locale) }
          />
        </SdfFocusPane>
      </>
    );
  }
}

FooterPopUpModal.propTypes = {
  displayOrder: PropTypes.number,
  displayAriaText: PropTypes.string,
  displayText: PropTypes.objectOf(PropTypes.string),
  url: PropTypes.objectOf(PropTypes.string),
  locale: PropTypes.string,
};

export default FooterPopUpModal;
