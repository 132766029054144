/* eslint-disable no-nested-ternary */
import React from 'react';
import { intlShape, FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  SdfCheckbox,
  SdfPhoneNumberInput,
  SdfAlert,
  SdfLink,
  SdfIcon,
} from '@synerg/react-components';
import { constants } from './channel-collection-constants';
import { isBlank, EMEA_REGION } from '../../util/misc';
import { TextMessagingTCModal } from '.';

const { CONSENT, NOT_CONSENT, DEFAULT_COUNTRY_CODE_NUMBER } = constants;

const ChannelCollectionMobile = (props) => {
  const {
    setMobile,
    setMobileConsent,
    textMessageTcModal,
    dispalyTextMessageTermsAndCondition,
    locale,
    mobile,
    consent,
    validationErrors,
    channelCollectionChallenge,
    intl,
    mobileValid,
    mobileErrMsg,
    setMobileValid,
    setMobileErrMsg,
    handleKeyPress,
    isAppView,
  } = props;

  const EMPTY_STRING = '';

  const { displayTermsAndConditions } = channelCollectionChallenge;

  const invalidPhoneNumberErrMsg = intl.formatMessage({
    id: 'channelcollection_invalid_phone_number_label2',
    defaultMessage: 'Invalid phone number.',
  });

  const invalidCountryCodeErrMsg = intl.formatMessage({
    id: 'channelcollection_invalid_country_code_label',
    defaultMessage: 'Invalid country code.',
  });

  const isEnterKeyPressed = key => key === 'Enter';

  const handleTAndC = () => {
    dispalyTextMessageTermsAndCondition(true);
  };

  const handleTAndCClick = () => {
    handleTAndC();
  };

  const handleTAndCKeyPress = (event) => {
    if (isEnterKeyPressed(event.key)) {
      handleTAndC();
    }
  };

  const handleConsentChange = event => setMobileConsent(event.detail ? CONSENT : NOT_CONSENT);

  const handleConsentKeyPress = (event) => {
    if (isEnterKeyPressed(event.key)) {
      setMobileConsent(consent === CONSENT ? NOT_CONSENT : CONSENT);
    }
  };

  const getMobile = (mobileValues) => {
    const { countryCode, mobileNumber } = mobileValues;

    if (isBlank(countryCode) && isBlank(mobileNumber)) {
      return EMPTY_STRING;
    }
    return `+${countryCode}${mobileNumber}`;
  };

  const allowedCountryCodeList = ['US', 'CA'];

  const isCountryCodeAllowed = countryPrefix => allowedCountryCodeList.includes(countryPrefix);

  const getCountryCode = number => number && number.slice(0, 2);

  const handleMobileChange = (event) => {
    const phoneObj = event.detail;
    const {
      valueInternational, valueNational, countryPrefix, countryCode,
    } = phoneObj;

    setMobile({
      country: countryPrefix && countryPrefix.toLowerCase(),
      countryCode,
      mobileNumber: valueNational,
      communicationId: mobile && mobile.communicationId,
    });
    if (
      (EMEA_REGION === channelCollectionChallenge.version && valueInternational !== EMPTY_STRING) ||
      (isCountryCodeAllowed(countryPrefix) && valueInternational !== EMPTY_STRING)
    ) {
      setMobileValid(true);
    } else if (valueInternational === EMPTY_STRING) {
      setMobileErrMsg(invalidPhoneNumberErrMsg);
      setMobileValid(false);
    } else {
      setMobileErrMsg(invalidCountryCodeErrMsg);
      setMobileValid(false);
    }
  };

  const handleInvalidMobileChange = (event) => {
    const phoneObj = event.detail;
    const { valueInternational } = phoneObj;

    if (
      EMEA_REGION === channelCollectionChallenge.version ||
      valueInternational === EMPTY_STRING ||
      getCountryCode(valueInternational) === DEFAULT_COUNTRY_CODE_NUMBER
    ) {
      setMobileErrMsg(invalidPhoneNumberErrMsg);
      setMobileValid(false);
    } else {
      setMobileErrMsg(invalidCountryCodeErrMsg);
      setMobileValid(false);
    }
  };

  const handleMobileCountryCodeChange = (event) => {
    if (EMEA_REGION !== channelCollectionChallenge.version) {
      const countryPrefix = event.detail;

      if (!isCountryCodeAllowed(countryPrefix)) {
        setMobileErrMsg(invalidCountryCodeErrMsg);
        setMobileValid(false);
      }
    }
  };

  const isErrorPresent = () => {
    if (validationErrors && validationErrors.length > 0) {
      const invalidPhoneFormatErrMsg = intl.formatMessage({
        id: 'channelcollection_invalid_phone_number_label',
        defaultMessage: 'Invalid phone number format.',
      });

      setMobileValid(false);
      setMobileErrMsg(invalidPhoneFormatErrMsg);
      return true;
    }
    return false;
  };

  const getConsentText = () => intl.formatMessage({
    id: 'channelcollection_opt_in',
    defaultMessage: 'Opt-in to receive account updates (Optional)',
  });

  return (
    <div>
      <div className="mb-4">
        <SdfPhoneNumberInput
          id="phone-number-input"
          label={ intl.formatMessage({
            id: 'channelcollection_primary_mobile_label',
            defaultMessage: 'Mobile Phone Number',
          }) }
          placeholder=""
          className={ isAppView ? 'w-full' : 'w-full mt-2 md:mt-0' }
          requiredState="required"
          value={ getMobile(mobile) }
          onSdfChange={ handleMobileChange }
          onSdfInvalidNumber={ handleInvalidMobileChange }
          onSdfCountryCodeChange={ handleMobileCountryCodeChange }
          state={ !mobileValid || isErrorPresent() ? 'error' : 'normal' }
          onKeyPress={ handleKeyPress }
        >
          { (!mobileValid || isErrorPresent()) && mobileErrMsg !== EMPTY_STRING && (
            <SdfAlert
              id="mobile-validation-error"
              type="inline"
              status="error"
              slot="alert"
              content="after"
            >
              { mobileErrMsg }
            </SdfAlert>
          ) }
        </SdfPhoneNumberInput>
      </div>
      { displayTermsAndConditions && (
        <div className="mb-4">
          <div>
            <SdfCheckbox
              id="consented-yes"
              label={ getConsentText() }
              checked={ consent === CONSENT }
              onSdfChange={ handleConsentChange }
              onKeyPress={ handleConsentKeyPress }
            />
          </div>
          <div className="font-normal leading-default mt-2">
            { EMEA_REGION === channelCollectionChallenge.version ? (
              <FormattedMessage
                id="channelcollection_mobile_consent_mandatory"
                // eslint-disable-next-line max-len
                defaultMessage="I agree that ADP may use this phone number to send me information about my account."
              />
            ) : (
              <FormattedMessage
                id="channelcollection_mobile_consent"
                // eslint-disable-next-line max-len
                defaultMessage="By Opting-in, you give us permission to contact you using automated calls or texts, to verify your identity, enhance your ADP account security, and for updates and notifications about your ADP services. Message and data rates may apply. You can opt-out at any time."
              />
            ) }

            <SdfLink
              id="tc-id"
              onClick={ handleTAndCClick }
              onKeyPress={ handleTAndCKeyPress }
            >
              <SdfIcon
                icon="external-link"
                icon-title={ intl.formatMessage({
                  id: 'tandc_icon_title',
                  defaultMessage: 'View terms and condition',
                }) }
              />
            </SdfLink>
          </div>
        </div>
      ) }
      <TextMessagingTCModal
        id="tandc-modal"
        visible={ textMessageTcModal }
        onSdfDismiss={ () => dispalyTextMessageTermsAndCondition(false) }
        size="medium"
        locale={ locale }
        onSdfAccept={ () => dispalyTextMessageTermsAndCondition(false) }
      />
    </div>
  );
};

ChannelCollectionMobile.propTypes = {
  setMobile: PropTypes.func,
  setMobileConsent: PropTypes.func,
  textMessageTcModal: PropTypes.bool,
  dispalyTextMessageTermsAndCondition: PropTypes.func,
  validationErrors: PropTypes.instanceOf(Array),
  mobile: PropTypes.shape({
    country: PropTypes.string,
    countryCode: PropTypes.string,
    mobileNumber: PropTypes.string,
  }),
  consent: PropTypes.string,
  locale: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
  channelCollectionChallenge: PropTypes.shape({}),
  mobileValid: PropTypes.bool,
  mobileErrMsg: PropTypes.string,
  setMobileValid: PropTypes.func,
  setMobileErrMsg: PropTypes.func,
  handleKeyPress: PropTypes.func,
  isAppView: PropTypes.bool,
};

ChannelCollectionMobile.contextTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ChannelCollectionMobile);
