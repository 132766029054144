/* eslint-disable max-len */
import { of, concat } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';

import { types } from '..';
import { types as authType } from '../../login/login-actions';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { constants } from '../../login/login-constants';
import { STEP_UP_QNA, HOME } from '../../../../routes/router-url-constants';
import {
  getAjaxJsonHeader,
  getUrlLocationSearch,
  setDeviceTokenForRequiredTypes,
} from '../../../../util/misc';
import { types as questionCollectionType } from '../../question-collection/question-collection-actions';
import { getDefaultErrorMessage } from '../../../helpers/epics/error';
import { types as logintypes } from '../../login';
import { types as stepUpType } from '../step-up-actions';
import { types as channelCollectionType } from '../../channel-collection';
import { types as tcType } from '../../terms-and-conditions/terms-and-conditions-actions';
import { constants as channelCollectionConstants } from '../../../../components/channel-collection/channel-collection-constants';

const {
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_FAIL,
  VERIFY_OTP_SUCCESS,
  REQUEST_NEW_CODE_REQUEST,
  REQUEST_NEW_CODE_FAIL,
  SHOW_QNA,
  RESET_STATE_STEP_UP,
  NAVIGATE_HOME,
} = types;

const { MOBILE_COLLECTION } = channelCollectionConstants;

const { SET_FIDO_ATTRIBUTES, SHOW_FIDO_CANCEL_PAGE, FIDO_AUTH_CANCEL } = logintypes;
const { AUTHENTICATE_SUCCESS, RESET_STATE } = authType;
const { DISPLAY_CHANNELS } = stepUpType;
const { AUTHENTICATE_ENDPOINT } = constants;
const { DISPLAY_TERMS_AND_CONDITIONS } = tcType;
const { DISPLAY_QUESTION_COLLECTION } = questionCollectionType;
const { DISPLAY_MOBILE_COLLECTION } = channelCollectionType;

const resetState = createAction(RESET_STATE);
const resetStateStepUp = createAction(RESET_STATE_STEP_UP);
const verifyOTPSuccess = createAction(VERIFY_OTP_SUCCESS);
const setFidoAttributes = createAction(SET_FIDO_ATTRIBUTES);
const showFidoCancelPage = createAction(SHOW_FIDO_CANCEL_PAGE);
const fidoAuthCancel = createAction(FIDO_AUTH_CANCEL);
const authenticateSuccess = createAction(AUTHENTICATE_SUCCESS);
const verifyOTPFail = createAction(VERIFY_OTP_FAIL);
const requestNewCodeFail = createAction(REQUEST_NEW_CODE_FAIL);
const showQNAPage = createAction(SHOW_QNA);
const displayChannels = createAction(DISPLAY_CHANNELS);
const tcDisplay = createAction(DISPLAY_TERMS_AND_CONDITIONS);
const displayMobileCollection = createAction(DISPLAY_MOBILE_COLLECTION);
const displayQuestionCollection = createAction(DISPLAY_QUESTION_COLLECTION);

// eslint-disable-next-line max-len
const containsMobilePrompt = collectionType => collectionType && collectionType.some(type => type.promptName === MOBILE_COLLECTION);

const handleResponse = (response, isFido) => {
  if (response.response === undefined) {
    throw { response }; // eslint-disable
  }

  const { challenge, session, result } = response.response;

  const {
    accessToken, type, redirectUrl, user, adpAccountId, fidoPromptStatus,
  } = result;

  const { givenName } = user || '';

  setDeviceTokenForRequiredTypes(response);

  if (challenge && challenge.type === 'COMMUNICATION_COLLECTION_CHALLENGE') {
    if (containsMobilePrompt(challenge.communicationCollectionType)) {
      return displayMobileCollection({
        channelCollectionSession: session,
        channelCollectionChallenge: challenge,
      });
    }
  }

  if (accessToken && !fidoPromptStatus && isFido) {
    return authenticateSuccess({
      accessToken,
      redirectUrl,
      givenName,
    });
  }
  if (accessToken && !fidoPromptStatus && !isFido) {
    return authenticateSuccess({
      accessToken,
      redirectUrl,
      givenName,
      signedInResponseType: type,
    });
  }

  return verifyOTPSuccess({
    challenge,
    otpVerifyResponseType: type,
    session,
    adpAccountId,
  });
};

const handleNewCodeResponse = (response) => {
  const { challenge, session } = response.response;

  setDeviceTokenForRequiredTypes(response);

  if (challenge && challenge.authenticators) {
    return displayChannels({
      channelsDisplaySession: session,
      channelsDisplayChallenge: challenge,
    });
  }
  throw new Error(getDefaultErrorMessage());
};

const verifyOTPRequest = (type, otp, session) => ({
  response: {
    type,
    otp,
  },
  session,
});
const newCodeRequest = (type, session) => ({
  response: {
    type,
    changeAuthenticator: true,
  },
  session,
});

const verifyOTPService = (
  ajax,
  type,
  otp,
  session,
  fidoPromptStatus,
  accessToken,
  nextActionUrl,
  userid,
  isFido,
) => ajax({
  url: nextActionUrl || `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(verifyOTPRequest(type, otp, session)),
}).pipe(
  map(response => handleResponse(response, userid, isFido)),
  catchError((err) => {
    const errorCode = getErrorMessage(err);

    if (errorCode === 'QUESTIONNAIRE_NOT_FOUND') {
      return of(
        verifyOTPFail({
          error: errorCode,
          otpDeadEndScenario: true,
        }),
      );
    }

    if (
      (errorCode === 'ACCESS_DENIED' ||
          errorCode === 'INTERNAL SERVER ERROR' ||
          errorCode === 'APPLICATION_ERROR') &&
        accessToken &&
        fidoPromptStatus
    ) {
      return of(
        showFidoCancelPage({
          disableLinkButton: true,
        }),
      );
    }

    if (
      (errorCode === 'ACCESS_DENIED' ||
          errorCode === 'INTERNAL SERVER ERROR' ||
          errorCode === 'APPLICATION_ERROR') &&
        isFido
    ) {
      return of(
        fidoAuthCancel({
          errorCode,
        }),
      );
    }

    return of(verifyOTPFail(getErrorMessage(err)));
  }),
);
const requestNewCodeService = (ajax, type, session, nextActionUrl) => ajax({
  url: nextActionUrl || `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(newCodeRequest(type, session)),
}).pipe(
  map(response => handleNewCodeResponse(response)),
  catchError(err => of(requestNewCodeFail(getErrorMessage(err)))),
);

// prettier-ignore
export const verifyOTPEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(VERIFY_OTP_REQUEST),
  switchMap(() => {
    const {
      type, otp, sendOTPActionUrl, stepupSession,
    } = state$.value.stepup;

    const {
      fidoPromptStatus, accessToken, userid, isFido,
    } = state$.value.login;

    return sendingAsyncRequest(
      verifyOTPService(ajax, type, otp, stepupSession, fidoPromptStatus, accessToken, sendOTPActionUrl, userid, isFido),
    );
  }),
);

export const verifyOTPSuccessEpic = (action$, state$) => action$.pipe(
  ofType(VERIFY_OTP_SUCCESS),
  // eslint-disable-next-line max-len
  map(
    ({
      const: { fidoPromptStatus } = state$.value.login,
      payload: { challenge, otpVerifyResponseType, session },
    }) => handleRedirectForVerifyOTP(challenge, otpVerifyResponseType, session, fidoPromptStatus),
  ),
);

const handleRedirectForVerifyOTP = (
  challenge,
  otpVerifyResponseType,
  session,
  fidoPromptStatus,
) => {
  const { otpReissueRemainingAttempts, next } = challenge || '';

  if (challenge && challenge.type === 'CONSENT_COLLECTION_CHALLENGE') {
    return tcDisplay({
      tcSession: session,
      tcChallenge: challenge,
    });
  }

  if (challenge && challenge.type === 'QUESTION_COLLECTION_CHALLENGE') {
    return displayQuestionCollection({
      questionCollectionDisplaySession: session,
      questionCollectionDisplayChallenge: challenge,
    });
  }

  if (otpVerifyResponseType === 'EMAIL_VERIFIED' || otpVerifyResponseType === 'MOBILE_VERIFIED') {
    return showQNAPage({ displayQnAChallenge: challenge, displayQnASession: session });
  }
  if (
    otpVerifyResponseType === 'EMAIL_NOT_VERIFIED' ||
    otpVerifyResponseType === 'MOBILE_NOT_VERIFIED'
  ) {
    return verifyOTPFail({
      error: 'INVALID_OTP',
      otpReissueRemainingAttempts,
      stepupSession: session,
      sendOTPActionUrl: next,
    });
  }

  return verifyOTPFail({
    error: 'Application Error. Please try again later',
  });
};

export const requestNewCode = (action$, state$, { ajax }) => action$.pipe(
  ofType(REQUEST_NEW_CODE_REQUEST),
  switchMap(() => {
    const { type, stepupSession, sendOTPActionUrl } = state$.value.stepup;

    return sendingAsyncRequest(
      requestNewCodeService(ajax, type, stepupSession, sendOTPActionUrl),
    );
  }),
);

export const showQNAPageEpic = action$ => action$.pipe(
  ofType(SHOW_QNA),
  switchMap(() => of(push(STEP_UP_QNA + getUrlLocationSearch()))),
);

export const navigateHomeEpic = action$ => action$.pipe(
  ofType(NAVIGATE_HOME),
  // eslint-disable-next-line max-len
  switchMap(() => concat(of(resetState()), of(resetStateStepUp()), of(push(HOME + getUrlLocationSearch())))),
);
