import locale from './locale';
import login from './login';
import brand from './brand';
import signin from './signin';
import synergy from './synergy';
import stepup from './step-up';
import sendingRequest from './sending-request';
import changepassword from './change-password';
import termsandconditions from './terms-and-conditions';
import questionCollection from './question-collection';
import channelCollection from './channel-collection';
import dynamicMessage from './dynamic-message';

export const reducers = {
  locale,
  login,
  brand,
  signin,
  sendingRequest,
  stepup,
  changepassword,
  termsandconditions,
  questionCollection,
  channelCollection,
  dynamicMessage,
  synergy,
};
