import { of } from 'rxjs';
import {
  map, switchMap, catchError, mergeMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { createAction } from 'redux-actions';
import {
  checkFidoAuthRequired,
  getAjaxJsonHeader,
  getPasskeyUserHandle,
  isNotEmpty,
  pendoOnClick,
  setDeviceTokenForRequiredTypes,
} from '../../../../util/misc/common';
import { getCookie } from '../../../../util/misc';
import { types } from '..';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { constants } from '../login-constants';

const {
  VERIFY_USERID_REQUEST,
  VERIFY_USERID_SUCCESS,
  VERIFY_USERID_FAIL,
  AUTHENTICATE_FIDO2,
} = types;
const { VERIFY_USERID_ENDPOINT, CHALLENGE_TYPE_PASSKEY, COOKIE_GREET_FNAME } = constants;

const verifyUseridSuccess = createAction(VERIFY_USERID_SUCCESS);
const verifyUseridFail = createAction(VERIFY_USERID_FAIL);
const authenticateFido2 = createAction(AUTHENTICATE_FIDO2);

const handleResponse = (verifyUseridResponse, userid) => {
  setDeviceTokenForRequiredTypes(verifyUseridResponse);
  if (verifyUseridResponse.status === 404) {
    throw new Error('User ID not found');
  } else if (verifyUseridResponse.status !== 200) {
    throw new Error(verifyUseridResponse.statusText);
  }

  return verifyUseridSuccess({ userid, verifyUseridResponse });
};

// eslint-disable-next-line max-len
const callVerifyUseridService = (
  ajax,
  userid,
  locale,
  version,
  apisession,
  devicePrint,
  nextActionUrl,
  userHandle,
) => ajax({
  url: nextActionUrl || `${VERIFY_USERID_ENDPOINT}`,
  headers: { ...getAjaxJsonHeader() },
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify({
    identifier: userid,
    session: apisession,
    adpDeviceFingerprint: devicePrint,
    userHandle,
  }),
}).pipe(
  map(response => handleResponse(response, userid, locale, version)),
  catchError(err => of(verifyUseridFail(getErrorMessage(err)))),
);

export const verifyUseridEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(VERIFY_USERID_REQUEST),
  switchMap(({ payload: { devicePrint } }) => {
    const { userid, switchToPassword } = state$.value.login;
    const { locale } = state$.value.locale;
    const { apisession, signStartActionUrl } = state$.value.signin;
    const { version } = state$.value.signin.identityProviders[0].identifierRecovery || '';

    let userHandle;

    if (isNotEmpty(getPasskeyUserHandle(userid)) && !switchToPassword) {
      userHandle = getPasskeyUserHandle(userid);
    }

    return sendingAsyncRequest(
      callVerifyUseridService(
        ajax,
        userid,
        locale,
        version,
        apisession,
        devicePrint,
        signStartActionUrl,
        userHandle,
      ),
    );
  }),
);

export const checkFidoAuthEpic = (action$, state$) => action$.pipe(
  ofType(VERIFY_USERID_SUCCESS),
  mergeMap(() => {
    const {
      userid,
      sendingRequest,
      challengeType,
      switchToPassword,
      adpUserId,
      userFName,
    } = state$.value.login;
    const { locale } = state$.value.locale;
    const { version } = state$.value.signin.identityProviders[0].identifierRecovery || '';
    const passkeyGreetingsCheck = adpUserId && userFName;

    if (challengeType === CHALLENGE_TYPE_PASSKEY && !switchToPassword && !passkeyGreetingsCheck) {
      pendoOnClick(userid, locale, version);
      if (userid && !sendingRequest) {
        return of(authenticateFido2());
      }
    }
    return of();
  }),
);
