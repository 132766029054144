export function initProfiling(profilingDomain, orgId, pageId, riskSessionId) {
  try {
    // eslint-disable-next-line no-undef
    atoLNVIntObj.profile(profilingDomain, orgId, riskSessionId, pageId);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('initalizing threatMetrix failed', error);
  }
}

export function generateSessionID() {
  return `${Math.random()
    .toString(36)
    .substring(2)}${Date.now()}`;
}
