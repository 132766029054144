import { handleActions } from 'redux-actions';
import { types } from './channel-collection-actions';
import { constants } from '../../../components/channel-collection/channel-collection-constants';
import {
  isMobileVerification,
  isEmailCollection,
  isEmailVerification,
  isPeriodicPrompt,
} from '../../../util/misc';

const {
  SET_MOBILE,
  SET_PASSCODE,
  SET_MOBILE_CONSENT,
  DISPLAY_CHANNEL_COLLECTION,
  DISPLAY_MOBILE_COLLECTION,
  DISPLAY_LANDLINE_COLLECTION,
  DISPLAY_EMAIL_COLLECTION,
  DISPLAY_VOICE_BIOMETRICS_COLLECTION,
  SAVE_COMMUNICATION_FAIL,
  SAVE_COMMUNICATION_SUCCESS,
  SUBMIT_PASSCODE_SUCCESS,
  SUBMIT_PASSCODE_FAIL,
  RESEND_PASSCODE_SUCCESS,
  RESEND_PASSCODE_FAIL,
  DISPLAY_TEXT_MESSAGE_TERMS_AND_CONDITION,
  TOGGLE_RESEND_CONFIRMATION_PAGE,
  SKIP_CHANNEL_COLLECTION_FAIL,
  OPTOUT_CHANNEL_COLLECTION_FAIL,
  ACKNOWLEDGE_SUCCESS_FAIL,
  EMAIL_ACTIVATION_ACKNOWLEDGED_FAIL,
  VOICE_BIOMETRICS_ACKNOWLEDGED_FAIL,
  LANDLINE_ACKNOWLEDGED_FAIL,
  CANCEL_PASSCODE_COLLECTION_FAIL,
  SET_EMAIL_USAGE_TYPE,
  SET_LANDLINE_USAGE_TYPE,
  SET_EMAIL_ADDRESS,
  SET_PERSONAL_EMAIL_ADDRESS,
  SHOW_EMAIL_VALIDATION_ERROR,
  SET_LANDLINE_BUSINESS_VALUE,
  SET_LANDLINE_PERSONAL_VALUE,
  SET_EXTENSION,
  SET_ADD_LANDLINE_FLAG,
  CLEAR_EXTN_VALUE,
  SET_CONSENT_FLAG,
  VOICE_BIO_METRICS_OPTOUT_PAGE,
  SET_BUSINESS_LANDLINE_VALID,
  SET_PERSONAL_LANDLINE_VALID,
  SET_BUSINESS_COUNTRY_CODE,
  SET_PERSONAL_COUNTRY_CODE,
  SET_BUSINESS_ERR_MSG,
  SET_PERSONAL_ERR_MSG,
  SET_MOBILE_VALID,
  SET_MOBILE_ERR_MSG,
} = types;

const {
  DEFAULT_COUNTRY_CODE, MOBILE, EMAIL, PERSONAL, BUSINESS, NOT_CONSENT,
} = constants;

export const initialState = {
  textMessageTcModal: false,
  errorMessage: '',
  mobile: {
    country: '',
    countryCode: '',
    mobileNumber: '',
  },
  passcode: '',
  consent: NOT_CONSENT,
  emailAddress: '',
  emailUsageType: PERSONAL,
  landlineUsageType: BUSINESS,
  channelCollectionPassed: false,
  channelCollectionFailed: false,
  landlineValue: '',
  addLandlineFlag: false,
  landlineBusinessValue: '',
  landlinePersonalValue: '',
  consentFlag: false,
  businessLandlineValid: true,
  personalLandlineValid: true,
  businessLandlineErrMsg: '',
  personalLandlineErrMsg: '',
  mobileValid: true,
  mobileErrMsg: '',
  passcodeFailErrorMessage: '',
};

// prettier-ignore
const findMobileIndex = communications => communications.findIndex(
  communication => communication.type === MOBILE,
);

// prettier-ignore
const findPersonalEmailIndex = communications => communications.findIndex(
  communication => communication.type === EMAIL && communication.usageType === PERSONAL,
);

// prettier-ignore
const findBusinessEmailIndex = communications => communications.findIndex(
  communication => communication.type === EMAIL && communication.usageType === BUSINESS,
);

// eslint-disable-next-line max-len
const getEmailUsageType = communications => communications.filter(item => item.type === EMAIL && item.usageType === BUSINESS).length > 0 &&
  communications.filter(item => item.type === EMAIL && item.usageType === PERSONAL).length === 0 ?
  BUSINESS :
  PERSONAL;

const displayCollection = (state, payload) => displayChannelCollection(state, {
  ...payload,
  emailUsageType:
      payload.channelCollectionChallenge &&
      payload.channelCollectionChallenge.communications &&
      payload.channelCollectionChallenge.communications.length > 0 ?
        getEmailUsageType(payload.channelCollectionChallenge.communications) :
        PERSONAL,
});

const getMobileIndex = payload => payload.channelCollectionChallenge.communications &&
  payload.channelCollectionChallenge.communications.length > 0 &&
  findMobileIndex(payload.channelCollectionChallenge.communications);

const getEmailPersonalIndex = payload => payload.channelCollectionChallenge.communications &&
  payload.channelCollectionChallenge.communications.length > 0 &&
  findPersonalEmailIndex(payload.channelCollectionChallenge.communications);

const getEmailBusinessIndex = payload => payload.channelCollectionChallenge.communications &&
  payload.channelCollectionChallenge.communications.length > 0 &&
  findBusinessEmailIndex(payload.channelCollectionChallenge.communications);

// eslint-disable-next-line max-len
const getCountryCode = (mobileIndex, payload) => payload.channelCollectionChallenge.communications &&
  payload.channelCollectionChallenge.communications.length > 0 &&
  mobileIndex > -1 ?
  payload.channelCollectionChallenge.communications[mobileIndex].countryCode :
  '';

// eslint-disable-next-line max-len
const getMobileNumber = (mobileIndex, payload) => payload.channelCollectionChallenge.communications &&
  payload.channelCollectionChallenge.communications.length > 0 &&
  mobileIndex > -1 ?
  payload.channelCollectionChallenge.communications[mobileIndex].mobileNumber :
  '';

// eslint-disable-next-line max-len
const getCommunicationId = (mobileIndex, payload) => payload.channelCollectionChallenge.communications &&
  payload.channelCollectionChallenge.communications.length > 0 &&
  mobileIndex > -1 ?
  payload.channelCollectionChallenge.communications[mobileIndex].communicationId :
  '';

// eslint-disable-next-line max-len
const getConsentStatus = (mobileIndex, payload) => payload.channelCollectionChallenge.communications &&
  payload.channelCollectionChallenge.communications.length > 0 &&
  mobileIndex > -1 ?
  payload.channelCollectionChallenge.communications[mobileIndex].consentStatus :
  '';

// eslint-disable-next-line max-len
const getPersonalEmailAddress = (emailPersonalIndex, payload) => payload.channelCollectionChallenge.communications &&
  payload.channelCollectionChallenge.communications.length > 0 &&
  emailPersonalIndex > -1 ?
  payload.channelCollectionChallenge.communications[emailPersonalIndex].address :
  '';

// eslint-disable-next-line max-len
const getPersoanlEmailCommunicationId = (emailPersonalIndex, payload) => payload.channelCollectionChallenge.communications &&
  payload.channelCollectionChallenge.communications.length > 0 &&
  emailPersonalIndex > -1 ?
  payload.channelCollectionChallenge.communications[emailPersonalIndex].communicationId :
  '';

// eslint-disable-next-line max-len
const getPersonalPreferenceType = (emailPersonalIndex, payload) => payload.channelCollectionChallenge.communications &&
  payload.channelCollectionChallenge.communications.length > 0 &&
  emailPersonalIndex > -1 ?
  payload.channelCollectionChallenge.communications[emailPersonalIndex].preferenceType :
  '';

// eslint-disable-next-line max-len
const getBusinessEmailAddress = (emailBusinessIndex, payload) => payload.channelCollectionChallenge.communications &&
  payload.channelCollectionChallenge.communications.length > 0 &&
  emailBusinessIndex > -1 ?
  payload.channelCollectionChallenge.communications[emailBusinessIndex].address :
  '';

// eslint-disable-next-line max-len
const getBusinessEmailCommunicationId = (emailBusinessIndex, payload) => payload.channelCollectionChallenge.communications &&
  payload.channelCollectionChallenge.communications.length > 0 &&
  emailBusinessIndex > -1 ?
  payload.channelCollectionChallenge.communications[emailBusinessIndex].communicationId :
  '';

// eslint-disable-next-line max-len
const getBusinessPreferenceType = (emailBusinessIndex, payload) => payload.channelCollectionChallenge.communications &&
  payload.channelCollectionChallenge.communications.length > 0 &&
  emailBusinessIndex > -1 ?
  payload.channelCollectionChallenge.communications[emailBusinessIndex].preferenceType :
  '';

const checkPersonalEmailPresent = payload => payload &&
  payload.channelCollectionChallenge &&
  payload.channelCollectionChallenge.communications &&
  payload.channelCollectionChallenge.communications.length > 0 &&
  payload.channelCollectionChallenge.communications.some(
    item => item.type === EMAIL && item.usageType === PERSONAL,
  );

const checkBusinessEmailPresent = payload => payload &&
  payload.channelCollectionChallenge &&
  payload.channelCollectionChallenge.communications &&
  payload.channelCollectionChallenge.communications.length > 0 &&
  payload.channelCollectionChallenge.communications.some(
    item => item.type === EMAIL && item.usageType === BUSINESS,
  );

const displayChannelCollection = (state, payload) => {
  const mobileIndex = getMobileIndex(payload);
  const emailPersonalIndex = getEmailPersonalIndex(payload);
  const emailBusinessIndex = getEmailBusinessIndex(payload);

  let mobile = {
    country: '',
    countryCode: '',
    mobileNumber: '',
  };

  let mobileNumber = '';

  let personalEmailAddress = '';

  let persoanlEmailCommunicationId = '';

  let personalPreferenceType = '';

  let businessEmailAddress = '';

  let businessEmailCommunicationId = '';

  let businessPreferenceType = '';

  let consent = NOT_CONSENT;

  if (
    payload &&
    payload.channelCollectionChallenge &&
    (isMobileVerification(payload.channelCollectionChallenge.communicationCollectionType) ||
      isPeriodicPrompt(payload.channelCollectionChallenge.communicationCollectionType))
  ) {
    mobile = {
      country: DEFAULT_COUNTRY_CODE,
      countryCode: getCountryCode(mobileIndex, payload),
      mobileNumber: getMobileNumber(mobileIndex, payload),
      communicationId: getCommunicationId(mobileIndex, payload),
    };

    consent = getConsentStatus(mobileIndex, payload);
  }

  if (
    payload &&
    payload.channelCollectionChallenge &&
    isEmailVerification(payload.channelCollectionChallenge.communicationCollectionType)
  ) {
    mobileNumber = getMobileNumber(mobileIndex, payload);

    personalEmailAddress = getPersonalEmailAddress(emailPersonalIndex, payload);
    persoanlEmailCommunicationId = getPersoanlEmailCommunicationId(emailPersonalIndex, payload);
    personalPreferenceType = getPersonalPreferenceType(emailPersonalIndex, payload);

    businessEmailAddress = getBusinessEmailAddress(emailBusinessIndex, payload);
    businessEmailCommunicationId = getBusinessEmailCommunicationId(emailBusinessIndex, payload);
    businessPreferenceType = getBusinessPreferenceType(emailBusinessIndex, payload);
  }

  if (
    payload &&
    payload.channelCollectionChallenge &&
    isEmailCollection(payload.channelCollectionChallenge.communicationCollectionType)
  ) {
    mobileNumber = getMobileNumber(mobileIndex, payload);
  }

  let emailAddress = '';
  const isPersonalEmailPresent = checkPersonalEmailPresent(payload);
  const isBusinessEmailPresent = checkBusinessEmailPresent(payload);

  if (
    isEmailVerification(payload.channelCollectionChallenge.communicationCollectionType) &&
    isBusinessEmailPresent &&
    isPersonalEmailPresent
  ) {
    emailAddress = personalEmailAddress;
  } else if (
    isEmailVerification(payload.channelCollectionChallenge.communicationCollectionType) &&
    isPersonalEmailPresent
  ) {
    emailAddress = personalEmailAddress;
  } else if (
    isEmailVerification(payload.channelCollectionChallenge.communicationCollectionType) &&
    isBusinessEmailPresent
  ) {
    emailAddress = businessEmailAddress;
  }

  return {
    ...state,
    ...payload,
    mobile,
    mobileNumber,
    personalEmailAddress,
    persoanlEmailCommunicationId,
    personalPreferenceType,
    businessEmailAddress,
    businessEmailCommunicationId,
    businessPreferenceType,
    emailAddress,
    consent,
  };
};

const setErrorWithValue = (state, payload, error) => ({
  ...state,
  ...payload,
  errorMessage: error || '',
});

const mergeWithSetValue = (state, payload, error) => ({
  ...state,
  ...payload,
  ...error,
});

const setPhoneValue = (state, payload) => mergeWithSetValue(state, {
  ...payload,
  validationErrors: [],
  errorMessage: '',
});

const setValue = (state, payload) => ({ ...state, ...payload });

export default handleActions(
  {
    [SET_MOBILE]: (state, { payload }) => setPhoneValue(state, payload),
    [SET_LANDLINE_BUSINESS_VALUE]: (state, { payload }) => setPhoneValue(state, payload),
    [SET_LANDLINE_PERSONAL_VALUE]: (state, { payload }) => setPhoneValue(state, payload),
    [SET_EXTENSION]: (state, { payload }) => setPhoneValue(state, payload),
    [SET_BUSINESS_LANDLINE_VALID]: (state, { payload }) => setValue(state, payload),
    [SET_PERSONAL_LANDLINE_VALID]: (state, { payload }) => setValue(state, payload),
    [SET_BUSINESS_COUNTRY_CODE]: (state, { payload }) => setValue(state, payload),
    [SET_PERSONAL_COUNTRY_CODE]: (state, { payload }) => setValue(state, payload),
    [SET_BUSINESS_ERR_MSG]: (state, { payload }) => setValue(state, payload),
    [SET_PERSONAL_ERR_MSG]: (state, { payload }) => setValue(state, payload),
    [SET_MOBILE_VALID]: (state, { payload }) => setValue(state, payload),
    [SET_MOBILE_ERR_MSG]: (state, { payload }) => setValue(state, payload),

    // prettier-ignore
    [SET_PASSCODE]: (state, { payload }) => mergeWithSetValue(
      state, {
        ...payload,
        errorMessage: '',
        passcodeFailErrorMessage: '',
      },
    ),

    [TOGGLE_RESEND_CONFIRMATION_PAGE]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
      passcode: '',
      errorMessage: '',
      passcodeFailErrorMessage: '',
    }),

    [VOICE_BIO_METRICS_OPTOUT_PAGE]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
    }),

    [SET_EMAIL_USAGE_TYPE]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
    }),

    [SET_LANDLINE_USAGE_TYPE]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
      landlineBusinessValue: '',
      landlinePersonalValue: '',
      extnValue: '',
      validationErrors: [],
      errorMessage: '',
      businessLandlineValid: true,
      personalLandlineValid: true,
    }),

    [SET_ADD_LANDLINE_FLAG]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
      landlineBusinessValue:
          payload && payload.landlineUsageType === PERSONAL ? '' : payload.landlineBusinessValue,
      landlinePersonalValue:
          payload && payload.landlineUsageType === BUSINESS ? '' : payload.landlinePersonalValue,
    }),

    [CLEAR_EXTN_VALUE]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
      extnValue: '',
    }),

    [SET_CONSENT_FLAG]: (state, { payload }) => ({ ...state, ...payload }),

    [SET_EMAIL_ADDRESS]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
      errorMessage: '',
      palErrorMessage: '',
      validationErrors: [],
    }),

    [SET_PERSONAL_EMAIL_ADDRESS]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
      errorMessage: '',
      validationErrors: [],
    }),

    [SET_MOBILE_CONSENT]: (state, { payload }) => ({ ...state, ...payload }),

    [SHOW_EMAIL_VALIDATION_ERROR]: (state, { payload }) => ({ ...state, ...payload }),

    [DISPLAY_TEXT_MESSAGE_TERMS_AND_CONDITION]: (state, { payload }) => ({ ...state, ...payload }),

    // prettier-ignore
    [DISPLAY_CHANNEL_COLLECTION]: (state, { payload }) => displayCollection(state, payload),

    // prettier-ignore
    [DISPLAY_MOBILE_COLLECTION]: (state, { payload }) => displayChannelCollection(state, payload),

    // prettier-ignore
    [DISPLAY_LANDLINE_COLLECTION]: (state, { payload }) => displayChannelCollection(state, payload),

    // prettier-ignore
    [DISPLAY_EMAIL_COLLECTION]: (state, { payload }) => displayCollection(state, payload),

    // prettier-ignore
    [DISPLAY_VOICE_BIOMETRICS_COLLECTION]: (state, { payload }) => displayChannelCollection(
      state, payload,
    ),

    // eslint-disable-next-line max-len
    [SAVE_COMMUNICATION_FAIL]: (state, { payload }) => setErrorWithValue(state, payload, payload.errorMessage || ''),

    // eslint-disable-next-line max-len
    [SKIP_CHANNEL_COLLECTION_FAIL]: (state, { payload }) => setErrorWithValue(state, payload, payload.errorMessage || ''),

    // eslint-disable-next-line max-len
    [OPTOUT_CHANNEL_COLLECTION_FAIL]: (state, { payload }) => setErrorWithValue(state, payload, payload.errorMessage || ''),

    // eslint-disable-next-line max-len
    [ACKNOWLEDGE_SUCCESS_FAIL]: (state, { payload }) => setErrorWithValue(state, payload, payload.errorMessage || ''),

    // eslint-disable-next-line max-len
    [LANDLINE_ACKNOWLEDGED_FAIL]: (state, { payload }) => setErrorWithValue(state, payload, payload.errorMessage || ''),

    // eslint-disable-next-line max-len
    [EMAIL_ACTIVATION_ACKNOWLEDGED_FAIL]: (state, { payload }) => setErrorWithValue(state, payload, payload.errorMessage || ''),

    // eslint-disable-next-line max-len
    [VOICE_BIOMETRICS_ACKNOWLEDGED_FAIL]: (state, { payload }) => setErrorWithValue(state, payload, payload.errorMessage || ''),

    [SAVE_COMMUNICATION_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

    [SUBMIT_PASSCODE_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

    [SUBMIT_PASSCODE_FAIL]: (state, { payload }) => ({ ...state, ...payload }),

    [RESEND_PASSCODE_SUCCESS]: (state, { payload }) => mergeWithSetValue(state, {
      ...payload,
      resendPasscodeConfirmed: true,
      resendPasscodeClicked: false,
      passcode: payload.passcode || '',
    }),

    [RESEND_PASSCODE_FAIL]: (state, { payload }) => ({ ...state, ...payload }),

    [CANCEL_PASSCODE_COLLECTION_FAIL]: (state, { payload }) => ({ ...state, ...payload }),
  },
  initialState,
);
