import { of, from } from 'rxjs';
import {
  switchMap, tap, map, catchError,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import { createAction } from 'redux-actions';
import { types } from '..';
import {
  FIDO_DEVICE_REGISTER,
  FIDO_SETUP_CANCEL_PAGE,
  SUCCESS_PAGE,
} from '../../../../routes/router-url-constants';
import {
  getTargetUrl,
  getUrlLocationSearch,
  getController,
  trackEvents,
  getadpAccountId,
} from '../../../../util/misc';
import { FIDO_REG_FAIL, FIDO_REG_SUCCESS } from '../../../../util/pendo/constants';

const {
  REDIRECT_URL,
  FORM_REDIRECT_URL,
  FIDO_REGISTER,
  SHOW_FIDO_REGISTER_SUCCESS_PAGE,
  SHOW_FIDO_CANCEL_PAGE,
  SHOW_SUCCESS_PAGE,
  REDIRECT_TARGET,
} = types;

const redirectURL = createAction(types.REDIRECT_URL);
const showFidoCancelPage = createAction(types.SHOW_FIDO_CANCEL_PAGE);
const showFidoRegisterSuccessPage = createAction(types.SHOW_FIDO_REGISTER_SUCCESS_PAGE);

export const registerFidoEpic = (action$, state$) => action$.pipe(
  ofType(FIDO_REGISTER),
  switchMap(() => {
    const {
      userid, verifyUserActionUrl, fidoSession, adpAccountId,
    } = state$.value.login;

    let { apiChallengeRespondsession } = state$.value.login;

    const controller = getController();

    if (fidoSession) apiChallengeRespondsession = fidoSession;

    return from(
      controller.register(userid, apiChallengeRespondsession, verifyUserActionUrl, adpAccountId),
    );
  }),
  map(response => handleFidoResponse(response)),
  catchError(() => {
    trackEvents(FIDO_REG_FAIL);
    return of(showFidoCancelPage());
  }),
);

const handleFidoResponse = (response) => {
  const { accessToken, resultType } = response || '';

  if (resultType === 'SUCCESS' && accessToken) {
    trackEvents(FIDO_REG_SUCCESS);
    return showFidoRegisterSuccessPage();
  }
  trackEvents(FIDO_REG_FAIL);
  return showFidoCancelPage();
};

export const redirectEpic = action$ => action$.pipe(
  ofType(REDIRECT_URL),
  tap(({ payload: { url, newTab } }) => redirect(url, newTab)),
  switchMap(({ payload: { url, newTab } }) => of({ type: 'redirect happened', payload: { url, newTab } })),
);

export const redirectTargetEpic = (action$, state$) => action$.pipe(
  ofType(REDIRECT_TARGET),
  switchMap(() => {
    const { redirectUrl, cookieCacheToken } = state$.value.login;

    return of(redirectURL({ url: getTargetUrl(redirectUrl, cookieCacheToken) }));
  }),
);

const redirect = (url, newTab) => {
  if (newTab) {
    window.open(url, '_blank');
  } else {
    window.location.href = url;
  }

  return url;
};

export const formRedirectEpic = action$ => action$.pipe(
  ofType(FORM_REDIRECT_URL),
  tap(({ payload: { params } }) => formRedirect(params)),
  switchMap(({ payload: { params } }) => of({ type: 'redirect happened', payload: { params } })),
);

export const showFidoCancelPageEpic = action$ => action$.pipe(
  ofType(SHOW_FIDO_CANCEL_PAGE),
  switchMap(() => of(push(FIDO_SETUP_CANCEL_PAGE + getUrlLocationSearch()))),
);

export const showSuccessPageEpic = action$ => action$.pipe(
  ofType(SHOW_SUCCESS_PAGE),
  switchMap(() => of(push(SUCCESS_PAGE + getUrlLocationSearch()))),
);

export const showFidoRegisterSuccessPageEpic = action$ => action$.pipe(
  ofType(SHOW_FIDO_REGISTER_SUCCESS_PAGE),
  switchMap(() => of(push(FIDO_DEVICE_REGISTER + getUrlLocationSearch()))),
);

const formRedirect = (params) => {
  const form = document.createElement('form');

  document.body.appendChild(form);
  form.method = params.method || 'POST';
  form.action = params.action;
  // eslint-disable-next-line guard-for-in
  Object.keys(params).forEach((param) => {
    const input = document.createElement('input');

    input.type = 'hidden';
    input.name = param;
    input.value = params[param];
    form.appendChild(input);
  });
  form.submit();
};
