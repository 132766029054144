import { createTypes, asyncAction } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { NEXO_NAMESPACE } from '../../helpers/constants';

export const types = createTypes(
  NEXO_NAMESPACE,
  'TERMS_AND_CONDITIONS_CANCEL',
  'DISPLAY_TERMS_AND_CONDITIONS',
  asyncAction('TERMS_AND_CONDITIONS_ACCEPT'),
);

const tcAccept = createAction(types.TERMS_AND_CONDITIONS_ACCEPT_REQUEST);

const tcCancel = createAction(types.TERMS_AND_CONDITIONS_CANCEL);
const tcDisplay = createAction(types.DISPLAY_TERMS_AND_CONDITIONS);

export const actions = {
  tcAccept,
  tcCancel,
  tcDisplay,
};
