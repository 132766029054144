/* eslint-disable newline-after-var */
/* eslint-disable no-unneeded-ternary */
const minOneLetter = password => password && password.length > 0 && (password.match(/[A-Z]/) || password.match(/[a-z]/)) ?
  true :
  false;

const minOneUpperCaseLetter = password => password && password.length > 0 && password.match(/[A-Z]/) ? true : false;

const minOneLowerCaseLetter = password => password && password.length > 0 && password.match(/[a-z]/) ? true : false;

const minOneNumber = password => password && password.length > 0 && password.match(/\d/) ? true : false;

const minEightChars = password => (password && password.length >= 8 ? true : false);

const minimumRequirements = (password) => {
  let count = 0;
  if (minOneNumber(password)) {
    count += 1;
  }
  if (minOneUpperCaseLetter(password)) {
    count += 1;
  }
  if (minOneLowerCaseLetter(password)) {
    count += 1;
  }
  if (minOneSpecialCharacter(password)) {
    count += 1;
  }
  return minEightChars(password) && count >= 3 ? true : false;
};

function mixedCaseLetters(password) {
  return minOneUpperCaseLetter(password) && minOneLowerCaseLetter(password);
}

const minTwelveChars = password => (password && password.length >= 12 ? true : false);

const minOneSpecialCharacter = password => password && password.match(/[~`!@#$%^&*+=\-_[\]\\;.,()/{}|\\:<>?]/g) ? true : false;

const moreThan3SequentialCharacters = (password) => {
  // eslint-disable-next-line no-param-reassign
  password = password.replace(' ', '');
  // Check for sequential numerical characters
  for (const i in password) {
    if (
      +password[+i + 1] === +password[i] + 1 &&
      +password[+i + 2] === +password[i] + 2 &&
      +password[+i + 3] === +password[i] + 3
    ) {
      return 'moreThan3SequentialCharacters';
    }
  }
  for (const i in password
    .split('')
    .reverse()
    .join('')
    .toString()) {
    if (
      +password[+i - 1] === +password[i] + 1 &&
      +password[+i - 2] === +password[i] + 2 &&
      +password[+i - 3] === +password[i] + 3
    ) {
      return 'moreThan3SequentialCharacters';
    }
  }
  // Check for sequential alphabetical characters
  for (const i in password) {
    if (
      String.fromCharCode(password.charCodeAt(i) + 1) === password[+i + 1] &&
      String.fromCharCode(password.charCodeAt(i) + 2) === password[+i + 2] &&
      String.fromCharCode(password.charCodeAt(i) + 3) === password[+i + 3]
    ) {
      return 'moreThan3SequentialCharacters';
    }
  }
  for (const i in password
    .split('')
    .reverse()
    .join('')
    .toString()) {
    if (
      String.fromCharCode(password.charCodeAt(i) - 1) === password[+i + 1] &&
      String.fromCharCode(password.charCodeAt(i) - 2) === password[+i + 2] &&
      String.fromCharCode(password.charCodeAt(i) - 3) === password[+i + 3]
    ) {
      return 'moreThan3SequentialCharacters';
    }
  }
  return '';
};

const moreThan3RepeatedCharacters = (password) => {
  const validationResult = password && String(password).match(/(.)\1\1\1/) ? true : false;
  return validationResult ? 'moreThan3RepeatedCharacters' : '';
};

const nonASCIICharacter = (password) => {
  const validationResult = password && String(password).match(/[^ -~]/) ? true : false;
  return validationResult ? 'nonASCIICharacter' : '';
};

const beginOrEndWithSpace = (password) => {
  const validationResult =
    password && (String(password).endsWith(' ') || String(password).startsWith(' ')) ? true : false;
  return validationResult ? 'beginOrEndWithSpace' : '';
};

const maximum64Characters = (password) => {
  const validationResult = password && password.length > 64 ? true : false;
  return validationResult ? 'maximum64Characters' : '';
};

const checkforErrors = (password) => {
  const errorRules = [
    maximum64Characters,
    moreThan3RepeatedCharacters,
    moreThan3SequentialCharacters,
    nonASCIICharacter,
    beginOrEndWithSpace,
  ];
  const noError = 'noError';
  return errorRules
    .map((errorFunction) => {
      const errorName = errorFunction(password);

      return errorName ? errorName : noError;
    })
    .filter(result => result !== 'noError');
};

export {
  minOneLetter,
  minOneUpperCaseLetter,
  minOneLowerCaseLetter,
  minOneNumber,
  minEightChars,
  minimumRequirements,
  mixedCaseLetters,
  minTwelveChars,
  minOneSpecialCharacter,
  moreThan3SequentialCharacters,
  moreThan3RepeatedCharacters,
  nonASCIICharacter,
  beginOrEndWithSpace,
  maximum64Characters,
  checkforErrors,
};
