import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { DEFAULT_LOCALE } from '../../../util/locale';
import { NEXO_NAMESPACE } from '../../helpers/constants';

export const types = createTypes(NEXO_NAMESPACE, 'CHANGE_LOCALE', 'LOCALE_MODE');

// eslint-disable-next-line max-len
const changeLocale = createAction(types.CHANGE_LOCALE, (locale, shouldSetCookie) => ({
  locale: locale || DEFAULT_LOCALE,
  shouldSetCookie,
}));

const modeLocale = createAction(types.LOCALE_MODE, defaultLocaleMode => ({
  defaultLocaleMode,
}));

export const actions = {
  changeLocale,
  modeLocale,
};
