import { handleActions } from 'redux-actions';
import { types } from './brand-actions';

const {
  LOAD_BRAND_SUCCESS, LOAD_BRAND_FAIL, SET_BRANDINGSTYLES, SET_BRANDINGSTYLESLOADED,
} = types;

export const initialState = {
  brandingStyles: null,
  isBrandingStylesLoaded: false,
};

export default handleActions(
  {
    [LOAD_BRAND_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [LOAD_BRAND_FAIL]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_BRANDINGSTYLES]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_BRANDINGSTYLESLOADED]: (state, { payload }) => ({ ...state, ...payload }),
  },
  initialState,
);
