import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { createAction } from 'redux-actions';
import { push, goBack } from 'connected-react-router';

import { types } from '..';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { constants } from '../../login/login-constants';
import {
  HOME,
  STEP_UP_VERIFICATION,
  STEP_UP_CHANNELS,
} from '../../../../routes/router-url-constants';
import {
  getAjaxJsonHeader,
  getUrlLocationSearch,
  setDeviceTokenForRequiredTypes,
  getParamFromResponseHeader,
} from '../../../../util/misc';
import { OTP_HEADER } from '../../../../util/misc/constants';

const {
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  CANCEL_CHANNEL_LIST,
  DISPLAY_CHANNELS,
  REDIRECT_HOME_SCREEN,
} = types;
const {
  AUTHENTICATE_ENDPOINT,
  MOBILE_VERIFICATION_RESPONSE,
  EMAIL_VERIFICATION_RESPONSE,
} = constants;

const sendOTPSuccess = createAction(SEND_OTP_SUCCESS);
const sendOTPFail = createAction(SEND_OTP_FAIL);

const handleResponse = (response, responseType, authenticatorId, authenticatorFormat) => {
  setDeviceTokenForRequiredTypes(response);
  const { challenge, session } = response.response;
  const { otpReissueRemainingAttempts, next } = challenge;
  const otp = getParamFromResponseHeader(response, OTP_HEADER);
  const type = findExactResponseType(responseType);
  const returnResponse = {
    otp,
    type,
    authenticatorId,
    authenticatorFormat,
    otpReissueRemainingAttempts,
    stepupSession: session,
    sendOTPActionUrl: next,
  };

  return sendOTPSuccess({ ...returnResponse });
};
const findExactResponseType = (type) => {
  if (type === 'MOBILE') return MOBILE_VERIFICATION_RESPONSE;
  if (type === 'EMAIL') return EMAIL_VERIFICATION_RESPONSE;
  return '';
};

const sendOTPService = (
  ajax,
  state$,
  type,
  authenticatorId,
  authenticatorType,
  authenticatorFormat,
  channelsDisplaySession,
  nextActionUrl,
) => ajax({
  url: nextActionUrl || `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify({
    response: {
      type,
      authenticator: {
        type: authenticatorType,
        authenticatorId,
      },
    },
    session: channelsDisplaySession,
  }),
}).pipe(
  // eslint-disable-next-line max-len
  map(response => handleResponse(response, authenticatorType, authenticatorId, authenticatorFormat)),
  catchError(err => of(sendOTPFail(getErrorMessage(err)))),
);

// prettier-ignore
export const sendOTPEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(SEND_OTP_REQUEST),
  switchMap(({
    payload: {
      type, authenticatorId, authenticatorType, authenticatorFormat, channelsDisplaySession,
    },
  }) => sendingAsyncRequest(
    // eslint-disable-next-line max-len
    sendOTPService(ajax, state$, type, authenticatorId, authenticatorType, authenticatorFormat, channelsDisplaySession, state$.value.stepup.channelsDisplayChallenge && state$.value.stepup.channelsDisplayChallenge.next),
  )),
);

export const sendOTPSuccessEpic = action$ => action$.pipe(
  ofType(SEND_OTP_SUCCESS),
  switchMap(() => of(push(STEP_UP_VERIFICATION + getUrlLocationSearch()))),
);

export const channelCancelEpic = action$ => action$.pipe(
  ofType(CANCEL_CHANNEL_LIST),
  switchMap(() => of(push(HOME + getUrlLocationSearch()))),
);

export const displayChannelsEpic = action$ => action$.pipe(
  ofType(DISPLAY_CHANNELS),
  switchMap(() => of(push(STEP_UP_CHANNELS + getUrlLocationSearch()))),
);

export const redirectHomeEpic = action$ => action$.pipe(
  ofType(REDIRECT_HOME_SCREEN),
  switchMap(() => of(goBack(HOME + getUrlLocationSearch()))),
);
