/* eslint-disable react/prefer-stateless-function */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { IntlProvider } from 'react-intl';
import Routes from './routes';
import { actions as localeActions } from './redux/ducks/locale';
import { actions as loginActions } from './redux/ducks/login';
import { actions as changePasswordActions } from './redux/ducks/change-password';
import { actions as tcActions } from './redux/ducks/terms-and-conditions';
import { getLocale } from './util/locale';

export const App = (props) => {
  const [messages, setMessages] = useState({});
  const { history, locale, rightToLeftLangCodes } = props;

  useEffect(() => {
    const localeLabel = getLocale(locale);
    import(`../locales/labels_${localeLabel}.json`).then((message) => {
      setMessages(message);
    });
    if (rightToLeftLangCodes && 
      rightToLeftLangCodes.length > 0 && 
      rightToLeftLangCodes.includes(locale)) {
      document.getElementsByTagName('html')[0].setAttribute("dir", 'rtl');
    } else {
      document.getElementsByTagName('html')[0].setAttribute("dir", 'ltr');
    }
  }, [locale]);

  return (
    <IntlProvider messages={ messages }>
      <ConnectedRouter history={ history }>
        <Routes { ...props } />
      </ConnectedRouter>
    </IntlProvider>
  );
};

App.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  locale: PropTypes.string,
};

const mapStateToProps = ({
  locale,
  login,
  brand,
  signin,
  sendingRequest,
  changepassword,
  termsandconditions,
}) => ({
  ...locale,
  ...login,
  ...brand,
  ...signin,
  ...sendingRequest,
  ...changepassword,
  ...termsandconditions,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    Object.assign(
      {},
      { ...localeActions },
      { ...loginActions },
      { ...changePasswordActions },
      { ...tcActions },
    ),
    dispatch,
  );
const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(App);
