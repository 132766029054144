import { setCookie } from './cookie-helper';
import { ADP_URL_COOKIE_EXPIRES, STATE_PARAMETER } from './constants';
import { validateTargetURLDomain, validateReturnURLDomain } from '../../components/url-check';

const DEFAULT_TARGETURL = '/local_success.html';
const SM_TARGET = '-SM-';
const TARGET = 'TARGET';
const NOT_WHITE_LIST_TARGET_URL_CHARS = /[^a-zA-Z0-9:/?=&.%;*+_#$@{}^!/-]/g;
const SKIP_AG_PARAMETER_LIST_DEFAULT = 'APPID,productId,returnURL,callingAppId';

export const getTargetUrl = (param, cookieCacheToken) => {
  const browserUrl = window.location.search;
  const prodDefnUrl = param || DEFAULT_TARGETURL;

  let targetUrl = param || DEFAULT_TARGETURL;
  const smIndex = browserUrl.indexOf(TARGET);

  if (smIndex !== -1) {
    targetUrl = decodeURIComponent(browserUrl.substring(smIndex + TARGET.length + 1));

    if (targetUrl.includes(SM_TARGET)) {
      targetUrl = targetUrl.slice(SM_TARGET.length);
    }
    if (targetUrl.includes('&')) {
      [targetUrl] = targetUrl.split('&');
    }
    // SiteMinder adds extra '-' to all '-' characters
    targetUrl = targetUrl.replace(/(--)+/g, '-');
  } else {
    targetUrl = decodeURIComponent(targetUrl);
  }

  // now appending additional params sent by the applications.
  const requiredParamsForTarget = removeParameters();

  if (requiredParamsForTarget) {
    if (targetUrl.includes('?')) {
      targetUrl = `${targetUrl}&${requiredParamsForTarget}`;
    } else {
      targetUrl = `${targetUrl}?${requiredParamsForTarget}`;
    }
  }
  targetUrl = targetUrl.replace(NOT_WHITE_LIST_TARGET_URL_CHARS, '');
  targetUrl = validateTargetURLDomain(targetUrl);
  if (targetUrl === null) targetUrl = prodDefnUrl;
  saveToCookie(targetUrl);
  if (cookieCacheToken) targetUrl = `${targetUrl}&${STATE_PARAMETER}=${cookieCacheToken}`;
  // removing encoding since this is the best for redirect..
  return targetUrl;
};

export const getUrlLocationSearch = () => window.location.search;

export const getReturnUrl = () => {
  let returnUrl = getParameterByName('returnURL');

  returnUrl = validateReturnURLDomain(returnUrl);
  return returnUrl;
};

export const removeParameters = () => {
  const url = window.location.search;

  if (!url) {
    return '';
  }
  const decodeUrl = decodeURIComponent(url);

  let removeParamsCSV =
    window.env && window.env.SKIP_AG_PARAMETER_LIST !== undefined ?
      window.env.SKIP_AG_PARAMETER_LIST :
      SKIP_AG_PARAMETER_LIST_DEFAULT;

  removeParamsCSV += `,${STATE_PARAMETER}`;

  const removeParams = removeParamsCSV.split(',').map(ele => ele.trim());

  removeParams.push(TARGET); // so that we dont append target again to target

  const urlParameters = decodeUrl.slice(1).split('&');

  let requiredParamsForTarget = '';

  urlParameters.forEach((queryParam) => {
    if (!removeParams.includes(queryParam.split('=')[0])) {
      if (!requiredParamsForTarget.includes(queryParam)) {
        requiredParamsForTarget = requiredParamsForTarget.concat(queryParam);
        requiredParamsForTarget = requiredParamsForTarget.concat('&');
      }
    }
  });

  requiredParamsForTarget = requiredParamsForTarget.slice(0, -1);

  return requiredParamsForTarget;
};

export const getParameterByName = (name) => {
  const url = window.location.href;

  // eslint-disable-next-line no-param-reassign
  name = name.replace(/[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const saveToCookie = (targetUrl) => {
  const paramSepIndex = targetUrl.indexOf('?');

  if (paramSepIndex !== -1) {
    const targetUrlParam = targetUrl.substring(paramSepIndex + 1);

    const targetUrlParamList = targetUrlParam.split('&');

    targetUrlParamList.forEach((ele) => {
      setUrlParamCookie(ele);
    });
  }
};
export const setUrlParamCookie = (ele) => {
  if (ele && ele.indexOf('=') !== -1) {
    const key = ele.split('=')[0];

    const value = ele.split('=')[1];

    setCookie(key, value, true, ADP_URL_COOKIE_EXPIRES);
  }
};
