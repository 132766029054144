import { createTypes, asyncAction } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { NEXO_NAMESPACE } from '../../helpers/constants';

export const types = createTypes(
  NEXO_NAMESPACE,
  asyncAction('SAVE_COMMUNICATION'),
  asyncAction('SUBMIT_PASSCODE'),
  asyncAction('RESEND_PASSCODE'),
  'SET_MOBILE',
  'SET_PASSCODE',
  'SET_MOBILE_CONSENT',
  'DISPLAY_CHANNEL_COLLECTION',
  'DISPLAY_MOBILE_COLLECTION',
  'DISPLAY_LANDLINE_COLLECTION',
  'DISPLAY_EMAIL_COLLECTION',
  'DISPLAY_VOICE_BIOMETRICS_COLLECTION',
  'DISPLAY_TEXT_MESSAGE_TERMS_AND_CONDITION',
  'TOGGLE_RESEND_CONFIRMATION_PAGE',
  'SET_EMAIL_USAGE_TYPE',
  'SET_LANDLINE_USAGE_TYPE',
  'SET_EMAIL_ADDRESS',
  'SET_PERSONAL_EMAIL_ADDRESS',
  'SHOW_EMAIL_VALIDATION_ERROR',
  asyncAction('CANCEL_PASSCODE_COLLECTION'),
  asyncAction('SKIP_CHANNEL_COLLECTION'),
  asyncAction('OPTOUT_CHANNEL_COLLECTION'),
  asyncAction('ACKNOWLEDGE_SUCCESS'),
  asyncAction('LANDLINE_ACKNOWLEDGED'),
  asyncAction('EMAIL_ACTIVATION_ACKNOWLEDGED'),
  asyncAction('VOICE_BIOMETRICS_ACKNOWLEDGED'),
  'SET_LANDLINE_BUSINESS_VALUE',
  'SET_LANDLINE_PERSONAL_VALUE',
  'SET_EXTENSION',
  'SET_ADD_LANDLINE_FLAG',
  'CLEAR_EXTN_VALUE',
  'SET_CONSENT_FLAG',
  'VOICE_BIO_METRICS_OPTOUT_PAGE',
  'SET_BUSINESS_LANDLINE_VALID',
  'SET_PERSONAL_LANDLINE_VALID',
  'SET_BUSINESS_COUNTRY_CODE',
  'SET_PERSONAL_COUNTRY_CODE',
  'SET_BUSINESS_ERR_MSG',
  'SET_PERSONAL_ERR_MSG',
  'SET_MOBILE_VALID',
  'SET_MOBILE_ERR_MSG',
);

const cancelPasscodeCollection = createAction(types.CANCEL_PASSCODE_COLLECTION_REQUEST);

const setMobile = createAction(types.SET_MOBILE, mobile => ({
  mobile,
}));

const setPasscode = createAction(types.SET_PASSCODE, passcode => ({ passcode }));

// eslint-disable-next-line max-len
const setMobileConsent = createAction(types.SET_MOBILE_CONSENT, consent => ({
  consent,
}));

const setConsentFlag = createAction(types.SET_CONSENT_FLAG, consentFlag => ({
  consentFlag,
}));

const setEmailUsageType = createAction(types.SET_EMAIL_USAGE_TYPE, emailUsageType => ({
  emailUsageType,
}));

const setEmailAddress = createAction(types.SET_EMAIL_ADDRESS, emailAddress => ({
  emailAddress,
}));

const setPersonalEmailAddress = createAction(
  types.SET_PERSONAL_EMAIL_ADDRESS,
  personalEmailAddress => ({
    personalEmailAddress,
  }),
);

const toggleResendConfirmationPage = createAction(
  types.TOGGLE_RESEND_CONFIRMATION_PAGE,
  resendPasscodeClicked => ({ resendPasscodeClicked }),
);

const voiceBioMetricsOptOutPage = createAction(
  types.VOICE_BIO_METRICS_OPTOUT_PAGE,
  voiceBioMetricsOptClicked => ({ voiceBioMetricsOptClicked }),
);

// prettier-ignore
const saveCommunicationChannel = createAction(types.SAVE_COMMUNICATION_REQUEST,
  (addedCommunications, changedCommunications) => ({
    addedCommunications,
    changedCommunications,
  }));

// prettier-ignore
const skipChannelCollection = createAction(types.SKIP_CHANNEL_COLLECTION_REQUEST,
  remindMeLater => ({
    remindMeLater,
  }));

const optOutChannelCollection = createAction(
  types.OPTOUT_CHANNEL_COLLECTION_REQUEST,
  optOutFlag => ({
    optOutFlag,
  }),
);

const submitPasscode = createAction(types.SUBMIT_PASSCODE_REQUEST);

const resendPasscode = createAction(types.RESEND_PASSCODE_REQUEST);

const acknowledgeSuccess = createAction(types.ACKNOWLEDGE_SUCCESS_REQUEST);

const landlineAcknowledged = createAction(types.LANDLINE_ACKNOWLEDGED_REQUEST);

const showEmailValidationError = createAction(
  types.SHOW_EMAIL_VALIDATION_ERROR,
  palErrorMessage => ({
    palErrorMessage,
  }),
);

const emailActivationAcknowledged = createAction(types.EMAIL_ACTIVATION_ACKNOWLEDGED_REQUEST);

const voiceBioMetricsAcknowledged = createAction(types.VOICE_BIOMETRICS_ACKNOWLEDGED_REQUEST);

const dispalyTextMessageTermsAndCondition = createAction(
  types.DISPLAY_TEXT_MESSAGE_TERMS_AND_CONDITION,
  textMessageTcModal => ({
    textMessageTcModal,
  }),
);

const onSetLandlineUsageType = createAction(types.SET_LANDLINE_USAGE_TYPE, landlineUsageType => ({
  landlineUsageType,
}));

const setExtnValue = createAction(types.SET_EXTENSION, extnValue => ({
  extnValue,
}));

const setAddLandlineFlag = createAction(
  types.SET_ADD_LANDLINE_FLAG,
  (addLandlineFlag, landlineUsageType, landlineBusinessValue, landlinePersonalValue) => ({
    addLandlineFlag,
    landlineUsageType,
    landlineBusinessValue,
    landlinePersonalValue,
  }),
);

const setLandlineBusinessValue = createAction(
  types.SET_LANDLINE_BUSINESS_VALUE,
  landlineBusinessValue => ({
    landlineBusinessValue,
  }),
);

const setLandlinePersonalValue = createAction(
  types.SET_LANDLINE_PERSONAL_VALUE,
  landlinePersonalValue => ({
    landlinePersonalValue,
  }),
);

const clearExtnValue = createAction(types.CLEAR_EXTN_VALUE, extnValue => ({
  extnValue,
}));

const setBusinessLandlineValid = createAction(
  types.SET_BUSINESS_LANDLINE_VALID,
  businessLandlineValid => ({ businessLandlineValid }),
);

const setPersonalLandlineValid = createAction(
  types.SET_PERSONAL_LANDLINE_VALID,
  personalLandlineValid => ({ personalLandlineValid }),
);

const setBusinessCountryCode = createAction(
  types.SET_BUSINESS_COUNTRY_CODE,
  businessCountryCode => ({ businessCountryCode }),
);

const setPersonalCountryCode = createAction(
  types.SET_PERSONAL_COUNTRY_CODE,
  personalCountryCode => ({ personalCountryCode }),
);

const setBusinessLandlineErrMsg = createAction(
  types.SET_BUSINESS_ERR_MSG,
  businessLandlineErrMsg => ({ businessLandlineErrMsg }),
);

const setPersonalLandlineErrMsg = createAction(
  types.SET_PERSONAL_ERR_MSG,
  personalLandlineErrMsg => ({ personalLandlineErrMsg }),
);

const setMobileValid = createAction(types.SET_MOBILE_VALID, mobileValid => ({ mobileValid }));

const setMobileErrMsg = createAction(types.SET_MOBILE_ERR_MSG, mobileErrMsg => ({ mobileErrMsg }));

export const actions = {
  setMobile,
  setMobileConsent,
  skipChannelCollection,
  saveCommunicationChannel,
  dispalyTextMessageTermsAndCondition,
  setPasscode,
  submitPasscode,
  resendPasscode,
  acknowledgeSuccess,
  toggleResendConfirmationPage,
  cancelPasscodeCollection,
  setEmailUsageType,
  setEmailAddress,
  setPersonalEmailAddress,
  emailActivationAcknowledged,
  showEmailValidationError,
  onSetLandlineUsageType,
  setLandlineBusinessValue,
  setLandlinePersonalValue,
  setExtnValue,
  setAddLandlineFlag,
  clearExtnValue,
  landlineAcknowledged,
  optOutChannelCollection,
  voiceBioMetricsAcknowledged,
  setConsentFlag,
  voiceBioMetricsOptOutPage,
  setBusinessLandlineValid,
  setPersonalLandlineValid,
  setBusinessLandlineErrMsg,
  setPersonalLandlineErrMsg,
  setBusinessCountryCode,
  setPersonalCountryCode,
  setMobileErrMsg,
  setMobileValid,
};
