/* global pendo */

let isPendoDefined = false;

export default class PendoUtil {
  static pendoInitialize(pendoApiKey, transactionId) {
    isPendoDefined = true;
    const scriptNode = document.createElement('script');

    scriptNode.setAttribute('type', 'text/javascript');
    scriptNode.innerText = `! (function(apiKey){   (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];v=['initialize','identify','updateOptions','pageLoad'];for(w=0,x=v.length;w<x;++w)(function(m){  o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');pendo.initialize({  visitor: {id:'',sessionId:'${transactionId}' },});})  ('${pendoApiKey}');`;
    document.getElementsByTagName('head')[0].appendChild(scriptNode);
  }

  static clearPendoSession() {
    if (isPendoDefined) {
      pendo.clearSession();
    }
  }

  static pendoUpdate(adpAccountId) {
    if (isPendoDefined) {
      pendo.updateOptions({
        visitor: {
          id: adpAccountId,
        },
      });
    }
  }

  static pendoTrackEvent(eventName, locale, version) {
    if (isPendoDefined) {
      pendo.track(eventName, {
        locale,
        Region: version,
      });
    }
  }

  static pendoVisitorIdUpdate(adpAccountId, locale, version) {
    if (isPendoDefined && adpAccountId !== undefined) {
      pendo.identify({
        visitor: {
          id: adpAccountId,
          locale,
          version,
        },
      });
    }
  }
}
