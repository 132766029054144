import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { createAction } from 'redux-actions';
import { types } from '..';
import { constants as dynMessageConstants } from '../dynamic-message-constants';
import { getErrorMessage } from '../../../helpers/epics';
import { getParameterByName } from '../../../../util/misc/target-finder';
import { constants as loginConstants } from '../../../../components/login/login-comp-constants';

const { CALLING_APP_ID_BROWSER_LABEL, USER_AGENT_FILTER } = loginConstants;

const { LOAD_DYNAMICMESSAGE_REQUEST, LOAD_DYNAMICMESSAGE_FAIL, SET_DYNAMICMESSAGE } = types;
const { GET_DYNAMIC_MESSAGE_ENDPOINT, CONTENT_TYPE_JSON } = dynMessageConstants;
const loadDynamicMessageFailEvent = createAction(LOAD_DYNAMICMESSAGE_FAIL);
const setDynamicMessageEvent = createAction(SET_DYNAMICMESSAGE);
const callingAppId = getParameterByName(CALLING_APP_ID_BROWSER_LABEL);
const browser = window.navigator.userAgent;

let UAFilter = null;

// eslint-disable-next-line max-len
function getDMSUrl() {
  let dynamicMessageUrl = null;

  try {
    dynamicMessageUrl =
      window.env && window.env.DYNAMIC_MESSAGING_SERVICE ?
        window.env.DYNAMIC_MESSAGING_SERVICE :
        GET_DYNAMIC_MESSAGE_ENDPOINT;

    if (browser.indexOf('Trident') > -1) {
      UAFilter = USER_AGENT_FILTER;
    }

    if (UAFilter) {
      dynamicMessageUrl = `${dynamicMessageUrl}?callingAppId=${callingAppId}&target=${UAFilter}`;
    } else {
      dynamicMessageUrl = `${dynamicMessageUrl}?callingAppId=${callingAppId}`;
    }
  } catch {
    dynamicMessageUrl = GET_DYNAMIC_MESSAGE_ENDPOINT;
  }
  return dynamicMessageUrl;
}

const dmsUrl = getDMSUrl();

const handleResponse = (response) => {
  const { status } = response.response;

  if (status === 200) {
    const { dynMsg } = response.response.response;

    return setDynamicMessageEvent({ dynMsg, dynamicMessageLoaded: true });
  }
  if (status === 204) {
    // eslint-disable-next-line no-console
    console.log('dynamic message expired');
  }
  return setDynamicMessageEvent({ dynMsg: null, dynamicMessageLoaded: true });
};

const loadDynamicMessageService = ajax => ajax({
  url: `${dmsUrl}`,
  headers: { 'Content-Type': CONTENT_TYPE_JSON },
  method: 'get',
}).pipe(
  map(response => handleResponse({ response })),
  catchError(err => of(loadDynamicMessageFailEvent(getErrorMessage(err)))),
);

export const loadDynamicMessageEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(LOAD_DYNAMICMESSAGE_REQUEST),
  switchMap(() => loadDynamicMessageService(ajax)),
);
