import { createTypes, asyncAction } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { NEXO_NAMESPACE } from '../../helpers/constants';

export const types = createTypes(
  NEXO_NAMESPACE,
  'SET_FIRST_QUESTION',
  'SET_SECOND_QUESTION',
  'SET_THIRD_QUESTION',
  'CANCEL_QUESTION_COLLECTION',
  asyncAction('SKIP_QUESTION_COLLECTION'),
  'DISPLAY_QUESTION_COLLECTION',
  'SET_FIRST_ANSWER',
  'SET_SECOND_ANSWER',
  'SET_THIRD_ANSWER',
  asyncAction('SAVE_QNA'),
);

const setFirstAnswer = createAction(types.SET_FIRST_ANSWER, firstAnswer => ({
  firstAnswer,
}));

const setSecondAnswer = createAction(types.SET_SECOND_ANSWER, secondAnswer => ({
  secondAnswer,
}));

const setThirdAnswer = createAction(types.SET_THIRD_ANSWER, thirdAnswer => ({
  thirdAnswer,
}));

// prettier-ignore
const saveQNA = createAction(types.SAVE_QNA_REQUEST);

const setFirstQuestion = createAction(types.SET_FIRST_QUESTION, firstQuestion => ({
  firstQuestion,
}));
const setSecondQuestion = createAction(types.SET_SECOND_QUESTION, secondQuestion => ({
  secondQuestion,
}));
const setThirdQuestion = createAction(types.SET_THIRD_QUESTION, thirdQuestion => ({
  thirdQuestion,
}));

const cancelQuestionCollection = createAction(types.CANCEL_QUESTION_COLLECTION);

// prettier-ignore
const skipQuestionCollection = createAction(types.SKIP_QUESTION_COLLECTION_REQUEST);

export const actions = {
  setFirstAnswer,
  setSecondAnswer,
  setThirdAnswer,
  saveQNA,
  setFirstQuestion,
  setSecondQuestion,
  setThirdQuestion,
  cancelQuestionCollection,
  skipQuestionCollection,
};
