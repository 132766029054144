import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SdfInput, SdfButton } from '@synerg/react-components';
import { isNotBlank, isEnterKeyPressed } from '../../util/misc';

const OTPPage = (props) => {
  const {
    currentlySending,
    otp,
    setOTP,
    verifyOTP,
    requestNewCode,
    authenticatorFormat,
    type,
    otpReissueRemainingAttempts,
    otpDeadEndScenario,
    navigateToHome,
    isAppView,
    intl,
    signedInResponseType,
    otpVerifyResponseType,
  } = props;
  const isOtpVerifySuccess =
    signedInResponseType == 'SIGNED_IN' || otpVerifyResponseType == 'SIGNED_IN';

  const isOTPEntered = !currentlySending && isNotBlank(otp) && !isOtpVerifySuccess;

  const otpMask = {
    mask: 'Number',
  };

  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    !currentlySending && verifyOTP();
  };

  const submitRequestCode = () => {
    if (otpDeadEndScenario) {
      navigateToHome();
    } else {
      !currentlySending && requestNewCode();
    }
  };

  const handleKeyPressOnNexIn = (event) => {
    if (event.charCode === 13 && isOTPEntered) {
      event.preventDefault();
      !currentlySending && verifyOTP();
    }
  };

  const otpAttemptsExceeded = otpReissueRemainingAttempts === 0;

  return (
    <div>
      <form onSubmit={ onSubmit }>
        <div id="frmLogin">
          <div>
            <div>
              <div className="md:font-medium md:not-italic text-md md:leading-md mt-2">
                { ' ' }
                <FormattedMessage
                  id={
                    type === 'MOBILE_VERIFICATION_RESPONSE' ?
                      'stepup.passcode.title1' :
                      'stepup.passcode.email'
                  }
                  defaultMessage="We sent a code to"
                  description="otp page title"
                />
                { authenticatorFormat }
                { '. ' }
                <br />
                <FormattedMessage
                  id="stepup.verifycode.title2"
                  defaultMessage="Enter it within 10 minutes"
                  description="otp page title"
                />
              </div>

              <div
                id="otpform"
                className="flex-row flex-1 text-center pt-4"
              >
                <SdfInput
                  autoFocus
                  id="otp-page_passcode"
                  data-testid="otp-page_passcode"
                  inputType="decimal"
                  advancedMask={ otpMask }
                  label={ intl.formatMessage({
                    id: 'stepup.passcode.input.label',
                    defaultMessage: 'Passcode',
                    description: 'Passcode label',
                  }) }
                  maxlength={ 7 }
                  value={ otp }
                  onSdfInput={ eve => setOTP(eve.detail) }
                  onKeyPress={ handleKeyPressOnNexIn }
                />
              </div>
              <div
                className={
                  isAppView ?
                    'flex-row flex-1 mt-10 text-center' :
                    'flex md:flex-row-reverse mt-20 md:mt-8 flex-col items-center'
                }
              >
                <SdfButton
                  buttonStyle="link"
                  emphasis="primary"
                  data-testid="verifyOtpBtn"
                  id="verifyOtpBtn"
                  className={ isAppView ? 'w-full p-2' : 'w-full max-w-xs md:min-w-32 md:w-auto' }
                  disabled={ !isOTPEntered || undefined }
                  size="lg"
                  onClick={ onSubmit }
                  onKeyPress={ handleKeyPressOnNexIn }
                >
                  <span>
                    <FormattedMessage
                      id="olp_submit"
                      defaultMessage="Submit"
                      description="stepup submit in button"
                    />
                  </span>
                </SdfButton>
                <SdfButton
                  id="request_new_code_div"
                  data-testid="request_new_code"
                  className={
                    isAppView ?
                      'w-full p-2' :
                      'md:p-4 w-full max-w-xs md:min-w-32 md:w-auto mt-4 md:mt-0'
                  }
                  buttonStyle="link"
                  size="lg"
                  hidden={ otpAttemptsExceeded }
                  onClick={ submitRequestCode }
                  onKeyPress={ (event) => {
                    isEnterKeyPressed(event.key) && submitRequestCode();
                  } }
                >
                  { ' ' }
                  { otpDeadEndScenario && (
                    <span id="cancel_span_link">
                      <FormattedMessage
                        id="olp_cancel"
                        defaultMessage="Back to Home"
                        description="Go Back to request Code"
                      />
                    </span>
                  ) }
                  { !otpDeadEndScenario && (
                    <FormattedMessage
                      id="stepup.verifycode.back"
                      defaultMessage="Back"
                      description="Go Back to request Code"
                    />
                  ) }
                </SdfButton>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

OTPPage.propTypes = {
  authenticatorFormat: PropTypes.string,
  currentlySending: PropTypes.bool,
  otpDeadEndScenario: PropTypes.bool,
  setOTP: PropTypes.func.isRequired,
  verifyOTP: PropTypes.func.isRequired,
  navigateToHome: PropTypes.func.isRequired,
  requestNewCode: PropTypes.func.isRequired,
  otp: PropTypes.string,
  signedInResponseType: PropTypes.string,
  otpVerifyResponseType: PropTypes.string,
  otpReissueRemainingAttempts: PropTypes.number,
  type: PropTypes.string,
  result: PropTypes.shape({
    challenge: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
    stepupSession: PropTypes.string.isRequired,
  }),
  isAppView: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};

export default injectIntl(OTPPage);
