/* eslint-disable no-undef */
export const getPlatformAuthenticatorService = () => new Promise((resolve) => {
  if (
    !window.PublicKeyCredential ||
      typeof PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable !== 'function'
  ) {
    // eslint-disable-next-line callback-return
    resolve(false);
  } else {
    PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
      .then(resolve)
      .catch(() => {
        // In case of error return false.
        resolve(false);
      });
  }
});
