/* eslint-disable global-require */

import { of } from 'rxjs';
import {
  map, switchMap, catchError, concat,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { createAction } from 'redux-actions';
import lscache from 'lscache';
import { types } from '..';
import { getErrorMessage } from '../../../helpers/epics';
import { constants } from '../brand-constants';

import {
  getCallingAppId,
  getOrganizationIdForBranding,
  isLocalStorageSupported,
  findAndReplaceText,
} from '../../../../util/misc';

const { LOAD_BRAND_REQUEST, LOAD_BRAND_SUCCESS, LOAD_BRAND_FAIL } = types;

const {
  GET_BRAND_ENDPOINT,
  DEFAULT,
  LS_EXPIRY_PERIOD_IN_MINUTES_DEFAULT,
  LOCAL_BRANDING_ENV_FILE_SUFFIX_DEFAULT,
  BRAND_LS_PREFIX,
  BRAND_LS_EXPIRATION_SUFFIX,
  CONTENT_TYPE_JSON,
  CONSUMER_HEADER_VALUE,
  CURRENT_YEAR,
} = constants;

const loadBrandSuccess = createAction(LOAD_BRAND_SUCCESS);
const loadBrandFail = createAction(LOAD_BRAND_FAIL);
const productId = getCallingAppId();
const derivedProductId = productId || DEFAULT;

const organizationId = productId ? getOrganizationIdForBranding() : '';

const orgIdLSName = organizationId || DEFAULT;

let brandUrl = organizationId ?
  `${GET_BRAND_ENDPOINT}?callingAppId=${derivedProductId}&organizationId=${organizationId}` :
  `${GET_BRAND_ENDPOINT}?callingAppId=${derivedProductId}`;

const callLoadBrandService = (updatedBrandUrl, ajax) => ajax({
  url: updatedBrandUrl,
  headers: { 'Content-Type': CONTENT_TYPE_JSON, consumer: CONSUMER_HEADER_VALUE },
  method: 'get',
}).pipe(
  map(response => handleResponse({ response })),
  catchError(err => of(loadBrandFail(getErrorMessage(err)))),
);

const handleResponse = (response) => {
  const { brands } = response.response.response;

  storeBrandingData({ brands });
  return loadBrandSuccess(brands);
};

const lsName = `${BRAND_LS_PREFIX + derivedProductId}_${orgIdLSName}`;

// eslint-disable-next-line max-len
const brandsExpiryTime = isLocalStorageSupported(true) ?
  localStorage.getItem(lsName + BRAND_LS_EXPIRATION_SUFFIX) :
  '0';

export const loadBrandEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(LOAD_BRAND_REQUEST),
  switchMap(() => {
    const brands = lscache.get(lsName);
    const mobileDevice = state$.value.login.isMobileDevice;

    if (mobileDevice) brandUrl += `&isMobile=${mobileDevice}`;
    return brands && !isExpired() ?
      of(loadBrandSuccess(brands)) :
      callLoadBrandService(brandUrl, ajax);
  }),
);

const currentTime = () => Math.floor(new Date().getTime() / (60 * 1000));

const resetLocalStorage = () => {
  const expiryPeriod = window.env ?
    window.env.LS_EXPIRY_PERIOD_IN_MINUTES :
    LS_EXPIRY_PERIOD_IN_MINUTES_DEFAULT;

  const expirationTime = () => currentTime() + parseInt(expiryPeriod, 10);

  isLocalStorageSupported(false) && localStorage.removeItem(lsName + BRAND_LS_EXPIRATION_SUFFIX);
  // eslint-disable-next-line max-len
  isLocalStorageSupported(false) &&
    localStorage.setItem(lsName + BRAND_LS_EXPIRATION_SUFFIX, expirationTime());

  return '';
};

const isExpired = () => {
  const brandExpiry = parseInt(brandsExpiryTime, 10);

  return currentTime() >= brandExpiry;
};

const storeBrandingData = (brands) => {
  of(concat(lscache.set(lsName, brands.brands), resetLocalStorage()));
};

export const loadBrandFailEpic = action$ => action$.pipe(
  ofType(LOAD_BRAND_FAIL),
  // eslint-disable-next-line max-len
  switchMap(() => {
    const envSuffix =
        window.env && window.env.LOCAL_BRANDING_ENV_FILE_SUFFIX ?
          window.env.LOCAL_BRANDING_ENV_FILE_SUFFIX :
          LOCAL_BRANDING_ENV_FILE_SUFFIX_DEFAULT;

    // eslint-disable-next-line import/no-dynamic-require
    const localBrandData = require(`../../../../../local-branding/default-branding-${envSuffix}.json`);

    const localBrandObj = findAndReplaceText(
      localBrandData,
      CURRENT_YEAR,
      new Date().getFullYear(),
    );

    return of(loadBrandSuccess(lscache.get(lsName) ? lscache.get(lsName) : localBrandObj.brands));
  }),
);
