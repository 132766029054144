import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { SdfButton, SdfSpotIllustration, SdfTooltip } from '@synerg/react-components';

const MobileListForm = (props) => {
  const { channelListContainerProps, sendingRequest, intl } = props;

  const {
    secondaryButton,
    primaryButton,
    tertiaryButton,
    subheadline,
    subtitle1,
    toolTipFlag,
    fidoDeviceActivate,
    title,
  } = channelListContainerProps;

  return (
    <div>
      <div id="fidoDeviceLogin">
        { fidoDeviceActivate && (
          <div className="text-center pb-4 hidden md:block">
            <SdfSpotIllustration
              illustrationName="face-ID"
              size="lg"
            />
            <SdfSpotIllustration
              illustrationName="lock"
              size="lg"
              class="ml-8"
            />
            <SdfSpotIllustration
              illustrationName="touch-ID"
              size="lg"
              class="ml-8"
            />
          </div>
        ) }
        { title && (
          <div className="text-2xl font-bold black pb-4">
            <FormattedMessage
              id={ title.id }
              defaultMessage={ title.defaultMessage }
              description={ title.description }
            />
          </div>
        ) }
        { subtitle1 && (
          <div
            id="fido_device_subtitle"
            className="text-md md:text-lg mr-0 md:mr-8 mb-4"
          >
            <span className="black">
              <FormattedMessage
                id={ subtitle1.id }
                defaultMessage={ subtitle1.defaultMessage }
                description={ subtitle1.description }
              />
            </span>
            { toolTipFlag && (
              <>
                <SdfTooltip
                  className="ml-1 text-lg"
                  id="passkeytooltip"
                  trigger="click"
                  attachment-point="auto-start"
                  position-context="element"
                >
                  <SdfButton
                    id="passkeytooltipBtnId"
                    slot="tooltip-target"
                    className="ml-1 text-lg"
                    icon="action-help"
                    variant="text"
                    class="text-action-primary"
                  />
                  { intl.formatMessage({
                    id: 'mobile.passcodeSent',
                    defaultMessage:
                      "We can text you a single-use numeric passcode to your mobile phone. Enter this code instead of your password and you're ready to go! You'll still be able to enter your current password whenever you want.",
                    description: 'passcode to mobile',
                  }) }
                </SdfTooltip>
              </>
            ) }
          </div>
        ) }
        { subheadline && (
          <FormattedMessage
            id={ subheadline.id }
            defaultMessage={ subheadline.defaultMessage }
            description={ subheadline.description }
          />
        ) }
      </div>
      <div
        id="fido_passowrdless_div"
        className="flex md:flex-end text-center md:flex-row-reverse flex-col mb-2 items-center mt-20 md:mt-0"
      >
        { secondaryButton && (
          <SdfButton
            id="mobilelist_secondaryBtn"
            action="standard"
            className="w-full max-w-xs md:min-w-32 md:w-auto md:ml-2"
            onClick={ () => {
              secondaryButton.clickFunction();
            } }
            emphasis="secondary"
          >
            <FormattedMessage
              id={ secondaryButton.intl.id }
              defaultMessage={ secondaryButton.intl.defaultMessage }
              description={ secondaryButton.intl.description }
            />
          </SdfButton>
        ) }
        { primaryButton && (
          <SdfButton
            id="mobilelist_primaryBtn"
            className="w-full max-w-xs md:min-w-32 md:w-auto mt-4 md:mt-0"
            emphasis="primary"
            disabled={ sendingRequest || undefined }
            onClick={ () => {
              primaryButton.clickFunction();
            } }
            onKeyPress={ (event) => {
              if (event.charCode === 13) {
                event.preventDefault();
                primaryButton.clickFunction();
              }
            } }
            action="standard"
          >
            <FormattedMessage
              id={ primaryButton.intl.id }
              defaultMessage={ primaryButton.intl.defaultMessage }
              description={ primaryButton.intl.description }
            />
          </SdfButton>
        ) }
      </div>
      { tertiaryButton && (
        <SdfButton
          id="fidoLinkButton"
          class="w-full mt-6 hidden"
          size="lg"
          emphasis="tertiary"
          onClick={ () => {
            tertiaryButton.clickFunction();
          } }
          action="standard"
        >
          <FormattedMessage
            id={ tertiaryButton.intl.id }
            defaultMessage={ tertiaryButton.intl.defaultMessage }
            description={ tertiaryButton.intl.description }
          />
        </SdfButton>
      ) }
    </div>
  );
};

MobileListForm.propTypes = {
  channelListContainerProps: PropTypes.shape({
    secondaryButton: PropTypes.object,
    primaryButton: PropTypes.object,
    tertiaryButton: PropTypes.object,
    subheadline: PropTypes.object,
    toolTipFlag: PropTypes.bool,
    title: PropTypes.object,
  }),
  sendingRequest: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};

MobileListForm.contextTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(MobileListForm);
