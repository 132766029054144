import { handleActions } from 'redux-actions';
import { types } from './synergy-actions';

const { LOAD_SYNERGY_COMPONENTS_SUCCESS, LOAD_SYNERGY_COMPONENTS_FAIL } = types;

export const initialState = {};

export default handleActions(
  {
    [LOAD_SYNERGY_COMPONENTS_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [LOAD_SYNERGY_COMPONENTS_FAIL]: (state, { payload }) => ({ ...state, ...payload }),
  },
  initialState,
);
