/* eslint-disable no-useless-escape */
export function validateTargetURLDomain(targetParam) {
  const WHITELIST_DOMAIN_URL = ['.adp.com', '.oneadp.com'];

  try {
    if (targetParam === '/local_success.html') return targetParam;
    const targetVal = targetParam.match(
      /^(([^:\/?#]+:)?(?:\/\/((?:([^\/?#:]*):([^\/?#:]*)@)?([^\/?#:]*)(?::([^\/?#:]*))?)))?([^?#]*)(\?[^#]*)?(#.*)?$/,
    );

    const urlParams = {
      hash: targetVal[10] || '',
      host: targetVal[3] || '',
      hostname: targetVal[6] || '',
      href: targetVal[0] || '',
      origin: targetVal[1] || '',
      pathname: targetVal[8] || (targetVal[1] ? '/' : ''),
    };

    const validDomain = WHITELIST_DOMAIN_URL.some(subStr => urlParams.hostname.endsWith(subStr));

    if (validDomain) return targetParam;
    return null;
  } catch (err) {
    return null;
  }
}

export function validateReturnURLDomain(returnParam) {
  const WHITELIST_URL_ARRAY = window.env && window.env.WHITELIST_DOMAIN_URL.split(',');

  try {
    const returnVal = returnParam.match(
      /^(([^:\/?#]+:)?(?:\/\/((?:([^\/?#:]*):([^\/?#:]*)@)?([^\/?#:]*)(?::([^\/?#:]*))?)))?([^?#]*)(\?[^#]*)?(#.*)?$/,
    );

    const urlParams = {
      hash: returnVal[10] || '',
      host: returnVal[3] || '',
      hostname: returnVal[6] || '',
      href: returnVal[0] || '',
      origin: returnVal[1] || '',
      pathname: returnVal[8] || (returnVal[1] ? '/' : ''),
    };

    const validDomain = WHITELIST_URL_ARRAY.some(subStr => urlParams.hostname.endsWith(subStr));

    if (validDomain) return returnParam;
    return null;
  } catch (err) {
    return null;
  }
}
