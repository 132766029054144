import Cookies from 'js-cookie';
import {
  COOKIE_EXPIRY_DEFAULT, DOMAIN, PATH, SECURE,
} from '.';

// eslint-disable-next-line max-len
export const setCookie = (
  cookieName,
  token,
  secure = SECURE,
  expires = COOKIE_EXPIRY_DEFAULT,
  domain = DOMAIN,
  path = PATH,
) => {
  Cookies.set(cookieName, token, {
    expires,
    domain,
    path,
    secure,
  });
};

export const setHtmlLang = (locale) => {
  const htmlLangAttribute = locale.replace('_', '-');

  document.querySelector('html').setAttribute('lang', htmlLangAttribute);
};

export const getCookie = cookieName => Cookies.get(cookieName, {
  domain: DOMAIN,
  path: PATH,
  secure: SECURE,
});

export const removeCookie = cookieName => Cookies.remove(cookieName, {
  domain: DOMAIN,
  path: PATH,
  secure: SECURE,
});
