import { handleActions } from 'redux-actions';
import { types } from './terms-and-conditions-actions';

const {
  TERMS_AND_CONDITIONS_ACCEPT_SUCCESS,
  TERMS_AND_CONDITIONS_ACCEPT_FAIL,
  TERMS_AND_CONDITIONS_CANCEL,
  DISPLAY_TERMS_AND_CONDITIONS,
} = types;

export const initialState = {
  errorType: '',
};

const navigateToHome = (state, payload) => ({
  ...state,
  validationError: '',
  changePasswordError: '',
});

const setSuccessState = (state, payload, error) => ({
  ...state,
  ...payload,
  changePasswordError: error || '',
  validationError: '',
  clearPasswordAlerts: false,
});

export default handleActions(
  {
    // prettier-ignore
    [TERMS_AND_CONDITIONS_ACCEPT_SUCCESS]: (state, { payload }) => setSuccessState(
      state, {
        ...payload,
      },
      payload.reason,
    ),
    [TERMS_AND_CONDITIONS_ACCEPT_FAIL]: (state, { payload }) => ({ ...state, ...payload }),
    [DISPLAY_TERMS_AND_CONDITIONS]: (state, { payload }) => ({ ...state, ...payload }),
    [TERMS_AND_CONDITIONS_CANCEL]: (state, { payload }) => navigateToHome(state, payload),
  },
  initialState,
);
