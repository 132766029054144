import { handleActions } from 'redux-actions';
import { types } from './locale-actions';
import { DEFAULT_LOCALE } from '../../../util/locale';

const { CHANGE_LOCALE, LOCALE_MODE } = types;

export const initialState = {
  locale: DEFAULT_LOCALE,
  defaultLocaleMode: true,
};

export default handleActions(
  {
    [CHANGE_LOCALE]: (state, { payload }) => ({ ...state, ...payload }),
    [LOCALE_MODE]: (state, { payload }) => ({ ...state, ...payload }),
  },
  initialState,
);
