import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { createAction } from 'redux-actions';
import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import { types } from '..';
import {
  CHANNEL_COLLECTION,
  MOBILE_COLLECTION,
  EMAIL_COLLECTION,
  LANDLINE_COLLECTION,
  VOICE_BIOMETRICS_COLLECTION,
} from '../../../../routes/router-url-constants';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import {
  getAjaxJsonHeader,
  setDeviceTokenForRequiredTypes,
  getUrlLocationSearch,
  getParamFromResponseHeader,
  isPeriodicPrompt,
} from '../../../../util/misc';
import { constants } from '../../login/login-constants';
import { types as loginType } from '../../login/login-actions';
import { types as tcType } from '../../terms-and-conditions/terms-and-conditions-actions';
import { OTP_HEADER } from '../../../../util/misc/constants';
import { constants as channelCollectionConstants } from '../../../../components/channel-collection/channel-collection-constants';

const { PERIODIC_PROMPT } = channelCollectionConstants;

const { AUTHENTICATE_ENDPOINT } = constants;
const {
  SAVE_COMMUNICATION_REQUEST,
  SAVE_COMMUNICATION_FAIL,
  SAVE_COMMUNICATION_SUCCESS,
  SUBMIT_PASSCODE_REQUEST,
  SUBMIT_PASSCODE_FAIL,
  SUBMIT_PASSCODE_SUCCESS,
  RESEND_PASSCODE_REQUEST,
  RESEND_PASSCODE_FAIL,
  RESEND_PASSCODE_SUCCESS,
  CANCEL_PASSCODE_COLLECTION_REQUEST,
  CANCEL_PASSCODE_COLLECTION_FAIL,
  DISPLAY_CHANNEL_COLLECTION,
  DISPLAY_MOBILE_COLLECTION,
  DISPLAY_EMAIL_COLLECTION,
  DISPLAY_LANDLINE_COLLECTION,
  DISPLAY_VOICE_BIOMETRICS_COLLECTION,
  SKIP_CHANNEL_COLLECTION_REQUEST,
  SKIP_CHANNEL_COLLECTION_FAIL,
  OPTOUT_CHANNEL_COLLECTION_REQUEST,
  OPTOUT_CHANNEL_COLLECTION_FAIL,
  ACKNOWLEDGE_SUCCESS_REQUEST,
  LANDLINE_ACKNOWLEDGED_REQUEST,
  ACKNOWLEDGE_SUCCESS_FAIL,
  LANDLINE_ACKNOWLEDGED_FAIL,
  EMAIL_ACTIVATION_ACKNOWLEDGED_REQUEST,
  EMAIL_ACTIVATION_ACKNOWLEDGED_FAIL,
  VOICE_BIOMETRICS_ACKNOWLEDGED_REQUEST,
  VOICE_BIOMETRICS_ACKNOWLEDGED_FAIL,
} = types;

const { AUTHENTICATE_SUCCESS } = loginType;
const { DISPLAY_TERMS_AND_CONDITIONS } = tcType;

const saveCommunicationFail = createAction(SAVE_COMMUNICATION_FAIL);
const saveCommunicationSuccess = createAction(SAVE_COMMUNICATION_SUCCESS);

const submitPasscodeFail = createAction(SUBMIT_PASSCODE_FAIL);
const submitPasscodeSuccess = createAction(SUBMIT_PASSCODE_SUCCESS);

const resendPasscodeFail = createAction(RESEND_PASSCODE_FAIL);
const resendPasscodeSuccess = createAction(RESEND_PASSCODE_SUCCESS);

const cancelPasscodeCollectionfail = createAction(CANCEL_PASSCODE_COLLECTION_FAIL);
const skipCommunicationCollectionFail = createAction(SKIP_CHANNEL_COLLECTION_FAIL);
const optOutCommunicationCollectionFail = createAction(OPTOUT_CHANNEL_COLLECTION_FAIL);
const acknowledgeSuccessFail = createAction(ACKNOWLEDGE_SUCCESS_FAIL);
const landlineAcknowledgedFail = createAction(LANDLINE_ACKNOWLEDGED_FAIL);
const emailActivationAcknowledgedFail = createAction(EMAIL_ACTIVATION_ACKNOWLEDGED_FAIL);
const voiceBioMetricsAcknowledgedFail = createAction(VOICE_BIOMETRICS_ACKNOWLEDGED_FAIL);
const authenticateSuccess = createAction(AUTHENTICATE_SUCCESS);
const tcDisplay = createAction(DISPLAY_TERMS_AND_CONDITIONS);

const COMMUNICATIONS_COLLECTED = 'COMMUNICATIONS_COLLECTED';
const USER_CONSENTED = 'USER_CONSENTED';
const CONSENT_COLLECTION_CHALLENGE = 'CONSENT_COLLECTION_CHALLENGE';
const SAVE_COMMUNICATION_RESPONSE_TYPE = 'COMMUNICATION_COLLECTION_RESPONSE';
const COMMUNICATION_COLLECTION_FAILED = 'COMMUNICATION_COLLECTION_FAILED';
const TYPE_SIGNED_IN = 'SIGNED_IN';
const VALIDATION_ERROR = 'VALIDATION_ERROR';
const SESSION_EXPIRED = 'SESSION_EXPIRED';
const MOBILE_VERIFICATION_RESPONSE = 'MOBILE_VERIFICATION_RESPONSE';
const SUCCESS_ACKNOWLEDGED = 'SUCCESS_ACKNOWLEDGED';
const EMAIL_ACTIVATION_ACKNOWLEDGED = 'EMAIL_ACTIVATION_ACKNOWLEDGED';
const VOICE_BIOMETRICS_ACKNOWLEDGED = 'VOICE_BIOMETRICS_ACKNOWLEDGED';
const LANDLINE_COLLECTION_ACKNOWLEDGED = 'LANDLINE_COLLECTION_ACKNOWLEDGED';
const MOBILE_NOT_VERIFIED = 'MOBILE_NOT_VERIFIED';
const COMMUNICATION_VERIFICATION_CHALLENGE = 'COMMUNICATION_VERIFICATION_CHALLENGE';
const VOICE_BIOMETRICS_ACKNOWLEDGEMENT_CHALLENGE = 'VOICE_BIOMETRICS_ACKNOWLEDGEMENT_CHALLENGE';
const SUCCESS_ACKNOWLEDGEMENT_CHALLENGE = 'SUCCESS_ACKNOWLEDGEMENT_CHALLENGE';
const USER_INITIATED_OTP_CANCEL = 'USER_INITIATED_OTP_CANCEL';
const EMAIL_ACTIVATION_REQUIRED_CHALLENGE = 'EMAIL_ACTIVATION_REQUIRED_CHALLENGE';
const INVALID_EMAIL = 'invalid email';
const TYPE_FIDO_SIGNED_IN = 'FIDO_AUTHENTICATION_RESULT';

const handleResponse = (responseObj) => {
  const { result, challenge, session } = responseObj.response;
  const { accessToken, redirectUrl, user } = result;

  const { givenName } = user || '';
  const otp = getParamFromResponseHeader(responseObj, OTP_HEADER);

  setDeviceTokenForRequiredTypes(responseObj);

  if (accessToken && (result.type === TYPE_SIGNED_IN || result.type === TYPE_FIDO_SIGNED_IN)) {
    return authenticateSuccess({
      accessToken,
      redirectUrl,
      givenName,
    });
  }

  if (challenge && challenge.type === CONSENT_COLLECTION_CHALLENGE) {
    return tcDisplay({
      tcSession: session,
      tcChallenge: challenge,
    });
  }
  if (
    responseObj &&
    responseObj.response &&
    result.type === COMMUNICATIONS_COLLECTED &&
    challenge.type === COMMUNICATION_VERIFICATION_CHALLENGE
  ) {
    return saveCommunicationSuccess({
      channelCollectionPassed: true,
      passcode: otp,
      channelCollectionSession: session,
      formattedChannelInfo: challenge && challenge.authenticator,
    });
  }

  if (
    responseObj &&
    responseObj.response &&
    result.type === USER_CONSENTED &&
    challenge.type === VOICE_BIOMETRICS_ACKNOWLEDGEMENT_CHALLENGE
  ) {
    return saveCommunicationSuccess({
      channelCollectionPassed: true,
      isVoiceBio: true,
      channelCollectionSession: session,
    });
  }

  return cancelPasscodeCollectionfail({
    channelCollectionFailed: true,
    errorMessage: '',
  });
};

const acknowledgeSuccessResponse = (responseObj) => {
  const { result, challenge, session } = responseObj.response;
  const { accessToken, redirectUrl, user } = result;

  const { givenName } = user || '';

  setDeviceTokenForRequiredTypes(responseObj);

  if (accessToken && (result.type === TYPE_SIGNED_IN || result.type === TYPE_FIDO_SIGNED_IN)) {
    return authenticateSuccess({
      accessToken,
      redirectUrl,
      givenName,
    });
  }
  if (challenge && challenge.type === CONSENT_COLLECTION_CHALLENGE) {
    return tcDisplay({
      tcSession: session,
      tcChallenge: challenge,
    });
  }
  return null;
};

const handleSkipChannelCollectionResponse = responseObj => acknowledgeSuccessResponse(responseObj);

const handlesubmitPasscodeResponse = (responseObj) => {
  setDeviceTokenForRequiredTypes(responseObj);

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.result &&
    responseObj.response.challenge
  ) {
    const { result, challenge, session } = responseObj.response;
    const { type } = result;

    const challengeType = challenge && challenge.type;

    if (challengeType === EMAIL_ACTIVATION_REQUIRED_CHALLENGE) {
      return submitPasscodeSuccess({
        mobileVerifiedForEmail: true,
        channelCollectionSession: session,
        expireTime: challenge.expireTime ? challenge.expireTime : 96,
      });
    }

    if (challengeType === SUCCESS_ACKNOWLEDGEMENT_CHALLENGE) {
      return submitPasscodeSuccess({
        mobileVerified: true,
        channelCollectionSession: session,
      });
    }
    if (type === MOBILE_NOT_VERIFIED) {
      if (challenge.otpRetryRemainingAttempts === 1) {
        return submitPasscodeFail({
          otpRetryRemainingAttempts: challenge.otpRetryRemainingAttempts,
          passcodeFailErrorMessage: 'channelCollection_second_error_message',
          channelCollectionSession: session,
        });
      }
      return submitPasscodeFail({
        otpRetryRemainingAttempts: challenge.otpRetryRemainingAttempts,
        passcodeFailErrorMessage: 'channelCollection_first_error_message',
        channelCollectionSession: session,
      });
    }
  }
  return null;
};

const handleResendPasscodeResponse = (responseObj) => {
  setDeviceTokenForRequiredTypes(responseObj);
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.result &&
    responseObj.response.challenge
  ) {
    const { result, challenge, session } = responseObj.response;
    const { type } = result;
    const otp = getParamFromResponseHeader(responseObj, OTP_HEADER);

    if (type === MOBILE_NOT_VERIFIED && challenge.otpResendRemainingAttempts === 0) {
      return resendPasscodeSuccess({
        otpRetryRemainingAttempts: challenge.otpRetryRemainingAttempts,
        passcode: otp,
        channelCollectionSession: session,
      });
    }
  }
  return null;
};

// eslint-disable-next-line max-len
const skipChannelCollectionRequest = (remindMeLater, channelCollectionSession) => ({
  response: {
    type: SAVE_COMMUNICATION_RESPONSE_TYPE,
    skipCommunicationCollection: !remindMeLater,
    cancelOtpVerification: false,
    remindMeLater,
    optOut: false,
    addedCommunications: [],
    changedCommunications: [],
    removedCommunications: [],
  },
  session: channelCollectionSession,
});

// eslint-disable-next-line max-len
const optOutChannelCollectionRequest = (optOutFlag, channelCollectionSession) => ({
  response: {
    type: SAVE_COMMUNICATION_RESPONSE_TYPE,
    skipCommunicationCollection: false,
    cancelOtpVerification: false,
    remindMeLater: false,
    optOut: optOutFlag,
    addedCommunications: [],
    changedCommunications: [],
    removedCommunications: [],
  },
  session: channelCollectionSession,
});

const acknowledgeSuccessRequest = channelCollectionSession => ({
  response: {
    type: SUCCESS_ACKNOWLEDGED,
  },
  session: channelCollectionSession,
});

const landlineAcknowledgedRequest = channelCollectionSession => ({
  response: {
    type: LANDLINE_COLLECTION_ACKNOWLEDGED,
  },
  session: channelCollectionSession,
});

const emailActivationAcknowledgedRequest = channelCollectionSession => ({
  response: {
    type: EMAIL_ACTIVATION_ACKNOWLEDGED,
  },
  session: channelCollectionSession,
});

const voiceBioMetricsAcknowledgedRequest = channelCollectionSession => ({
  response: {
    type: VOICE_BIOMETRICS_ACKNOWLEDGED,
  },
  session: channelCollectionSession,
});

const saveCommunicationRequest = (
  addedCommunications,
  changedCommunications,
  channelCollectionSession,
  channelCollectionChallenge,
) => isPeriodicPrompt(channelCollectionChallenge.communicationCollectionType) ?
  {
    response: {
      type: SAVE_COMMUNICATION_RESPONSE_TYPE,
      skipCommunicationCollection: false,
      cancelOtpVerification: false,
      remindMeLater: false,
      optOut: false,
      addedCommunications,
      changedCommunications,
      removedCommunications: [],
      promptStatus: PERIODIC_PROMPT,
    },
    session: channelCollectionSession,
  } :
  {
    response: {
      type: SAVE_COMMUNICATION_RESPONSE_TYPE,
      skipCommunicationCollection: false,
      cancelOtpVerification: false,
      remindMeLater: false,
      optOut: false,
      addedCommunications,
      changedCommunications,
      removedCommunications: [],
    },
    session: channelCollectionSession,
  };

// eslint-disable-next-line max-len
const submitPasscodeRequest = (passcode, channelCollectionSession, channelCollectionChallenge) => isPeriodicPrompt(channelCollectionChallenge.communicationCollectionType) ?
  {
    response: {
      type: MOBILE_VERIFICATION_RESPONSE,
      otp: passcode,
      resendOtp: false,
      changeAuthenticator: false,
      promptStatus: PERIODIC_PROMPT,
    },
    session: channelCollectionSession,
  } :
  {
    response: {
      type: MOBILE_VERIFICATION_RESPONSE,
      otp: passcode,
      resendOtp: false,
      changeAuthenticator: false,
    },
    session: channelCollectionSession,
  };

const resendPasscodeRequest = channelCollectionSession => ({
  response: {
    type: MOBILE_VERIFICATION_RESPONSE,
    resendOtp: true,
    changeAuthenticator: false,
  },
  session: channelCollectionSession,
});

const cancelPasscodeCollectionRequest = channelCollectionSession => ({
  response: {
    type: SAVE_COMMUNICATION_RESPONSE_TYPE,
    skipCommunicationCollection: false,
    cancelOtpVerification: true,
    remindMeLater: false,
    optOut: false,
    addedCommunications: [],
    changedCommunications: [],
    removedCommunications: [],
  },
  session: channelCollectionSession,
});

// eslint-disable-next-line max-len
const saveCommunicationService = (
  ajax,
  addedCommunications,
  changedCommunications,
  channelCollectionSession,
  channelCollectionChallenge,
) => ajax({
  url: `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(
    saveCommunicationRequest(
      addedCommunications,
      changedCommunications,
      channelCollectionSession,
      channelCollectionChallenge,
    ),
  ),
}).pipe(
  map(response => handleResponse(response)),
  catchError((err) => {
    if (err && err.response && err.response.code === COMMUNICATION_COLLECTION_FAILED) {
      return of(saveCommunicationFail({ channelCollectionFailed: true }));
    }
    if (
      err &&
        err.response &&
        err.response.code === VALIDATION_ERROR &&
        err.response.fieldErrors
    ) {
      const validationErrors = err.response.fieldErrors;
      const isInvalidEmail =
          validationErrors && validationErrors.some(error => error.message === INVALID_EMAIL);

      if (isInvalidEmail) {
        return of(
          saveCommunicationFail({
            validationErrors,
            palErrorMessage: 'channelcollection_invalid_email_address_label',
          }),
        );
      }
      return of(
        saveCommunicationFail({
          validationErrors,
          palErrorMessage: 'channelcollection_invalid_phone_number_label',
        }),
      );
    }
    if (err && err.response && err.response.code === SESSION_EXPIRED) {
      return of(saveCommunicationFail({ errorMessage: getErrorMessage(err) }));
    }

    return of(saveCommunicationFail({ channelCollectionFailed: true }));
  }),
);

const skipChannelCollectionService = (ajax, remindMelater, channelCollectionSession) => ajax({
  url: `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(skipChannelCollectionRequest(remindMelater, channelCollectionSession)),
}).pipe(
  map(response => handleSkipChannelCollectionResponse(response)),
  catchError(err => of(skipCommunicationCollectionFail({ errorMessage: getErrorMessage(err) }))),
);

const optOutChannelCollectionService = (ajax, optOutFlag, channelCollectionSession) => ajax({
  url: `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(optOutChannelCollectionRequest(optOutFlag, channelCollectionSession)),
}).pipe(
  map(response => handleSkipChannelCollectionResponse(response)),
  catchError(err => of(optOutCommunicationCollectionFail({ errorMessage: getErrorMessage(err) }))),
);

const acknowledgeSuccessService = (ajax, channelCollectionSession) => ajax({
  url: `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(acknowledgeSuccessRequest(channelCollectionSession)),
}).pipe(
  map(response => acknowledgeSuccessResponse(response)),
  catchError(err => of(acknowledgeSuccessFail({ errorMessage: getErrorMessage(err) }))),
);

const landlineAcknowledgedService = (ajax, channelCollectionSession) => ajax({
  url: `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(landlineAcknowledgedRequest(channelCollectionSession)),
}).pipe(
  map(response => acknowledgeSuccessResponse(response)),
  catchError(err => of(landlineAcknowledgedFail({ errorMessage: getErrorMessage(err) }))),
);

const emailActivationAcknowledgedService = (ajax, channelCollectionSession) => ajax({
  url: `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(emailActivationAcknowledgedRequest(channelCollectionSession)),
}).pipe(
  map(response => acknowledgeSuccessResponse(response)),
  catchError(err => of(emailActivationAcknowledgedFail({ errorMessage: getErrorMessage(err) }))),
);

const voiceBioMetricsAcknowledgedService = (ajax, channelCollectionSession) => ajax({
  url: `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(voiceBioMetricsAcknowledgedRequest(channelCollectionSession)),
}).pipe(
  map(response => acknowledgeSuccessResponse(response)),
  catchError(err => of(voiceBioMetricsAcknowledgedFail({ errorMessage: getErrorMessage(err) }))),
);

const submitPasscodeService = (
  ajax,
  passcode,
  channelCollectionSession,
  channelCollectionChallenge,
) => ajax({
  url: `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(
    submitPasscodeRequest(passcode, channelCollectionSession, channelCollectionChallenge),
  ),
}).pipe(
  map(response => handlesubmitPasscodeResponse(response)),
  catchError((err) => {
    if (err && err.response && err.response.code === SESSION_EXPIRED) {
      return of(submitPasscodeFail({ errorMessage: getErrorMessage(err) }));
    }
    return of(
      submitPasscodeFail({
        channelCollectionFailed: true,
      }),
    );
  }),
);

const resendPasscodeService = (ajax, channelCollectionSession) => ajax({
  url: `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(resendPasscodeRequest(channelCollectionSession)),
}).pipe(
  map(response => handleResendPasscodeResponse(response)),
  catchError((err) => {
    if (err && err.response && err.response.code === SESSION_EXPIRED) {
      return of(resendPasscodeFail({ errorMessage: getErrorMessage(err) }));
    }
    return of(resendPasscodeFail({ channelCollectionFailed: true }));
  }),
);

const cancelPasscodeCollectionService = (ajax, channelCollectionSession) => ajax({
  url: `${AUTHENTICATE_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(cancelPasscodeCollectionRequest(channelCollectionSession)),
}).pipe(
  catchError((err) => {
    if (err && err.response && err.response.code === USER_INITIATED_OTP_CANCEL) {
      return of(
        cancelPasscodeCollectionfail({
          channelCollectionFailed: true,
          errorMessage: '',
        }),
      );
    }
    return of(cancelPasscodeCollectionfail({ errorMessage: getErrorMessage(err) }));
  }),
);

export const saveCommunicationEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(SAVE_COMMUNICATION_REQUEST),
  switchMap(
    // eslint-disable-next-line max-len
    ({ payload: { addedCommunications, changedCommunications } }) => {
      const {
        channelCollectionSession,
        channelCollectionChallenge,
      } = state$.value.channelCollection;

      return sendingAsyncRequest(
        saveCommunicationService(
          ajax,
          addedCommunications,
          changedCommunications,
          channelCollectionSession,
          channelCollectionChallenge,
        ),
      );
    },
  ),
);

export const displayChannelCollectionEpic = action$ => action$.pipe(
  ofType(DISPLAY_CHANNEL_COLLECTION),
  switchMap(() => of(push(CHANNEL_COLLECTION + getUrlLocationSearch()))),
);

export const displayMobileCollectionEpic = action$ => action$.pipe(
  ofType(DISPLAY_MOBILE_COLLECTION),
  switchMap(() => of(push(MOBILE_COLLECTION + getUrlLocationSearch()))),
);

export const displayLandlineCollectionEpic = action$ => action$.pipe(
  ofType(DISPLAY_LANDLINE_COLLECTION),
  switchMap(() => of(push(LANDLINE_COLLECTION + getUrlLocationSearch()))),
);

export const displayVoiceBioMetricsCollectionEpic = action$ => action$.pipe(
  ofType(DISPLAY_VOICE_BIOMETRICS_COLLECTION),
  switchMap(() => of(push(VOICE_BIOMETRICS_COLLECTION + getUrlLocationSearch()))),
);

export const displayEmailCollectionEpic = action$ => action$.pipe(
  ofType(DISPLAY_EMAIL_COLLECTION),
  switchMap(() => of(push(EMAIL_COLLECTION + getUrlLocationSearch()))),
);

export const skipChannelCollectionEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(SKIP_CHANNEL_COLLECTION_REQUEST),
  switchMap(({ payload: { remindMeLater } }) => {
    const { channelCollectionSession } = state$.value.channelCollection;

    return sendingAsyncRequest(
      // eslint-disable-next-line max-len
      skipChannelCollectionService(ajax, remindMeLater, channelCollectionSession),
    );
  }),
);

export const optOutChannelCollectionEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(OPTOUT_CHANNEL_COLLECTION_REQUEST),
  switchMap(({ payload: { optOutFlag } }) => {
    const { channelCollectionSession } = state$.value.channelCollection;

    return sendingAsyncRequest(
      // eslint-disable-next-line max-len
      optOutChannelCollectionService(ajax, optOutFlag, channelCollectionSession),
    );
  }),
);

export const acknowledgeSuccessEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(ACKNOWLEDGE_SUCCESS_REQUEST),
  switchMap(() => {
    const { channelCollectionSession } = state$.value.channelCollection;

    return sendingAsyncRequest(
      // eslint-disable-next-line max-len
      acknowledgeSuccessService(ajax, channelCollectionSession),
    );
  }),
);

export const landlineAcknowledgedEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(LANDLINE_ACKNOWLEDGED_REQUEST),
  switchMap(() => {
    const { channelCollectionSession } = state$.value.channelCollection;

    return sendingAsyncRequest(
      // eslint-disable-next-line max-len
      landlineAcknowledgedService(ajax, channelCollectionSession),
    );
  }),
);

export const emailActivationAcknowledgedEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(EMAIL_ACTIVATION_ACKNOWLEDGED_REQUEST),
  switchMap(() => {
    const { channelCollectionSession } = state$.value.channelCollection;

    return sendingAsyncRequest(
      // eslint-disable-next-line max-len
      emailActivationAcknowledgedService(ajax, channelCollectionSession),
    );
  }),
);

export const voiceBioMetricsAcknowledgedEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(VOICE_BIOMETRICS_ACKNOWLEDGED_REQUEST),
  switchMap(() => {
    const { channelCollectionSession } = state$.value.channelCollection;

    return sendingAsyncRequest(
      // eslint-disable-next-line max-len
      voiceBioMetricsAcknowledgedService(ajax, channelCollectionSession),
    );
  }),
);

export const submitPasscodeEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(SUBMIT_PASSCODE_REQUEST),
  switchMap(() => {
    const {
      passcode,
      channelCollectionSession,
      channelCollectionChallenge,
    } = state$.value.channelCollection;

    return sendingAsyncRequest(
      // eslint-disable-next-line max-len
      submitPasscodeService(ajax, passcode, channelCollectionSession, channelCollectionChallenge),
    );
  }),
);

export const resendPasscodeEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(RESEND_PASSCODE_REQUEST),
  switchMap(() => {
    const { channelCollectionSession } = state$.value.channelCollection;

    return sendingAsyncRequest(resendPasscodeService(ajax, channelCollectionSession));
  }),
);

export const cancelPasscodeCollectionEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(CANCEL_PASSCODE_COLLECTION_REQUEST),
  switchMap(() => {
    const { channelCollectionSession } = state$.value.channelCollection;

    return sendingAsyncRequest(cancelPasscodeCollectionService(ajax, channelCollectionSession));
  }),
);
