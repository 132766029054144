import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { types } from '..';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { constants } from '../terms-and-conditions-constants';
import { types as authType } from '../../login/login-actions';
import { getUrlLocationSearch } from '../../../../util/misc';
import { HOME, TERMS_AND_CONDITIONS } from '../../../../routes/router-url-constants';
import { getAjaxJsonHeader, setDeviceTokenForRequiredTypes } from '../../../../util/misc/common';

const {
  TERMS_AND_CONDITIONS_ACCEPT_REQUEST,
  TERMS_AND_CONDITIONS_ACCEPT_FAIL,
  TERMS_AND_CONDITIONS_CANCEL,
  DISPLAY_TERMS_AND_CONDITIONS,
} = types;
const { TERMS_AND_CONDITIONS_ENDPOINT } = constants;
const { AUTHENTICATE_SUCCESS } = authType;

const tcAcceptSuccess = createAction(AUTHENTICATE_SUCCESS);
const tcAcceptFail = createAction(TERMS_AND_CONDITIONS_ACCEPT_FAIL);

const handleResponse = (responseObj) => {
  const { accessToken, redirectUrl } = responseObj.response.result || '';
  const { user } = responseObj.response.result || '';
  const { givenName } = user || '';

  setDeviceTokenForRequiredTypes(responseObj);

  return tcAcceptSuccess({
    accessToken,
    redirectUrl,
    givenName,
  });
};

const submitTCAcceptRequest = (password, tcSession) => ({
  response: {
    type: 'CONSENT_COLLECTION_RESPONSE',
    accepted: true,
  },
  session: tcSession,
});

const tcAcceptService = (ajax, password, tcSession, nextActionUrl) => ajax({
  url: nextActionUrl || `${TERMS_AND_CONDITIONS_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(submitTCAcceptRequest(password, tcSession)),
}).pipe(
  map(response => handleResponse(response)),
  catchError(err => of(tcAcceptFail(getErrorMessage(err)))),
);

// prettier-ignore
export const tcAcceptEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(TERMS_AND_CONDITIONS_ACCEPT_REQUEST),
  switchMap(() => {
    const { password } = state$.value.login;
    const { tcSession, tcChallenge } = state$.value.termsandconditions;

    return sendingAsyncRequest(
    // eslint-disable-next-line max-len
      tcAcceptService(ajax, password, tcSession, tcChallenge && tcChallenge.next),
    );
  }),
);

export const tcCancelEpic = action$ => action$.pipe(
  ofType(TERMS_AND_CONDITIONS_CANCEL),
  switchMap(() => of(push(HOME + getUrlLocationSearch()))),
);

export const tcDisplayEpic = action$ => action$.pipe(
  ofType(DISPLAY_TERMS_AND_CONDITIONS),
  switchMap(() => of(push(TERMS_AND_CONDITIONS + getUrlLocationSearch()))),
);
