import { handleActions } from 'redux-actions';
import { types } from './dynamic-message-actions';

const { LOAD_DYNAMICMESSAGE_SUCCESS, LOAD_DYNAMICMESSAGE_FAIL, SET_DYNAMICMESSAGE } = types;

export const initialState = {
  dynMsg: null,
  dynamicMessageLoaded: false,
};

export default handleActions(
  {
    [LOAD_DYNAMICMESSAGE_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [LOAD_DYNAMICMESSAGE_FAIL]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_DYNAMICMESSAGE]: (state, { payload }) => ({ ...state, ...payload }),
  },
  initialState,
);
