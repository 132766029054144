import { getAppLocale, LOCALE_MAP } from '.';

export const getLocaleObjFromPropKey = (propElement, locale) => {
  const resultObj = Object.keys(propElement).filter(key => key === locale);

  return propElement[resultObj] === undefined ? propElement.default : propElement[resultObj];
};

export const getLocaleImage = (i18nJson, locale) => i18nJson[locale];

// eslint-disable-next-line consistent-return
export const getDefaultLocaleFromPreferences = (preferences, supportedBrandingLanguages) => {
  for (const preference of preferences) {
    let preferenceLang = preference.value && preference.value.replace('-', '_');

    if (preferenceLang && preferenceLang.length === 2) {
      preferenceLang = getAppLocale(preferenceLang);
    }

    // eslint-disable-next-line max-len
    if (
      preferenceLang &&
      preferenceLang in LOCALE_MAP &&
      supportedBrandingLanguages.includes(preferenceLang)
    ) {
      return { key: preference.key, value: preferenceLang };
    }
  }
};
