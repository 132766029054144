import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import CommonAlert from './CommonAlert';
import {
  ALERT_AUTOCLOSE_FALSE,
  ALERT_TYPE_WARNING,
  ALERT_TYPE_ERROR,
  ALERT_TYPE_SUCCESS,
  APPLICATION_ERROR_DEFAULT,
} from '../../layouts/layout-constants';
import { getDefaultErrorMessage } from '../../redux/helpers/epics/error';

const AlertMessage = (props) => {
  const {
    errorMessage,
    changePasswordError,
    passwordStatus,
    passwordUpdateStatus,
    clearAlerts,
    clearPasswordAlerts,
    passwordExpirationAt,
    intl,
    validationError,
  } = props;

  const isBlank = str => (str || '').trim().length === 0;
  const clearAlertswithDefaults = clearAlerts || false;
  const clearPasswordAlertswithDefaults = clearPasswordAlerts || false;

  const populateAlertCode = () => {
    if (!isBlank(errorMessage)) {
      return errorMessage;
    }
    if (!isBlank(changePasswordError)) {
      return changePasswordError;
    }
    if (
      !isBlank(passwordStatus) &&
      passwordStatus !== 'RESET' &&
      !clearPasswordAlertswithDefaults
    ) {
      return passwordStatus;
    }
    if (
      !isBlank(passwordUpdateStatus) &&
      !clearAlertswithDefaults &&
      !clearPasswordAlertswithDefaults
    ) {
      return passwordUpdateStatus;
    }
    return '';
  };

  const getLocalizedAlert = (localizedAlertCode) => {
    if (!isBlank(localizedAlertCode) && passwordStatus === 'ABOUT_TO_EXPIRE') {
      const expiryDays = parseInt(
        (new Date(passwordExpirationAt) - new Date()) / (24 * 3600 * 1000),
        10,
      );
      const expiryDaysExcludeSameday = expiryDays === 0 ? 1 : expiryDays;
      const localeData = intl.formatMessage({
        id: localizedAlertCode,
        defaultMessage: localizedAlertCode,
      });

      return localeData.replace('[X]', expiryDaysExcludeSameday);
    }
    if (localizedAlertCode === getDefaultErrorMessage) {
      return intl.formatMessage({
        id: APPLICATION_ERROR_DEFAULT,
        defaultMessage: localizedAlertCode,
      });
    }
    return intl.formatMessage({
      id: localizedAlertCode,
      defaultMessage: `${localizedAlertCode}[]`,
    });
  };
  const populateAlertType = () => {
    if (
      (!isBlank(passwordStatus) &&
        passwordStatus === 'ABOUT_TO_EXPIRE' &&
        passwordUpdateStatus !== 'PASSWORD_NOT_CHANGED' &&
        validationError !== 'VALIDATON_ERROR') ||
      (!isBlank(validationError) && validationError === 'signin.adpRemember')
    ) {
      return ALERT_TYPE_WARNING;
    }
    if (
      !isBlank(passwordUpdateStatus) &&
      isBlank(passwordStatus) &&
      isBlank(errorMessage) &&
      passwordUpdateStatus === 'PASSWORD_CHANGED'
    ) {
      return ALERT_TYPE_SUCCESS;
    }
    return ALERT_TYPE_ERROR;
  };
  const alertTypeVal = populateAlertType();

  const alertCode = populateAlertCode();

  const localizedAlertContent = !isBlank(alertCode) ? getLocalizedAlert(alertCode) : '';

  return (
    !isBlank(localizedAlertContent) && (
      <CommonAlert
        autoClose={ ALERT_AUTOCLOSE_FALSE }
        alertType={ alertTypeVal }
        content={ localizedAlertContent }
      />
    )
  );
};

AlertMessage.prototype = {
  children: PropTypes.element,
};

AlertMessage.contextTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AlertMessage);
