import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  SdfActionMenu, SdfMenu, SdfIcon, SdfMenuItem,
} from '@synerg/react-components';
import { intlShape } from 'react-intl';
import {
  DEFAULT_OPTIONS_MAP,
  LOCALE_MAP_LIST,
  DEFAULT_LOCALE,
  ADP_LANG_LOCALE_COOKIE,
} from '../../util/locale';
import { isNotBlank, getCookie } from '../../util/misc';

const localeFromBranding = (language) => {
  const localeLanguages = language.options.length > 0 ? language.options : [language.default];
  const localeResult = [];

  // build language list to display based on branding
  localeLanguages.forEach((localeLanguage, i) => {
    const languageLabel = getLanguageLabel(localeLanguage.replace(/[-]/, '_'));

    localeLanguage &&
      languageLabel &&
      localeResult.push({
        value: localeLanguage,
        label: languageLabel,
      });
  });
  return localeResult;
};

// prettier-ignore
const getLanguageLabel = (locale) => {
  const language = LOCALE_MAP_LIST.find(lang => lang.value === locale);

  return language && language.label;
};

const LanguageDropdown = (props) => {
  const {
    locale, changeLocale, language, modeLocale, intl,
  } = props;
  const localeBrandindData = localeFromBranding(language);
  const localeResult = localeBrandindData.length > 0 ? localeBrandindData : DEFAULT_OPTIONS_MAP;
  const getLangLocalCookie = getCookie(ADP_LANG_LOCALE_COOKIE) || language.default;

  const langDefault = isNotBlank(getLanguageLabel(getLangLocalCookie)) ?
    getLanguageLabel(getLangLocalCookie) :
    getLanguageLabel(DEFAULT_LOCALE);

  const [langName, setLangName] = useState(langDefault);
  const [value, setValue] = useState('');

  const onLangChange = (item) => {
    setLangName(item.label);
    setValue(item.value);

    modeLocale(false);
    changeLocale(item.value || locale, true);
  };

  return (
    <div>
      { localeResult.length > 1 ? (
        <>
          <SdfActionMenu
            id="language_dropdown"
            label={ intl.formatMessage({
              id: 'languagedropdown_label',
              defaultMessage: 'Languages',
              description: 'language dropdown label',
            }) }
            emphasis="tertiary"
          >
            <div className="max-h-xs overflow-auto">
              <SdfMenu class="min-w-48">
                { localeResult.map(item => (
                  <SdfMenuItem
                    id="language_dropdown_item"
                    onClick={ () => {
                      onLangChange(item);
                    } }
                    onKeyPress={ (event) => {
                      if (event.charCode === 13) {
                        event.preventDefault();
                        onLangChange(item);
                      }
                    } }
                  >
                    { item.label }
                    { item.label == langName ? (
                      <SdfIcon
                        slot="after"
                        icon="action-confirm"
                        icon-title={ intl.formatMessage({
                          id: 'language_selected_label',
                          defaultMessage: 'Selected option',
                        }) }
                        class="text-action-primary"
                      />
                    ) : (
                      ''
                    ) }
                  </SdfMenuItem>
                )) }
              </SdfMenu>
            </div>
          </SdfActionMenu>
        </>
      ) : (
        ''
      ) }
    </div>
  );
};

LanguageDropdown.propTypes = {
  locale: PropTypes.string,
  changeLocale: PropTypes.func.isRequired,
  modeLocale: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  language: PropTypes.shape({
    default: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
  }),
};

export default LanguageDropdown;
