import { createTypes, asyncAction } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { NEXO_NAMESPACE } from '../../helpers/constants';

export const types = createTypes(
  NEXO_NAMESPACE,
  asyncAction('LOAD_BRAND'),
  'SET_BRANDINGSTYLES',
  'SET_BRANDINGSTYLESLOADED',
);

const loadBrand = createAction(types.LOAD_BRAND_REQUEST);
const setBrandingStyles = createAction(types.SET_BRANDINGSTYLES, brandingStyles => ({
  brandingStyles,
}));
const setBrandingStylesLoaded = createAction(
  types.SET_BRANDINGSTYLESLOADED,
  isBrandingStylesLoaded => ({ isBrandingStylesLoaded }),
);

export const actions = {
  loadBrand,
  setBrandingStyles,
  setBrandingStylesLoaded,
};
