import { createTypes, asyncAction } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { NEXO_NAMESPACE } from '../../helpers/constants';

export const types = createTypes(
  NEXO_NAMESPACE,
  asyncAction('INITIAL_LOAD'),
  asyncAction('LOAD_CSRF'),
);

const initialLoad = createAction(types.INITIAL_LOAD_REQUEST);
const loadCSRF = createAction(types.LOAD_CSRF_REQUEST);

export const actions = {
  initialLoad,
  loadCSRF,
};
