export const constants = {
  CHALLENGE_TYPE_PASSWORD: 'PASSWORD_VERIFICATION_CHALLENGE',
  CHALLENGE_TYPE_PASSKEY: 'PASSKEY_AUTHENTICATOR_CHALLENGE',
  ENTER_KEY_CODE: 13,
  MOUSE_CLICK: 0,
  USER_AGENT_FILTER: 'IE11',
  STATUS_EXPIRED: 'EXPIRED',
  DYNATRACE_COOKIE_EXPIRY: 10 / 24 / 60, // 10 mins
  STATUS_ABOUT_TO_EXPIRE: 'ABOUT_TO_EXPIRE',
  STATUS_RESET: 'RESET',
  SIGNUP_URL: 'https://netsecuredit.nj.adp.com/pages/sms/ess/v3/pub/ssr/theme.jsp',
  FORGOT_PWD_URLNETSECURE:
    'https://netsecuredit.nj.adp.com/ilink/pub/smsess/v3/forgot/ws/rest/singleForgots/olpForgotPassword.start',
  POST: 'POST',
  RETURN_URL: 'http://signin.isi.dit.paascloud.oneadp.com/signin/v1',
  COOKIE_REMEMBER_ME: 'adp_user_id',
  COOKIE_GREET_FNAME: 'adp_user_fname',
  PMDATA: 'PMData',
  FIDO2ENABLED: 'fido2_enabled',
  FIDO2REGISTERED: 'FIDO2_REGISTERED',
  ALP_COOKIE_REMEMBER_ME: 'adp_alp_userid',
  DYNATRACE_COOKIE: 'MTRUID',
  MOBILE: 'mobile',
  LOCK: 'lock',
  ADP_USER_ID_COOKIE: 'ADPUserIdCookie',
  CALLING_APP_ID: 'marketplace',
  LANGUAGECOOKIE: 'ADPLangLocaleCookie',
  CALLING_APP_ID_BROWSER_LABEL: 'APPID',
  SLEEP_REFETCH_DEVICEPRINT_IN_MILLISECONDS: 1000, // milliseconds
  PASSWORD_STATUS_MOCK: 'PASSWORD_CHANGED',
  PASSWORD_MOCKVALUE: 'b',
  PASSWORD1_MOCKVALUE: 'a',
  SPINFO_COOKIE: 'adp_user_spInfo',
  CURRENT_PASSWORD_AUTO_FOCUS: 'current_password_focus',
  NEW_PASSWORD_AUTO_FOCUS: 'new_password_focus',
  CONFIRM_PASSWORD_AUTO_FOCUS: 'confirm_password_focus',
  newUser: {
    hyperLinkDescription: 'Get started',
    hyperLinkID: 'signin.getStarted',
    hyperLinkdefaultMessage: 'Get started',
    hyperLinkDescriptionPre: 'Is a new user',
    hyperLinkIDPre: 'signin.newUser',
    hyperLinkdefaultMessagePre: 'New User',
  },
  signDiffUser: {
    hyperLinkDescription: 'sign as different user',
    hyperLinkID: 'signin.different.user',
    hyperLinkdefaultMessage: 'Sign in as a different user',
    hyperLinkDescriptionPre: 'not user',
    hyperLinkIDPre: 'sign_not_user',
    hyperLinkdefaultMessagePre: 'Not',
  },
};
