/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-eq-null */
/* eslint-disable func-names */
/**
 * @classdesc Monitors user activity and session timeout. Renews the session if necessary
 * and notifies the controller that session has expired.
 * @class
 * @param {object} controller Controller instance that implements the following methods:
 * Method name        | Usage
 * ------------------ | --------------------------------
 * onSessionTimeout   | Called when session expires.
 * upgradeSessionData | Called when session is renewed.
 *
 * @param {number} [offset] Session expiration time offset in minutes.
 * Allows to adjust session timeout to compensate possible time difference between the client and server.
 * The session timeout will happen specified amount minutes earlier. Default is 5 minutes.
 */
import { nnlConfig } from './config';
import { isObjectEmpty } from './common';
import { isLocalStorageSupported } from '../../../util/misc';

export const SessionManager = function SessionManager(controller, offset) {
  const _this = this;

  let startTime, expireTime;

  let timer = null;

  if (offset == null) {
    offset = 5;
  }

  /**
   * Starts the monitoring with the given session expiration time.
   * @param {number} exp Session expiration time in seconds.
   */
  this.start = function (exp) {
    document.addEventListener('mousedown', onUserActivity);
    document.addEventListener('keypress', onUserActivity);
    document.addEventListener('touchstart', onUserActivity);
    window.addEventListener('storage', storageEventHandler);

    window.document.cookie = 'isValidSession=true;';
    resetTimer(exp);
  };

  /**
   * Stops the monitoring. Should be called after logout to stop the timer.
   */
  this.stop = function () {
    document.removeEventListener('mousedown', onUserActivity);
    document.removeEventListener('keypress', onUserActivity);
    document.removeEventListener('touchstart', onUserActivity);
    window.removeEventListener('storage', storageEventHandler);

    window.document.cookie = 'isValidSession=false;';
    clearTimeout(timer);
  };

  // Calculates the session timeout and starts/restarts the timer.
  function resetTimer(exp) {
    if (timer != null) {
      clearTimeout(timer);
      timer = null;
    }

    startTime = new Date();
    expireTime = new Date((exp - offset * 60) * 1000);

    // If expiration time (including offset) is before start time, then end session immediatley.
    if (expireTime <= startTime) {
      onSessionExpired();
    } else {
      timer = setTimeout(onSessionExpired, expireTime - startTime);
    }
  }

  // Stops the timer and notifies the controller about session expiration.
  function onSessionExpired() {
    _this.stop();
    controller.onSessionTimeout();
  }

  // Handles local storage changes on other tabs/windows.
  function storageEventHandler(event) {
    if (event.key === `session_${APP_NAME}`) {
      onSessionChanged();
    } else if (event.key === 'activity') {
      onUserActivity();
    }
  }

  // Handles user activity events.
  function onUserActivity() {
    const timeout = expireTime - startTime;

    const passedTime = new Date() - startTime;
    // If more than the third of session timeout has passed then renew the session.

    if (passedTime >= timeout / 3) {
      renewSession()
        .then((data) => {
          if (data.outcome === Outcome.SUCCESS && data.sessionData != null) {
            // Notify the controller about session renewal.
            controller.upgradeSessionData(data.sessionData);
            // Restart the timer with the new expiration time.
            resetTimer(data.sessionData.exp);
          }
        })
        .catch((error) => {
          showToast(`Failed to renew the session: ${error}`);
        });
    } else if (isLocalStorageSupported(true)) {
      // If the threshold is not passed on this tab, notify other tabs/windows about user activity.

      localStorage.setItem('activity', new Date());
    }
  }

  // Sends current session data to the backend to renew it.
  function renewSession() {
    const session = getSession();

    if (session == null || session.sessionData == null) {
      return Promises.reject('Failed to load existing session.');
    }

    return new Promise((resolve, reject) => {
      const data = {
        op: 'renew',
        sessionData: session.sessionData,
      };

      $.ajax({
        url: nnlConfig.login_url,
        method: 'POST',
        dataType: 'json',
        timeout: 15000,
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(data),
        success: resolve,
        error(xhr, textStatus, errorThrown) {
          if (xhr.readyState === 0) {
            reject('Device is not connected to the Internet');
          } else {
            reject(`Error ${xhr.status}: ${textStatus}`);
          }
        },
      });
    });
  }

  // Handles session data changes in other tabs/windows
  function onSessionChanged() {
    const session = getSession();
    // If session data is deleted, either session is expired on user has logged out.

    if (session == null || session.sessionData == null || session.sessionData.exp == null) {
      // End the session on this tab too.
      onSessionExpired();
    } else {
      // Otherwise restart the timer with the new expiration time.
      resetTimer(session.sessionData.exp);
    }
  }
};

/**
 * @classdesc This class provides methods for storing and retrieving user specific data in the local storage.
 * @class
 */
export const UserData = function () {
  /**
   * Retrieves data for specified user.
   * @param [userName] - User name to retrieve data for. If not specified, user name will be retrieved from session.
   * @returns User data or null of user name is not specified and cannot be retrieved.
   */
  this.getData = function (userName) {
    // Try to resolve user name either form specified value or from session.
    userName = userName || getSessionUser();
    if (!userName) {
      return null;
    }

    // Load user data or create default empty object.
    let userData = {};

    if (isLocalStorageSupported(true)) {
      userData = localStorage.getItem('userData') || '{}';
    }

    userData = JSON.parse(userData);
    userData[userName] = getParameterCaseInsensitive(userData, userName) || {};

    // Create userData.oob object if it does not exist.
    userData[userName].oob = userData[userName].oob || {};

    // Create userData.fido2 structure if it does not exist.
    userData[userName].fido2 = userData[userName].fido2 || {};
    userData[userName].fido2.localCredentials = userData[userName].fido2.localCredentials || [];

    return userData[userName];
  };

  /**
   * Adds or updates registration information to the user data.
   * @param userName - User name to add registration for. If not specified, user name will be retrieved from session.
   * @param protocol - Protocol of the registration.
   * @param additionalInfo - Object containing registration information. Device ID and Key ID will be extracted from this object.
   * @param [push] - Object containing push handle.
   */
  this.addRegistration = function (
    userName,
    protocol,
    additionalInfo,
    push,
    fidoJwtKey,
    noknokConfig,
    authenticatorId,
  ) {
    // Try to extract device ID form additional information.
    let deviceID = null;

    if (additionalInfo && additionalInfo.device) {
      deviceID = additionalInfo.device.id;
    }

    // Load data for user.
    const userData = this.getData(userName);

    // If the user data is missing, nothing can be added.
    if (!userData) {
      return;
    }

    if (protocol === 'fido2') {
      // Try to extract key ID form additional information.
      if (
        additionalInfo &&
        additionalInfo.authenticatorsResult &&
        additionalInfo.authenticatorsResult.length > 0
      ) {
        // Add the new key ID to the list if it does not exist.
        if (
          userData.fido2.localCredentials.indexOf(additionalInfo.authenticatorsResult[0].handle) ===
          -1
        ) {
          userData.fido2.localCredentials.push(additionalInfo.authenticatorsResult[0].handle);
        }
        if (fidoJwtKey !== undefined) {
          userData.fido2.fidoAuthenticatorToken = fidoJwtKey;
        }
      }
      if (authenticatorId) {
        userData.fido2.localCredentials[0] = authenticatorId;
      }
    } else if (protocol === 'uaf') {
      // In this case if the deviceID is missing, nothing can be added.
      if (!deviceID) {
        return;
      }

      // Mark as available
      userData.oob.available = true;
      // Mark as webOob
      if (additionalInfo && additionalInfo.isWebOob) {
        userData.oob.appLess = true;
      } else {
        userData.oob.appLess = false;
      }
      // Save deviceID.
      userData.oob.device = deviceID;

      // If push handle is present (QR code case), save it too.
      if (push && push.pushHandle) {
        userData.oob.pushHandle = push.pushHandle;
      }
    }

    // Save data for user.
    saveData(userName, userData);
  };

  /**
   * Updates registration information in the user data.
   * @param userName - User name to update registration for. If not specified, user name will be retrieved from session.
   * @param registrations - List of registrations for the user for all protocols.
   */
  this.updateRegistrations = function (userName, registrations) {
    // Load data for user.
    const userData = this.getData(userName);

    if (!userData) {
      return Promise.resolve();
    }

    return AppSdkInfo.getDeviceId().then((thisDeviceID) => {
      // Intersection of userData.fido2.localCredentials and handles in registrations.
      const fido2credentials = [];
      // If userData.oob.device is not present in registrations, then oob is not available.

      userData.oob.available = false;

      // Loop over the registrations.
      registrations.forEach((registration) => {
        if (registration.protocol === 'fido2') {
          // If a fido2 registration is present in localCredentials, add it to fido2credentials.
          if (userData.fido2.localCredentials.indexOf(registration.handle) !== -1) {
            fido2credentials.push(registration.handle);
          }

          // If a fido2 registration is for this device and is not in the list, add it.
          if (
            registration.device === thisDeviceID &&
            fido2credentials.indexOf(registration.handle) === -1
          ) {
            fido2credentials.push(registration.handle);
          }
        }

        // If stored device ID is found, then oob is available.
        if (registration.device === userData.oob.device) {
          userData.oob.available = true;
        }

        // Save the push handle of the last registered device if present.
        if (registration.push) {
          userData.oob.available = true;
          userData.oob.device = registration.device;
          userData.oob.pushHandle = registration.push.pushHandle;
        }
      });

      // Override localCredentials with filtered list.
      userData.fido2.localCredentials = fido2credentials;

      // Save data for user.
      saveData(userName, userData);
    });
  };

  /**
   * Saves a custom flag in user data.
   * @param userName - User name to delete registration for. If not specified, user name will be retrieved from session.
   * @param flagName - Name of the custom flag.
   * @param [flagValue] - Value of the custom flag.
   */
  this.setFlag = function (userName, flagName, flagValue) {
    // Load data for user.
    const userData = this.getData(userName);

    if (!userData) {
      return;
    }

    // If value was not specified or is false, just remove the flag.
    // Otherwise add it to the user data.
    if (!flagValue) {
      delete userData[flagName];
    } else {
      userData[flagName] = flagValue;
    }

    // Save data for user.
    saveData(userName, userData);
  };

  this.getSessionUserName = function () {
    return getSessionUser();
  };
  /**
   * Saves data for specified user.
   * @param userName - User name to retrieve data for. If not specified, user name will be retrieved from session.
   * @param data - Data to save.
   * @private
   */
  function saveData(userName, data) {
    // Try to resolve user name either form specified value or from session.
    userName = userName || getSessionUser();
    if (!userName) {
      return null;
    }

    // Load data from local storage.
    let userData = {};

    if (isLocalStorageSupported(true)) {
      userData = localStorage.getItem('userData') || '{}';
    }

    userData = JSON.parse(userData);
    // If data is not provided or is empty object, delete the existing data.
    // Otherwise override the existing data.
    if (!data || isObjectEmpty(data)) {
      delete userData[userName];
    } else {
      userData[userName] = data;
    }

    // Save data back to local storage.
    userData = JSON.stringify(userData);

    if (isLocalStorageSupported(true)) {
      localStorage.setItem('userData', userData);
    }
  }

  /**
   * Returns user name stored in session.
   * @returns User name from session if it is present, otherwise null.
   * @private
   */
  function getSessionUser() {
    const session = getSession();

    if (session) {
      return session.user;
    }

    return null;
  }
};

/**
 * Get the session from localStorage
 * @returns session
 */
export function getSession() {
  let session;

  if (isLocalStorageSupported(true)) {
    session = localStorage.getItem(getSessionKeyName());
  }
  if (!session) {
    return null;
  }
  const parsedSession = JSON.parse(session);

  if (window.document.cookie.search('isValidSession=true') === -1) {
    // If session is not valid anymore (e.g. browser was restarted)),
    // delete the session and tab objects so the App will render a login view.
    delete parsedSession.sessionData;
    delete parsedSession.tab;
  }
  return parsedSession;
}
export function getUserSession(userName, sessionKey) {
  return {
    user: userName,
    sessionData: { sessionKey },
  };
}

/**
 * Saves the session in localStorage
 */
export function saveSession(session) {
  if (isLocalStorageSupported(true)) {
    localStorage.setItem(getSessionKeyName(), JSON.stringify(session));
  }
}

export function saveUserSession(username, sessionKey) {
  if (isLocalStorageSupported(true)) {
    localStorage.setItem(getSessionKeyName(), JSON.stringify(getUserSession(username, sessionKey)));
  }
}

/**
 * Removes the session from localStorage
 */
export function removeSession() {
  if (isLocalStorageSupported(true)) {
    localStorage.removeItem(getSessionKeyName());
  }
}

/**
 * Get the session key name based on the URL.
 * Extracts the top level folder name and use that as the suffix.
 * @returns session key name
 */
export function getSessionKeyName() {
  return 'session_OLP';
}

function getParameterCaseInsensitive(object, key) {
  if (!key) {
    return {};
  }
  const asLowercase = key.toLowerCase();

  return object[Object.keys(object).filter(k => k.toLowerCase() === asLowercase)[0]];
}
