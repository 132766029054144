import { combineEpics } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import * as brand from './brand/epics';
import * as initialLoad from './initial-load/epics';
import * as locale from './locale/epics';
import * as login from './login/epics';
import * as signin from './signin/epics';
import * as stepup from './step-up/epics';
import * as changepassword from './change-password/epics';
import * as termsandconditions from './terms-and-conditions/epics';
import * as questionCollection from './question-collection/epics';
import * as channelCollection from './channel-collection/epics';
import * as dynamicMessage from './dynamic-message/epics';
import * as synergy from './synergy/epics';

const epics = [
  ...Object.values(brand),
  ...Object.values(initialLoad),
  ...Object.values(locale),
  ...Object.values(login),
  ...Object.values(signin),
  ...Object.values(stepup),
  ...Object.values(changepassword),
  ...Object.values(termsandconditions),
  ...Object.values(questionCollection),
  ...Object.values(channelCollection),
  ...Object.values(dynamicMessage),
  ...Object.values(synergy),
];

export const rootEpics = (...args) => combineEpics(...epics)(...args, { ajax });
