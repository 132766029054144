export const ACCOUNTTYPE_ADP = 'ADP_IDENTITY_PROVIDER';
export const LOGIN_NOT_DEFINED_ERROR = 'Login Not Defined Error';
export const WELCOME_TITLE = 'welcome.title';
export const ALERT_TYPE_ERROR = 'error';
export const ALERT_TYPE_WARNING = 'warning';
export const ALERT_TYPE_INFO = 'info';
export const ALERT_TYPE_SUCCESS = 'success';
export const ALERT_AUTOCLOSE_TRUE = true;
export const ALERT_AUTOCLOSE_FALSE = false;
export const ACCOUNT_LOCKED = 'ACCOUNT_LOCKED';
export const ADMIN_ACCOUNT_LOCKED = 'ADMIN_ACCOUNT_LOCKED';
export const EMPLOYEE_ACCOUNT_SUSPENDED = 'EMPLOYEE_ACCOUNT_SUSPENDED';
export const EMPLOYEE_ACCOUNT_INACTIVE = 'EMPLOYEE_ACCOUNT_INACTIVE';
export const ACCOUNT_SUSPENDED = 'ACCOUNT_SUSPENDED';
export const QUESTIONNAIRE_ACCOUNT_LOCKED = 'QUESTIONNAIRE_ACCOUNT_LOCKED';
export const ERROR_TITLE = 'errorpage.accounts-suspended.title';
export const APPLICATION_ERROR_DEFAULT = 'APPLICATION_ERROR_DEFAULT';
