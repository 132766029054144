/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SdfAlert, SdfButton, SdfFocusPane } from '@synerg/react-components';
import { injectIntl } from 'react-intl';
import { mapDynamicMessageImportanceToAlertType } from '../../util/misc/common';

const HTMLReactParser = require('html-react-parser');
const Entities = require('html-entities').AllHtmlEntities;

const DynamicMessageAlert = (props) => {
  const {
    dynMsg, locale, autoClose, intl, isAppView,
  } = props;

  const {
    headline, summary, detail, importance,
  } = dynMsg;

  const headlineLocale = headline[locale];
  const summaryLocale = summary[locale];
  const detailLocale = detail && detail[locale];
  const alertType = mapDynamicMessageImportanceToAlertType(importance).type || '';
  const parseHtml = html => HTMLReactParser(html);
  const entities = new Entities();
  const [focusPane, setFocusPane] = useState('false');

  const modalClose = intl.formatMessage({
    id: 'signin.close',
    defaultMessage: 'Close',
  });
  const moreInfo = intl.formatMessage({
    id: 'dynMsg_MoreInfo',
    defaultMessage: 'More Info',
  });

  return (
    <div>
      { headlineLocale && (
        <div id="dynamicMessage_alert_div">
          <SdfAlert
            type="banner"
            autoClose={ autoClose }
            closeable
            size="sm"
            id="common_alert"
            status={ alertType }
            className="m-2 md:m-0"
          >
            <>
              <span
                slot="title"
                className="text-lg"
              >
                { headlineLocale }
              </span>
              <span>
                { !isAppView && summaryLocale }
                <SdfButton
                  data-testid="moreInfo"
                  id="dynMsg_MoreInfoLinkButton"
                  emphasis="tertiary"
                  size="sm"
                  onClick={ () => {
                    (detailLocale || summaryLocale) && setFocusPane('true');
                  } }
                  variant="text"
                  className={ isAppView ? 'text-md font-medium ml-2  p-2' : 'ml-2 font-bold' }
                >
                  { moreInfo }
                </SdfButton>
              </span>
            </>
          </SdfAlert>
          <SdfFocusPane
            size="md"
            id="sdf-Focus-Pane"
            data-testid="focusPane"
            heading={ headlineLocale }
            hideDismissButton
            acceptLabel={ modalClose }
            visible={ focusPane }
            onSdfAccept={ () => {
              setFocusPane('false');
            } }
            onSdfDismiss={ () => setFocusPane(false) }
            closeable={ !isAppView }
            icon={ !isAppView && 'alert-notification' }
            status="info"
            spacing="loose"
          >
            <span className="text-lg">
              { parseHtml(entities.decode(detailLocale || summaryLocale)) }
            </span>
          </SdfFocusPane>
        </div>
      ) }
    </div>
  );
};

DynamicMessageAlert.propTypes = {
  dynMsg: PropTypes.shape({
    defaultLanguage: PropTypes.string.isRequired,
    detail: PropTypes.object,
    endDateTime: PropTypes.string.isRequired,
    headline: PropTypes.object.isRequired,
    importance: PropTypes.string.isRequired,
    startDateTime: PropTypes.string.isRequired,
    summary: PropTypes.object.isRequired,
  }),
  isAppView: PropTypes.bool,
  locale: PropTypes.string,
  autoClose: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};

export default injectIntl(DynamicMessageAlert);
